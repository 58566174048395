.alerttoast {
    position: fixed;
    top: 5%;
    left: -90% ;
    display: flex;
    min-width: 250px;
    justify-content: space-between;
    align-items: center;
    max-width: fit-content;
    padding: 0.5rem;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    animation: slideRight 1s forwards;
    z-index: 999;
}

@keyframes slideRight {
    0% {
        left: -90%;
    }

    100% {
        left: 4%;
        transform: translateX(4%);
    }
}

.alerttoast.success {
    background-color: #DDFFDD;
    color: gray;
}

.alerttoast.failure {
    background-color: #FFDDDD;
    color: gray;
}

.alerttoast.info {
    background-color: #DDFFFF;
    color: gray;
}

.alerttoast.notification {
    background-color: #F8F8FF;
    color: gray;
}
.alerttoast.unauthorized {
    background-color: #ffc107;
    color: gray;
}

.alerttoast.paymentRequired {
    background-color: #ff5722;
    color: gray;
}

.close-button {
    margin-left: 0.5rem;
    /* margin-right: 0.5rem; */
    margin-bottom: 0.3rem;
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font-size: 20px;
    transition: 0.5s;
}
.close-button:hover{
    background-color: none;
    scale: 1.6;
}