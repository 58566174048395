@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body{
  font-family: 'Poppins', sans-serif;
}

button{
  font-family: 'Poppins', sans-serif;
}

.bg-purple {
  background-color: #D8AC48 !important; /* Change the button's background color */
}

.bg-yellow{
  background-color: #D8AC48;
}

.bg-gray {
  background-color: #ececec !important;
}

/* #telly {
  margin-top: 50px;
} */

.formwidth {
  width: 130px;
}

.bg-grayy {
  background-color: #c4bfbf !important;
}

/* .formwidth:hover {
  background-color:#D8AC48 !important;
  color: white !important;
  border-radius: 50%;
} */

/* abc */

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}


input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* css btn hover */
.button-container {
  display: flex;
  margin: 0px 16px;
}

.button-container button {
  border: none;
  outline: none; /* Add this line to remove the outline */
  padding: 10px;
  width: 100%;
  text-align: center;
  transition: background-color 0.5s ease;
  border-radius: 0px;
  font-weight: 700;
  background-color:#ffffff;
  border:1px solid #cbd5e1;
  color:#4A5568;
}
.button-container button:focus{
  box-shadow: none;
}


.button-container .active {
  background-color:#DCD9E0;
  color: #1A0F31 !important;  
}

/* .button-container .active.standard {
  background-color: #D8AC48;
  color: #ffffff !important;
} */

/* mailerbox buttoins */
.btngrouphover {
  background-color: transparent;
  border: 1px solid #D8AC48;
  color: #D8AC48;
  border-radius: 20px;
  padding: 5px 10px;
  transition: background-color 0.3s ease;
}

.btngrouphover.active {
  background-color: #D8AC48;
  color: white !important;
}

.gray {
  background-color: #ebf1f5 !important;
}

.paypalformpayment {
  text-decoration: none;
}

form.shadow-lg.p-4 {
  border-radius: 20px !important;
}

.proofing {
  border-radius: 20px !important;
}

.screen3rounded {
  border-radius: 20px !important;
}

.bggwhite {
  background-color: white;
}

.shopping {
  font-size: 10px;
}

.form.shadow.w-100.rounded.bg-light {
  border-radius: 15px !important;
}

.small-input {
  height: 35px !important; /* Adjust the height as per your preference */
}

form.py-5.shadow-sm.border-0.rounded.px-3.bg-light {
  border-radius: 15px !important;
}
.successPopup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9); /* Adjust the background color and opacity */
    z-index: 1050; /* Ensure the z-index is higher than the default z-index of the modal */
    overflow: hidden;
}

.payment-form .payment-header{
  background-color: #635675;
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 20px;
}
.payment-form .payment-header p{
  margin-bottom: 0px;
  font-family:  'Inter', sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #ffffff;
    padding: 15px 25px 

}
.payment-form input{
    background-color: #F3F4F6;
    border: none;
    color: #4A5568;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root{
  background-color: #F3F4F6;
}
.payment-form button{
  width: 100%;
    padding: 15px;
    border: none;
    background-color: #D8AC48;
    color: #ffffff;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    border-radius: 50px;
}

.payment-form-wrap .text-field{
  margin:8px 8px;
}
.threed-modal-alternative{
  display: block;
    width: 800px;
    height: 400px;
    position: relative;
    right: 0%;
    top: 0%;
    text-align: center;
    font-size: 25px;
}


/* Media Queries for Responsiveness */
@media screen and (max-width: 768px) {
  /* Adjust styles for screens up to 768px width */
  /* Example styles */
  .formwidth {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  /* Adjust styles for screens up to 480px width */
  /* Example styles */
  .formwidth {
    width: 80%;
  }
}


/* Add necessary CSS styles to make the component responsive */

/* Example media query for screens up to 768px width */
@media screen and (max-width: 768px) {
  .col-lg-7,
  .col-lg-4 {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
}

/* Example media query for screens up to 480px width */
@media screen and (max-width: 480px) {
  .col-lg-7,
  .col-lg-4 {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
}


/* Adjust layout and styling for small screens */
@media (max-width: 767px) {
  .modal-body-content {
    margin-bottom: 1rem;
  }
  
  .sc4btn-container {
    margin-top: 1rem;
  }
}


