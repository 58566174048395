@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.cdnfonts.com/css/gilroy');
@font-face {
    font-family: gilroy;
    font-style: normal;
    font-weight: 900;
    src: local('Gilroy'), url(https://theboxlane.com/assets/fonts/GilroyHeavy.woff) format('woff');
} 
@font-face {
	font-family: 'gilroy';
	src: url('https://theboxlane.com/assets/fonts/Gilroy-Light.otf') format('opentype');
	font-weight: 300; 
	font-style: normal;
  }
@font-face {
	font-family: 'gilroy';
	src: url('https://theboxlane.com/assets/fonts/Gilroy-ExtraBold.otf') format('opentype');
	font-weight: 700; 
	font-style: normal;
  }

h3{
	font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    color: #1A0F31;
}
.d.flex{
	display:flex;
}
.back-to-top-button {
	position: fixed;
	bottom: 1000px;
	left: 30px;
	display: none;
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
	z-index: 55;
}

.back-to-top-button.active {
	display: block;
	bottom: 30px;
}

.back-to-top-button button {
	background: transparent;
	border: none;
	width: 50px;
	height: 50px;
	background: #d7ab48;
	color: #fff;
	border-radius: 50%;
	cursor: pointer;
	outline: none;
}

.section-title {
	margin-bottom: 63px;
	text-align: center;
}

.section-title span.subtitle {
	font-size: 18px;
	color:#000000;
	display: block;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 9px;
	margin-top: -6px;
}

.section-title h2 {
	font-size: 60px;
	font-family: 'Inter', sans-serif;
	line-height: 70px;
	color: #d7ab48;
	font-weight: 700;
	margin-bottom: 20px;
}

.section-title p {
	font-size: 16px;
	line-height: 26px;
	color: #2C4836;
}

.section-title.section-title-2 span.subtitle {
	color: #fff;
}

.section-title.section-title-2 h2 {
	color: #fff;
}

.section-title.section-title-2 p {
	color: #e6e7e9;
}

.c-white {
	color: #fff;
}

.overflow-hidden {
	overflow: hidden;
	height: 100%;
}

.preloader {
	position: fixed;
	left: 0;
	width: 100%;
	height: 100%;
	top: 0;
	background: #fff;
	z-index: 999;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.preloader img {
	width: 150px;
}

.preloader span {
	font-size: 20px;
	font-weight: 600;
	color: #e9664a;
	letter-spacing: 6px;
	margin-top: 10px;
}

.scroll-to-top {
	position: fixed;
	right: 50px;
	bottom: 80px;
	display: inline-block;
	z-index: 9;
	width: 40px;
	height: 40px;
	background: #052157;
	border: 2px solid #fff;
	text-align: center;
}

.scroll-to-top a {
	text-decoration: none;
	font-family: 'Lato', sans-serif;
	font-weight: 900;
	cursor: pointer;
	font-size: 24px;
	color: #fff;
	padding: 2px 0 0px 0;
	display: inline-block;
}

.scroll-to-top a i {
	color: #fff;
}

.modal-open {
	position: fixed;
	width: 100%;
}


/* Base for label styling */

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
	position: absolute;
	left: -9999px;
}

[type="checkbox"]:not(:checked)+label,
[type="checkbox"]:checked+label {
	position: relative;
	padding-left: 27px;
	cursor: pointer;
	font-size: 14px;
	color: #1A0F31;
	line-height: 28px;
	font-weight: 500;
}


/* checkbox aspect */


[type="checkbox"]:not(:checked)+label:before{
	content: '';
	position: absolute;
	left: 0;
	top: 4px;
	width: 22px;
	height: 22px;
	background: #fff;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	border-radius: 20%;
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	font-size: 13px;
	line-height: 22px;
	text-align: center;
	color: #1A0F31;
}

[type="checkbox"]:checked+label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 4px;
	width: 22px;
	height: 22px;
	background: #fff;
	border-radius: 4px;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	border-radius: 50%;
	content: '\f00c';
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	font-size: 13px;
	line-height: 22px;
	text-align: center;
	color: #1A0F31;
}

[type="checkbox"]:checked+label:before {
	background: #000000;
	border-color: #000000;
	border-radius: 20%;
}


/* checked mark aspect */

[type="checkbox"]:not(:checked)+label:after,
[type="checkbox"]:checked+label:after {
	content: '\f00c';
	color:#000000;
	position: absolute;
	top: 10px;
	left: 4px;
	font-size: 13px;
	line-height: 0.8;
	color: #fff;
	-webkit-transition: all .2s;
	-o-transition: all .2s;
	transition: all .2s;
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
}


/* checked mark aspect changes */

[type="checkbox"]:not(:checked)+label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
}

[type="checkbox"]:checked+label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}


/* disabled checkbox */

[type="checkbox"]:disabled:not(:checked)+label:before,
[type="checkbox"]:disabled:checked+label:before {
	-webkit-box-shadow: none;
	box-shadow: none;
	border-color: #bbb;
	background-color: #ddd;
}

[type="checkbox"]:disabled:checked+label:after {
	color: #999;
}

[type="checkbox"]:disabled+label {
	color: #aaa;
}


/* hover style just for information */

.modal-registration {
	background: rgba(18, 31, 89, 0.6);
}

.modal-registration .modal-dialog {
	width: 350px;
}

.modal-registration .modal-content {
	padding: 30px;
}

.modal-registration .modal-content .nav-tabs {
	border: none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	border-radius: 50px;
	overflow: hidden;
	margin-bottom: 30px;
}

.modal-registration .modal-content .nav-tabs .nav-item {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.modal-registration .modal-content .nav-tabs .nav-item .nav-link {
	border: none;
	padding: 0;
	text-align: center;
	height: 50px;
	line-height: 50px;
	border-radius: 0;
	color: #0b1d97;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 14px;
	background-color: rgba(18, 31, 89, 0.1);
}

.modal-registration .modal-content .nav-tabs .nav-item .nav-link.active {
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	color: #fff;
}

.modal-registration .modal-content .nav-tabs .nav-item:first-child .nav-link {
	border-right: 1px solid rgba(11, 29, 151, 0.3);
}

.modal-registration .modal-content img {
	width: 50px;
	display: inline-block;
	position: relative;
	left: 50%;
	margin-left: -25px;
	margin-bottom: 24px;
}

.modal-registration .modal-content h3.title {
	font-size: 20px;
	font-weight: 700;
	color: #1A0F31;
	margin-bottom: 0;
	text-align: center;
	margin-bottom: 24px;
}

.modal-registration .modal-content form input {
	width: 100%;
	height: 44px;
	border: 1px solid #f0f0f0;
	font-size: 15px;
	padding: 0 20px;
	background: #f5f5f5;
	margin-bottom: 20px;
	color: rgba(18, 31, 89, 0.8);
}

.modal-registration .modal-content form input:placeholder {
	color: rgba(18, 31, 89, 0.6);
}

.modal-registration .modal-content form .create-account-btn,
.modal-registration .modal-content form .facebook-btn {
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
	display: block;
	font-size: 14px;
	color: #fff;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
	height: 50px;
	line-height: 50px;
}

.modal-registration .modal-content form .create-account-btn {
	margin-top: 7px;
}

.modal-registration .modal-content form .facebook-btn {
	background: #3b5999;
}

.modal-registration .modal-content form .text {
	font-size: 16px;
	font-weight: 700;
	color: #1A0F31;
	text-transform: uppercase;
	text-align: center;
	display: block;
	margin: 14px 0 14px;
}

.modal-registration .modal-content p {
	font-size: 14px;
	color: #1A0F31;
	text-align: center;
	margin: 0;
	margin-top: 15px;
}

.modal-registration .modal-content p a {
	color: #0b1d97;
	font-weight: 600;
	font-size: 14px;
	margin-left: 10px;
}


/*==========================================
    normalize
==========================================*/

html {
	font-family: 'Inter', sans-serif;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	overflow-x: hidden;
}

body {
	font-family: 'Inter', sans-serif;
	font-size: 16px;
	margin: 0;
	color: #111705;
	overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
a {
	line-height: 1.3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'gilroy', sans-serif;
	
}

p {
	line-height: 1.6;
	font-family: 'Inter', sans-serif;
	/* font-weight: normal; */
}

a {
	color: #111705;
	text-decoration: none;
}

a,
a:hover,
a:focus,
a:active {
	text-decoration: none !important;
	outline: none;
}

a i {
	padding: 0 2px;
}

img {
	max-width: 100%;
}


/*input and button type focus outline disable*/

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
textarea:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
select:focus {
	outline: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: 1px solid #ddd;
}


/**
 * 5.0 - Alignments
 */

.alignleft {
	float: left;
}

.alignright {
	float: right;
}

.aligncenter {
	clear: both;
	display: block;
	margin: 0 auto 1.75em;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

@-webkit-keyframes circle {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(3600deg);
		transform: rotate(3600deg);
	}
}

@keyframes circle {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(3600deg);
		transform: rotate(3600deg);
	}
}

@-webkit-keyframes circle1 {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	50% {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes circle1 {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	50% {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@-webkit-keyframes circle2 {
	0% {
		-webkit-transform: rotate(0deg) translate(-35px) rotate(0deg);
		transform: rotate(0deg) translate(-35px) rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg) translate(-35px) rotate(-360deg);
		transform: rotate(360deg) translate(-35px) rotate(-360deg);
	}
}

@keyframes circle2 {
	0% {
		-webkit-transform: rotate(0deg) translate(-35px) rotate(0deg);
		transform: rotate(0deg) translate(-35px) rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg) translate(-35px) rotate(-360deg);
		transform: rotate(360deg) translate(-35px) rotate(-360deg);
	}
}

.header {
	/* position: absolute; */
	z-index: 55;
	width: 100%;
	top: 0;
	left: 0;
}

.header.navbar-fixed {
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	border-radius: 0;
	background: #ffffff;
	box-shadow: 0px 10px 17px #0000001C;
	border-bottom:none;
}

.header.navbar-fixed .header-top {
	display: none;
}

.header.navbar-fixed .header-bottom {
	-webkit-box-shadow: none;
	box-shadow: none;
	padding-left: 0;
	padding-right: 0;
}

.header .header-top {
	padding: 15px 0;
}

.header .header-top .part-left ul li,
.header .header-top .part-right ul li {
	display: inline-block;
	padding: 0 8px;
	color: #e9edff;
	font-size: 14px;
}

.header .header-top .part-left ul li a,
.header .header-top .part-right ul li a {
	display: block;
}

.header .header-top .part-left ul li a span.part-icon,
.header .header-top .part-right ul li a span.part-icon {
	margin-right: 0px;
	display: inline-block;
	color: #e9edff;
	font-size: 14px;
}

.header .header-top .part-left ul li a span.text,
.header .header-top .part-right ul li a span.text {
	display: inline-block;
	color: #e9edff;
	font-size: 14px;
}

.header .header-top .part-left ul li span.part-icon,
.header .header-top .part-right ul li span.part-icon {
	margin-right: 4px;
}

.header .header-top .part-left ul li span.part-icon.user,
.header .header-top .part-right ul li span.part-icon.user {
	display: inline-block;
	width: 35px;
	height: 35px;
	background: #263780;
	text-align: center;
	line-height: 35px;
	border-radius: 50px;
}

.header .header-top .part-left ul li select,
.header .header-top .part-right ul li select {
	background: transparent;
	color: #e9edff;
	border: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	cursor: pointer;
}

.header .header-top .part-left ul li select option,
.header .header-top .part-right ul li select option {
	background: #1A0F31;
}

.header .header-top .part-left ul li .c-acc,
.header .header-top .part-right ul li .c-acc {
	display: inline-block;
	color: #e9edff;
	font-size: 14px;
}

.header .header-top .part-right {
	text-align: right;
}

.header {
	background:transparent;
	-webkit-box-shadow: 0px 4px 20px 2px rgba(0, 0, 0, 0.08);
	padding: 12px 0px;
	border-bottom: 1px solid #494455;
}

.header .header-bottom .main-menu .navbar {
	padding: 0;
}

.header .header-bottom .main-menu .navbar .navbar-nav .nav-item {
	padding: 0;
}

.header .header-bottom .main-menu .navbar .navbar-nav .nav-item.buy-nows {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.header .header-contact-info ul{
	display:flex;
}
.header .header-contact-info ul .call-header{
	margin: 0px 10px;
	border-radius: 0px 32.5px 32.5px 32.5px;
    background: var(--Red, #EB5757);
    padding: 10px;
}
.header .header-contact-info .email-header{
	border: 2px solid #ffffff;
    border-radius: 8px;
	padding: 0px 10px;
}
.header .header-contact-info .email-header a{
	color:#ffffff;
	height: 40px;
	line-height: 44px;
}
.header .header-contact-info .call-header a{
	color: #ffffff;
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
    letter-spacing: 0.50px;
}
.header .header-contact-info .call-header a i{
	transform: scaleX(-1);
	padding-left: 6px;
	font-size: 25px;
	color:#ffffff;
}
.header .header-contact-info .call-header span{
	font-size: 11px;
    color: #ffffff;
    font-weight: 500;
}

.header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link {
	font-family: 'Inter', sans-serif;
	color: #ffffff;
	font-size: 14px;
	font-weight: 500;
	position: relative;
    padding: 6px 12px;
	border-radius: 5px;
}
.header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link:hover{
	background-color: #D8AC48;
}
.header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link.active{
	background-color: #D8AC48;
}

/* .header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link:before {
	position: absolute;
	content: '';
	left: 0;
	bottom: 20px;
	width: 0;
	height: 3px;
	background: #ffffff;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
} */

.header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link.dropdown-toggle:after {
	content: '\f107';
	border: none;
	margin-left: 1px;
	font-family: "Font Awesome 5 Free";
	vertical-align: baseline;
}

.header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link.buy-now {
	background: #000000;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link.buy-now:hover {
	background: #fff;
	color: #000000;
}

.header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link.buy-now:before {
	display: none;
}

.header .header-bottom .main-menu .navbar .navbar-nav .nav-item .dropdown-menu {
	padding: 0;
	border-radius: 0;
	display: block;
	opacity: 0;
	visibility: hidden;
	margin-top: 10px;
	border: none;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.header .header-bottom .main-menu .navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item {
	padding: 15px 20px;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 600;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	background: #000000;
	color: #fff;
}

.header .header-bottom .main-menu .navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item:last-child {
	border-bottom: none;
}

.header .header-bottom .main-menu .navbar .navbar-nav .nav-item:last-child {
	padding-right: 0;
}

.header .header-bottom .main-menu .navbar .navbar-nav .nav-item:hover .nav-link:before {
	width: 100%;
}

.header .header-bottom .main-menu .navbar .navbar-nav .dropdown:hover .dropdown-menu {
	opacity: 1;
	visibility: visible;
	margin-top: 0;
}


.banner.banner-3 {
	background: url(https://theboxlane.com/images/homepage/Hero-Bg.webp) center center no-repeat;
	background-size: cover;
	position: relative;
	height:115vh;
	z-index: 2;
}

.banner.banner-3:after {
	display: none;
}

.banner.banner-3 .banner-animation {
	position: absolute;
}

.banner.banner-3 .banner-animation.animation-3 {
	right: 100px;
	bottom: 0;
	height: 450px;
}

.banner.banner-3 .banner-animation.animation-2 {
	-webkit-animation: circle1 6s linear infinite;
	animation: circle1 6s linear infinite;
}

.banner.banner-3 .banner-animation.animation-1 {
	right: 170px;
    width: 800px;
    top: 280px;
    -webkit-animation: circle1 6s linear infinite;
    animation: circle1 6s linear infinite;
}

.banner .banner-content {
	padding: 200px 0;
	margin-top: 139px;
}

.banner .banner-content h1 {
	color: #fff;
	font-size: 48px;
	line-height: 90px;
	font-weight: 700;
	margin-bottom: 16px;
	margin-top: -10px;
}

.banner .banner-content p {
	color: #fff;
	line-height: 28px;
}

.banner .banner-content .banner-buttons {
	margin-top: 42px;
}



.banner .banner-content .banner-buttons a.banner-button,
.banner .banner-content .banner-buttons span.banner-button {
	padding: 16px 36px;
	display: inline-block;
	color: #fff;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	font-family: 'Inter', sans-serif;
	background: #000000;
	-webkit-box-shadow: 0px 4px 12px #0000002E;
	box-shadow: 0px 4px 12px #0000002E;
	border-radius: 50px;
}

.banner-3 .banner-buttons a{
	color:#3d9155 !important;
	background-color: #ffffff !important;
	box-shadow: 0px 4px 12px #0000002E !important;
}

.banner .banner-content .banner-buttons a.banner-button {
	margin-right: 16px;
}

.banner .banner-content .banner-buttons span.banner-button {
	padding: 0;
	width: 50px;
	height: 50px;
	text-align: center;
	line-height: 50px;
	font-size: 14px;
}

.banner .banner-content .banner-buttons span.text {
	display: inline-block;
	color: #fff;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	font-family: 'Inter', sans-serif;
	margin-left: 6px;
}

.banner.banner-2 {
	/*background: url(../img/banner-2-bg.png) center center no-repeat;*/
	background-size: cover;
	background-image: -moz-linear-gradient(90deg, #0d154b 0%, #1b226b 100%);
	background-image: -webkit-linear-gradient(90deg, #0d154b 0%, #1b226b 100%);
	position: relative;
	z-index: 2;
}

.banner.banner-2 .banner-content {
	padding: 300px 0;
}

.banner.banner-2:after {
	position: absolute;
	content: '';
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	/*background: url("../img/banner-2-bg.png") center center no-repeat;*/
	background-size: cover;
	z-index: -1;
}

.banner .banner-img {
	margin-left: -70px;
}

.banner .banner-img img {
	max-width: 800px;
	width: auto;
}

.feature {
	padding: 70px 0px;
}

.feature .single-feature {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: #FBFBFB;
	box-shadow: 0px 17px 10px #0000000F;
	border: 1px solid #F1F1F1;
	border-radius: 13px;
	height: 190px;
    align-items: center;
    justify-content: center;
    padding: 15px 15px 15px 25px;
}

.feature .single-feature .part-icon {
	display: inline-block;
	width: 65px;
	height: 65px;
	padding: 15px;
	border-radius: 50px;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
	background-color: #000000;
}

.icon-box{
	margin-right: 10px;
	display: inline-block;
}

.feature-border{
	border: 1px solid #000000;
    border-radius: 50px;
    padding: 2px;
}


.feature .single-feature .part-icon img {
	width: 100%;
	width:35px;
	height:35px;
}

.feature .single-feature .part-text span.title {
	display: block;
	font-size: 20px;
	font-weight: 600;
	color: #1A0F31;
	text-transform: capitalize;
	font-family: 'Inter', sans-serif;
}

.feature .single-feature .part-text p {
	font-size: 16px;
	line-height: 26px;
	color: #2C4836;
	margin-bottom: 0;
}



.amazing-feature {
	padding: 120px 0 80px;
	position: relative;
	overflow: hidden;
}

.amazing-feature .af-shape-1,
.amazing-feature .af-shape-2 {
	position: absolute;
	width: 260px;
	top: 0;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 0;
	-webkit-transition: 5s;
	-moz-transition: 5s;
	-o-transition: 5s;
	transition: 5s;
}

.amazing-feature .af-shape-2 {
	left: auto;
	right: 0;
	-webkit-transform: translateY(50%) rotate(180deg);
	-ms-transform: translateY(50%) rotate(180deg);
	transform: translateY(50%) rotate(180deg);
	top: auto;
	bottom: 0%;
}

.amazing-feature .af-shape-1.active {
	top: 50%;
}

.amazing-feature .af-shape-2.active {
	bottom: 50%;
}

.amazing-feature .single-feature {
	text-align: center;
	margin-bottom: 40px;
	border-radius: 20px;
	border: 1px solid #d2d4d6;
	padding: 40px 30px 17px 30px;
	position: relative;
	z-index: 2;
	overflow: hidden;
}

.amazing-feature .single-feature:after {
	position: absolute;
	content: '';
	width: 180%;
	height: 100%;
	background-image: -moz-linear-gradient(90deg, #000000 0%, #000000 100%);
	background-image: -webkit-linear-gradient(90deg, #000000 0%, #000000 100%);
	z-index: -1;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	opacity: 0;
	opacity: 1;
	-webkit-transform: rotate(60deg);
	-ms-transform: rotate(60deg);
	transform: rotate(60deg);
	left: -140%;
	top: 50%;
}

.amazing-feature .single-feature:before {
	position: absolute;
	content: '';
	width: 180%;
	height: 100%;
	background-image: -moz-linear-gradient(90deg, #000000 0%, #000000 100%);
	background-image: -webkit-linear-gradient(90deg, #000000 0%, #000000 100%);
	z-index: -1;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	opacity: 0;
	opacity: 1;
	-webkit-transform: rotate(60deg);
	-ms-transform: rotate(60deg);
	transform: rotate(60deg);
	right: -140%;
	bottom: 50%;
}

.amazing-feature .single-feature:hover:after {
	left: -60%;
}

.amazing-feature .single-feature:hover:before {
	right: -60%;
}

.amazing-feature .single-feature:hover .part-icon:after {
	border: 1px dashed rgba(255, 255, 255, 0.5);
}

.amazing-feature .single-feature:hover .part-text span.title {
	color: #fff;
}

.amazing-feature .single-feature:hover .part-text p {
	color: #ddd !important;
}

.amazing-feature .single-feature .part-icon {
	margin: 10px 0;
	border-radius: 50%;
	display: inline-block;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.3);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.3);
	width: 80px;
	height: 80px;
	background-color: #000000;
	text-align: center;
	line-height: 80px;
	margin-bottom: 33px;
	position: relative;
	border: 1px solid #ffffff;
}
.amazing-feature .single-feature .part-icon img{
    width:35px;
    height:35px;
}

.amazing-feature .single-feature .part-icon:after {
	position: absolute;
	content: '';
	left: -10px;
	top: -10px;
	right: -10px;
	bottom: -10px;
	border: 1px dashed rgba(18, 31, 89, 0.5);
	border-radius: 50%;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.amazing-feature .single-feature .part-text span.title {
	font-family: 'Inter', sans-serif;
	font-weight: 700;
	color: #1A0F31;
	font-size: 24px;
	text-transform: capitalize;
	display: block;
	margin-bottom: 14px;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.amazing-feature .single-feature .part-text p {
	color: #2C4836 !important;
	font-size: 16px;
	line-height: 26px;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.power-of-x8 {
	padding: 120px 0 0;
	background-image: -moz-linear-gradient(24deg, #0e154b 0%, rgba(61, 73, 205, 0.9) 100%);
	background-image: -webkit-linear-gradient(24deg, #0e154b 0%, rgba(61, 73, 205, 0.9) 100%);
}

.power-of-x8 .single-feature {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.power-of-x8 .single-feature .part-icon {
	width: 60px;
	margin-right: 20px;
}

.power-of-x8 .single-feature .part-icon img {
	width: 100%;
}

.power-of-x8 .single-feature .part-text span.number {
	font-family: 'Inter', sans-serif;
	color: #fff;
	font-weight: 700;
	font-size: 60px;
	line-height: 100%;
	display: block;
	margin-top: -6px;
	margin-bottom: 6px;
}

.power-of-x8 .single-feature .part-text p {
	color: #e6e7e9;
}

.power-of-x8 .part-video {
	margin-top: 22px;
	margin-bottom: -249px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.power-of-x8 .part-video img {
	width: 100%;
}

.power-of-x8 .part-video a.play-button {
	display: inline-block;
	position: absolute;
	color: #fff;
	background: #000000;
	width: 50px;
	height: 50px;
	text-align: center;
	line-height: 50px;
	border-radius: 50%;
	z-index: 2;
}

.power-of-x8 .part-video a.play-button:after {
	position: absolute;
	content: '';
	left: -10px;
	top: -10px;
	right: -10px;
	bottom: -10px;
	background: #000000;
	z-index: -1;
	opacity: .4;
	border-radius: 50%;
	-webkit-animation: circle1 5s infinite;
	animation: circle1 5s infinite;
}

.power-of-x8 .part-video a.play-button:before {
	position: absolute;
	content: '';
	left: -20px;
	top: -20px;
	right: -20px;
	bottom: -20px;
	background: #000000;
	z-index: -1;
	opacity: .2;
	border-radius: 50%;
	-webkit-animation: circle1 5s infinite;
	animation: circle1 5s infinite;
}

.industry-feature {
	padding: 120px 0 90px;
	position: relative;
}

.industry-feature .i-feature-shape {
	position: absolute;
	right: 0;
	bottom: 116px;
	width: 420px;
}

.industry-feature .i-features .single-feature {
	text-align: center;
	border-radius: 20px;
	-webkit-box-shadow: 0.5px 0.866px 10px 0px rgba(31, 39, 50, 0.1);
	box-shadow: 0.5px 0.866px 10px 0px rgba(31, 39, 50, 0.1);
	padding: 50px 0 0;
	margin-bottom: 50px;
	position: relative;
}

.industry-feature .i-features .single-feature .hover-effect {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 20px;
}

.industry-feature .i-features .single-feature .hover-effect:after {
	position: absolute;
	content: '';
	width: 180%;
	height: 100%;
	background-image: -moz-linear-gradient(90deg, #0d154b 0%, #1b226b 100%);
	background-image: -webkit-linear-gradient(90deg, #0d154b 0%, #1b226b 100%);
	z-index: -1;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	opacity: 0;
	opacity: 1;
	-webkit-transform: rotate(60deg);
	-ms-transform: rotate(60deg);
	transform: rotate(60deg);
	left: -140%;
	top: 50%;
}

.industry-feature .i-features .single-feature .hover-effect:before {
	position: absolute;
	content: '';
	width: 180%;
	height: 100%;
	background-image: -webkit-linear-gradient(90deg, #1e256d 0%, #1b226b 100%);
	z-index: -1;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	opacity: 0;
	opacity: 1;
	-webkit-transform: rotate(60deg);
	-ms-transform: rotate(60deg);
	transform: rotate(60deg);
	right: -140%;
	bottom: 50%;
}

.industry-feature .i-features .single-feature:hover .hover-effect:after {
	left: -60%;
}

.industry-feature .i-features .single-feature:hover .hover-effect:before {
	right: -60%;
}

.industry-feature .i-features .single-feature:hover .part-icon:after {
	border-color: #fff;
}

.industry-feature .i-features .single-feature:hover .part-text span.title {
	color: #fff;
}

.industry-feature .i-features .single-feature:hover a {
	color: #fff;
	background: #000000;
}

.industry-feature .i-features .single-feature .part-icon {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	position: relative;
	margin: 7px 0;
	margin-bottom: 29px;
}

.industry-feature .i-features .single-feature .part-icon:after {
	position: absolute;
	content: '';
	left: -7px;
	top: -7px;
	bottom: -7px;
	right: -7px;
	border: 1px solid #847f9c;
	border-radius: 50%;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.industry-feature .i-features .single-feature .part-icon img {
	width: auto;
}

.industry-feature .i-features .single-feature .part-text span.title {
	display: block;
	font-size: 24px;
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	color: #1A0F31;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.industry-feature .i-features .single-feature a {
	display: inline-block;
	text-transform: uppercase;
	font-weight: 700;
	color: #404a79;
	font-size: 15px;
	height: 40px;
	width: 170px;
	line-height: 40px;
	text-align: center;
	-webkit-box-shadow: 1.5px 2.598px 10px 0px rgba(31, 39, 50, 0.2);
	box-shadow: 1.5px 2.598px 10px 0px rgba(31, 39, 50, 0.2);
	border-radius: 50px;
	background: #fff;
	position: relative;
	margin-top: 10px;
	top: 20px;
	z-index: 3;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.industry-feature .industry-drone {
	position: relative;
	left: 100%;
	-webkit-transition: 4s;
	-o-transition: 4s;
	transition: 4s;
}

.industry-feature .industry-drone.active {
	left: 0;
}

.industry-feature .industry-drone img {
	width: auto;
	max-width: none;
}

.easy-to-begin {
	background: url(https://theboxlane.com/images/homepage/about-banner.webp) center center no-repeat;
	position: relative;
	background-size: cover;
}

.easy-to-begin.style-2 {
	background: transparent;
}

.easy-to-begin.style-2:after {
	display: none;
}

.easy-to-begin.style-2 .part-img {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 785px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	/*background: url("../img/easy-to-begin-step-3-bg.png") center center no-repeat;*/
	background-size: 100%;
}

.easy-to-begin.style-2 .part-img .pukkut {
	width: 355px;
	position: relative;
}

.easy-to-begin.style-2 .part-img .pukkut img:first-child {
	width: 320px;
}

.easy-to-begin.style-2 .part-text span.subtitle {
	color: #0b1d97;
}

.easy-to-begin.style-2 .part-text h3 {
	color: #1A0F31;
}

.easy-to-begin.style-2 .part-text p {
	color: #2C4836;
}


.easy-to-begin .part-text {
	padding: 120px 0;
}

.easy-to-begin .part-text span.subtitle {
	font-family: 'Inter', sans-serif;
	font-weight: 700;
	font-size: 18px;
	text-transform: uppercase;
	color: #fff;
	display: block;
	margin-bottom: 6px;
}

.easy-to-begin .part-text h3 {
	font-size: 60px;
	font-weight: 700;
	margin-bottom: 15px;
	color: #fff;
}

.easy-to-begin .part-text p {
	color: #fff;
	font-size: 16px;
	line-height: 30px;
}

.easy-to-begin .part-text a.play {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	height: 50px;
	padding: 0 20px 0 10px;
	border-radius: 50px;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	color: #fff;
	font-family: 'Inter', sans-serif;
	text-transform: uppercase;
	font-weight: 700;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-top: 15px;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
}

.easy-to-begin .part-text a.play span {
	display: inline-block;
}

.easy-to-begin .part-text a.play span.icon {
	width: 35px;
	height: 35px;
	margin-right: 10px;
	text-align: center;
	border: 1px solid #fff;
	line-height: 33px;
	border-radius: 50%;
	font-size: 12px;
}

.product {
	padding: 70px 0 90px;
}

.product.product-page {
	padding-bottom: 120px;
}

.product .product-filtering-button {
	margin-bottom: 40px;
	text-align: center;
}

.product .product-filtering-button .nav-tabs {
	border: none;
	border: 1px solid #cfd1d4;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	padding: 5px;
	border-radius: 50px;
}

.product .product-filtering-button .nav-tabs .nav-item .nav-link {
	padding: 0;
	border: none;
	height: 50px;
	padding: 0 50px;
	border-radius: 50px;
	font-weight: 700;
	font-size: 16px;
	text-transform: uppercase;
	color: #414c7a;
	line-height: 50px;
	opacity: 0.8;
}

.product .product-filtering-button .nav-tabs .nav-item .nav-link.active {
	color: #fff;
	opacity: 1;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
}

.product .single-product {
	border: 1px solid #f0f0f0;
	border-radius: 10px;
	margin-bottom: 30px;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	background-color: #F6F6F6;
}

.category-page-wrap .single-product{
	display:block;
}

.product .single-product:hover {
	-webkit-box-shadow: 0px 0px 7px 0px rgba(11, 29, 151, 0.2);
	box-shadow: 0px 0px 7px 0px rgba(11, 29, 151, 0.2);
	border-color: #0b1d97;
	background-color: #ffffff;
	box-shadow: 0px 15px 20px #00000026;
	border: 0.5px solid #21CD7A;
}

.product .single-product:hover .part-img ul {
	opacity: 1;
	visibility: visible;
	left: 10px;
}

.product .single-product .part-img {
	position: relative;
    padding: 5px 5px 0;
    min-height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
	
}

.product .single-product .part-img img {
	padding: 30px;
	width: 100%;
    height: 220px;
    object-fit: contain;
}

.product .single-product .part-img .todas-tag {
	display: inline-block;
	position: absolute;
	top: 20px;
	right: -10px;
	height: 40px;
	line-height: 40px;
	padding: 0 20px;
	background: #000000;
	color: #fff;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 500;
}

.product .single-product .part-img .todas-tag:after {
	position: absolute;
	content: "";
	top: 0;
	left: -20px;
	width: 0;
	height: 100%;
	border: 20px solid #000000;
	border-left-color: transparent;
	z-index: 1;
}

.product .single-product .part-img .todas-tag:before {
	position: absolute;
	content: "";
	top: 50%;
	right: 0;
	width: 10px;
	height: 70%;
	background: #2b6ddd;
	-webkit-transform: skewY(-44deg);
	-ms-transform: skewY(-44deg);
	transform: skewY(-44deg);
	z-index: -1;
}

.product .single-product .part-img ul {
	width: 40px;
	border-radius: 5px;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 5px 0;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	background: #fff;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	left: 0;
}

.product .single-product .part-img ul li a {
	display: inline-block;
	color: #1A0F31;
	position: relative;
	width: 22px;
	height: 34px;
	line-height: 34px;
	font-size: 14px;
	text-align: center;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.product .single-product .part-img ul li a:hover {
	color: #000000;
}

.product .single-product .part-img ul li a:after {
	position: absolute;
	content: '';
	border-bottom: 1px solid #1A0F31;
	width: 100%;
	left: 0;
}

.product .single-product .part-img ul li:first-child a:after {
	display: none;
}

.product .single-product .part-text {
	text-align: center;
	padding-bottom: 30px;
}

.product .single-product .part-text span {
	display: block;
}

.product .single-product .part-text span.product-name {
	font-family: 'Inter', sans-serif;
	font-weight: 500;
	color: #1A0F31;
	font-size: 24px;
	margin-top: 22px;
	margin-bottom: 8px;
}

.product .single-product .part-text span.product-price {
	font-size: 28px;
	font-weight: 600;
	color: #0b1d97;
	margin-bottom: 20px;
}

.product .single-product .part-text .rated {
	display: none;
}

.product .single-product .part-text p {
	display: none;
}

.product .single-product .part-text a.read-more-button {
	display: none;
}

.product .single-product .part-text a.buy-now-button {
	height: 40px;
	width: 165px;
	background: #000000;
	background-image: -moz-linear-gradient(270deg, #1DD27D 0%, #37AE64 100%);
	background-image: -webkit-linear-gradient(270deg, #1DD27D 0%, #37AE64 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	display: inline-block;
	text-align: center;
	line-height: 40px;
	text-transform: uppercase;
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	color: #fff;
	border-radius: 50px;
	font-size: 14px;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.product .single-product .part-text a.buy-now-button:hover {
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.3);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.3);
}

.product .filter-area {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border: 1px solid #dbdce4;
	padding: 10px;
	margin-bottom: 30px;
}

.product .filter-area .left-side ul,
.product .filter-area .right-side ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.product .filter-area .left-side ul li,
.product .filter-area .right-side ul li {
	display: inline-block;
	margin-right: 15px;
}

.product .filter-area .left-side ul li .grid-button,
.product .filter-area .right-side ul li .grid-button {
	background: transparent;
	border: none;
	font-size: 30px;
	height: 45px;
	position: relative;
	top: -2px;
	margin-bottom: -4px;
	color: #555b81;
	cursor: pointer;
}

.product .filter-area .left-side ul li .grid-button:focus,
.product .filter-area .right-side ul li .grid-button:focus {
	color: #000000;
	outline: none;
}

.product .filter-area .left-side ul li form .form-group,
.product .filter-area .right-side ul li form .form-group {
	position: relative;
	margin-bottom: 0;
}

.product .filter-area .left-side ul li form .form-group:after,
.product .filter-area .right-side ul li form .form-group:after {
	position: absolute;
	content: '\f107';
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 15px;
	z-index: -1;
	color: #5d607b;
}

.product .filter-area .left-side ul li form .form-group .form-control,
.product .filter-area .right-side ul li form .form-group .form-control {
	border: 1px solid #dbdce4;
	color: #1A0F31;
	font-size: 16px;
	font-weight: 500;
	font-family: 'Inter', sans-serif;
	padding: 0 30px 0 15px;
	text-transform: capitalize;
	height: 45px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: transparent;
	cursor: pointer;
}

.product .filter-area .left-side ul li form .form-group .form-control:focus,
.product .filter-area .right-side ul li form .form-group .form-control:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	border: 2px solid #dbdce4;
}

.product .filter-area .left-side ul li form .select-number-of-content,
.product .filter-area .right-side ul li form .select-number-of-content {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.product .filter-area .left-side ul li form .select-number-of-content label,
.product .filter-area .right-side ul li form .select-number-of-content label {
	margin: 0;
	margin-right: 10px;
	color: #1A0F31;
	font-size: 16px;
	font-weight: 500;
	font-family: 'Inter', sans-serif;
}

.product .filter-area .right-side ul li:last-child {
	margin-right: 0;
}

.product .filter-area .left-side ul li:first-child {
	margin-right: 4px;
}

.product .pagination-todas {
	text-align: center;
	position: relative;
	margin-top: 20px;
}

.product .pagination-todas:after {
	position: absolute;
	content: '';
	left: 0;
	top: 50%;
	width: 30%;
	height: 1px;
	background: #dbdbdb;
}

.product .pagination-todas:before {
	position: absolute;
	content: '';
	right: 0;
	top: 50%;
	width: 30%;
	height: 1px;
	background: #dbdbdb;
}

.product .pagination-todas ul li {
	display: inline-block;
}

.product .pagination-todas ul li a {
	display: inline-block;
	font-size: 14px;
	font-weight: 700;
	color: #999999;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	background: #f1f5f7;
	border-bottom: 3px solid #dbdbdb;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	margin-right: 6px;
}

.product .pagination-todas ul li a:hover {
	border-color: #000000;
}

.product .pagination-todas ul li:last-child a {
	margin-right: 0;
}

.product .product-details .single-product-view .part-img img {
	width: 100%;
}

.product .product-details .single-product-view .part-img .owl-thumbs {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
    justify-content: center;
	margin: 0 50px;
	position: relative;
	z-index: 2;
}

.product .product-details .single-product-view .part-img .owl-thumbs .owl-thumb-item {
	margin-right: 20px;
	cursor: pointer;
}

.product .product-details .single-product-view .part-img .owl-thumbs .owl-thumb-item:last-child {
	margin-right: 0;
}

.product .product-details .single-product-view .part-img .owl-thumbs .owl-thumb-item .product-pic img {
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.product .product-details .single-product-view .part-img .owl-thumbs .owl-thumb-item.active .product-pic img {
	border: 1px solid #2bbf70;
	border-radius: 5px;
	background-color: #F6F6F6;
}


.product .product-details .single-product-view .part-text span {
	display: block;
}

.product .product-details .single-product-view .part-text span.product-name {
	font-family: 'Inter', sans-serif;
	font-weight: 500;
	color: #1A0F31;
	font-size: 24px;
	margin-top: 7px;
	margin-bottom: 8px;
}

.product .product-details .single-product-view .part-text span.product-price {
	font-size: 28px;
	font-weight: 600;
	color: #0b1d97;
	margin-bottom: 14px;
}

.product .product-details .single-product-view .part-text .rating-box .rated {
	margin-top: -6px;
	margin-bottom: 4px;
	display: inline-block;
	margin-right: 10px;
}

.product .product-details .single-product-view .part-text .rating-box .rated li {
	display: inline-block;
	font-size: 13px;
	color: #000000;
}

.product .product-details .single-product-view .part-text .rating-box span.rated-text {
	display: inline-block;
	color: #2C4836;
	font-size: 14px;
	font-weight: 600;
}

.product .product-details .single-product-view .part-text .text-body p {
	color: #2C4836;
	font-size: 16px;
	line-height: 26px;
	display: block;
	text-align: justify;
}

.product .product-details .single-product-view .part-text .text-body ul li {
	color: #2C4836;
	font-size: 16px;
	line-height: 26px;
	padding: 2px 0;
}

.product .product-details .single-product-view .part-text .text-body ul li i {
	margin-right: 6px;
}

.product .product-details .single-product-view .part-text .cart-button {
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 30px 0;
	margin-top: 21px;
}

.product .product-details .single-product-view .part-text .cart-button .item-selected-count {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	border: 1px solid #e5e5e5;
	height: 40px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 145px;
	border-radius: 50px;
	margin-right: 16px;
}

.product .product-details .single-product-view .part-text .cart-button .item-selected-count button {
	background: transparent;
	border: 0;
	outline: none;
	cursor: pointer;
	color: #2C4836;
	padding: 0 25px;
}

.product .product-details .single-product-view .part-text .cart-button .item-selected-count input.number {
	font-weight: 600;
	color: #2C4836;
	border: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.product .product-details .single-product-view .part-text .cart-button a.add-to-button {
	height: 40px;
	width: 165px;
	background: #000000;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	display: inline-block;
	text-align: center;
	line-height: 40px;
	text-transform: uppercase;
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	color: #fff;
	border-radius: 50px;
	font-size: 14px;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.product .product-details .single-product-view .part-text .cart-button a.add-to-button:hover {
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.3);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.3);
}

.product .product-details .single-product-view .part-text .cat-and-tag {
	margin-bottom: 19px;
	margin-top: 25px;
}

.product .product-details .single-product-view .part-text .cat-and-tag span {
	display: inline-block;
	font-size: 16px;
	color: #2C4836;
	text-transform: capitalize;
	font-family: 'Inter', sans-serif;
}

.product .product-details .single-product-view .part-text .cat-and-tag span a {
	display: inline-block;
	color: #0b1d97;
	margin-left: 5px;
}

.product .product-details .single-product-view .part-text .share-on-social span.text {
	display: inline-block;
	font-size: 16px;
	color: #2C4836;
	text-transform: capitalize;
	font-family: 'Inter', sans-serif;
}

.product .product-details .single-product-view .part-text .share-on-social ul {
	display: inline-block;
	margin-left: 6px;
}

.product .product-details .single-product-view .part-text .share-on-social ul li {
	display: inline-block;
	font-family: 'Inter', sans-serif;
}

.product .product-details .single-product-view .part-text .share-on-social ul li a {
	display: inline-block;
	color: #b3b7c8;
	margin-right: 6px;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.product .product-details .single-product-view .part-text .share-on-social ul li a:hover {
	color: #1A0F31;
}

.product .product-details .single-product-view .product-slider .owl-item .single-product-slider {
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: 30px;
	background-color: #F6F6F6;
}

.product .product-details .single-product-view .product-slider .owl-item .single-product-slider a.test-popup-link {
	display: inline-block;
	width: 40px;
	height: 40px;
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	color: #fff;
	line-height: 40px;
	text-align: center;
	font-size: 16px;
	border-radius: 50%;
	position: absolute;
	top: 30px;
	right: 30px;
	z-index: 2;
}

.product .product-details .single-product-view .product-slider .owl-nav {
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	z-index: -1;
	position: absolute;
	top: calc(100% + 60px);
}

.product .product-details .single-product-view .product-slider .owl-nav div {
	font-size: 6px;
    color: #000000;
	cursor: pointer;
}

.product-content-wrapper .tab-navigation{
	display: flex;
    align-items: center;
	justify-content: center;
}

.product-content-wrapper .tab-navigation .nav-tabs {
	border: none;
	background: #EEEEEE;
	padding: 7px;
	border-radius: 50px;
	border: 1px solid rgba(44, 72, 54, 0.2);
	width: 660px;
	z-index:2;
}
.mylar-product-content-wrapper .tab-navigation .nav-tabs{
        width: 820px !important;
}
.mylar-product-content-wrapper .material-card-wrapper .material-content{
        min-height: initial;
}


.product-content-wrapper .tab-navigation .nav-tabs .nav-item .nav-link {
	font-weight: 700;
	text-transform: uppercase;
	color: #2C4836;
	font-size: 14px;
	border: none;
	padding: 0 30px;
	height: 45px;
	line-height: 45px;
	border-radius: 0;
	width: 160px;
    text-align: center;
}

.product-content-wrapper .tab-navigation .nav-tabs .nav-item .nav-link.active {
	background-color: #D8AC48;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
	color: #fff;
	border-radius: 50px;
}

.product-content-wrapper .tab-navigation .tab-content .tab-pane .descr-body {
	margin-top: 36px;
}

.product-content-wrapper .tab-navigation .tab-content .tab-pane .descr-body h4 {
	color: #1A0F31;
	font-weight: 700;
	font-size: 18px;
	margin-bottom: 16px;
	margin-top: 18px;
}

.descr-for-product .descr-body h2{
	color: #2C4836;
	font-weight: 700;
	font-size: 22px;
	margin-bottom: 16px;
	margin-top: 18px;
}

.single-product-view .product-name{
	font-family: 'Inter', sans-serif;
    font-weight: bold;
    color: #2C4836;
    font-size: 26px;
    margin-top: 7px;
    margin-bottom: 8px;
}

.descr-for-product .descr-body h3{
	color: #2C4836;
	font-weight: 700;
	font-size: 18px;
	margin-bottom: 16px;
	margin-top: 18px;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .descr-body p {
	color: #2C4836;
	font-size: 16px;
	line-height: 26px;
	text-align: justify;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .descr-body ul {
	position: relative;
	padding-bottom: 21px;
	padding-left: 20px;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .descr-body ul:last-child {
	padding-bottom: 0;
	margin-bottom: 0;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .descr-body ul:last-child:after {
	display: none;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .descr-body ul li {
	color: #2C4836;
	line-height: 32px;
	list-style: disc;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .descr-body ul li i {
	margin-right: 15px;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .specification-body {
	margin-top: 36px;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .specification-body h4 {
	color: #1A0F31;
	font-weight: 700;
	font-size: 18px;
	margin-bottom: 16px;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .specification-body ul {
	position: relative;
	padding-bottom: 21px;
	margin-bottom: 26px;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .specification-body ul:after {
	position: absolute;
	content: '';
	left: 0;
	bottom: 0;
	height: 1px;
	right: 0;
	background: #b7c2cf;
	opacity: .5;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .specification-body ul:last-child {
	padding-bottom: 0;
	margin-bottom: 0;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .specification-body ul:last-child:after {
	display: none;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .specification-body ul li {
	color: #2C4836;
	line-height: 32px;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .specification-body ul li i {
	margin-right: 15px;
}

.descr-for-product .specification-body h2{
	color: #2C4836;
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 16px;
    margin-top: 18px;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-box .single-comment {
	position: relative;
	padding: 35px 0 13px;                        
	display: -webkit-box;                       
	display: -ms-flexbox;
	display: flex;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-box .single-comment:after {
	position: absolute;
	content: '';
	left: 100px;
	bottom: 0;
	height: 1px;
	right: 0;
	background: #b7c2cf;
	opacity: .5;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-box .single-comment.reply {
	margin-left: 100px;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-box .single-comment .part-rating {
	position: absolute;
	right: 0;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-box .single-comment .part-rating span.rating-text {
	display: inline-block;
	margin-right: 10px;
	color: #2C4836;
	font-weight: 700;
	font-size: 14px;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-box .single-comment .part-rating ul {
	display: inline-block;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-box .single-comment .part-rating ul li {
	display: inline-block;
	font-size: 12px;
	color: #000000;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-box .single-comment .part-img {
	width: 100px;
	margin-right: 30px;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-box .single-comment .part-img img {
	width: 100%;
	border: 1px solid #000000;
	border-radius: 50%;
	padding: 5px;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-box .single-comment .part-text span {
	display: block;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-box .single-comment .part-text span.name {
	font-family: 'Inter', sans-serif;
	font-size: 20px;
	color: #1A0F31;
	font-weight: 600;
	margin-bottom: 7px;
	margin-top: -6px;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-box .single-comment .part-text span.location {
	color: #2C4836;
	font-size: 14px;
	margin-bottom: 20px;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-box .single-comment .part-text p {
	color: #2C4836;
	font-size: 16px;
	line-height: 26px;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-box .single-comment .part-text .reply-btn {
	display: inline-block;
	position: absolute;
	bottom: -6px;
	left: 40px;
	text-transform: uppercase;
	color: #000000;
	font-size: 16px;
	font-weight: 600;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-form {
	margin-top: 60px;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-form h2.title {
	font-size: 26px;
	font-weight: 700;
	color: #1A0F31;
	margin-bottom: 32px;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-form form input {
	background: #f3f4f6;
	border: 1px solid #f3f4f6;
	border-radius: 5px;
	color: #42435d;
	height: 60px;
	width: 100%;
	padding: 0 30px;
	margin-bottom: 20px;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-form form input:focus {
	background: transparent;
	border-color: #eceef2;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-form form input::-webkit-input-placeholder {
	color: rgba(66, 67, 93, 0.6);
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-form form input:-ms-input-placeholder {
	color: rgba(66, 67, 93, 0.6);
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-form form input::-ms-input-placeholder {
	color: rgba(66, 67, 93, 0.6);
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-form form input::placeholder {
	color: rgba(66, 67, 93, 0.6);
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-form form textarea {
	background: #f3f4f6;
	border: 1px solid #f3f4f6;
	border-radius: 5px;
	color: #42435d;
	height: 160px;
	width: 100%;
	padding: 15px 30px;
	margin-bottom: 20px;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-form form textarea:focus {
	background: transparent;
	border-color: #eceef2;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-form form .rating-comment {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 20px;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-form form .rating-comment span.text {
	font-size: 16px;
	display: inline-block;
	font-weight: 700;
	color: #0b1d97;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-form form .rating-comment ul {
	display: inline-block;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-form form .rating-comment ul li {
	display: inline-block;
	font-size: 12px;
	color: #e2e2e2;
	cursor: pointer;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-form form .rating-comment ul li.active {
	color: #000000;
}

.product .product-details .single-product-view .descr-for-product .tab-content .tab-pane .comment-form form button {
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	color: #fff;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 15px;
	border: none;
	width: 220px;
	height: 60px;
	border-radius: 5px;
	margin-top: 10px;
}

.delete {
	height: 0px;
	overflow: hidden;
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.add {
	height: auto;
	overflow: visible;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.list-layout {
	max-width: 100%;
	-webkit-box-flex: 100%;
	-ms-flex: 100%;
	flex: 100%;
}

.list-layout .single-product {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.list-layout .single-product .part-img {
	width: 40%;
	margin: 10px;
	padding: 0;
}

.list-layout .single-product .part-img img {
	border-radius: 10px;
	padding: 20px;
}

.list-layout .single-product .part-text {
	width: 60%;
	text-align: left;
	padding: 0 40px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.list-layout .single-product .part-text .rated {
	margin-top: -6px;
	margin-bottom: 4px;
	display: block;
}

.list-layout .single-product .part-text .rated li {
	display: inline-block;
	font-size: 13px;
	color: #000000;
}

.list-layout .single-product .part-text span.product-name {
	margin-top: -8px;
}

.list-layout .single-product .part-text span.product-price {
	margin-bottom: 14px;
}

.list-layout .single-product .part-text p {
	color: #2C4836;
	font-size: 16px;
	line-height: 26px;
	display: block;
}

.list-layout .single-product .part-text .read-more-button {
	display: block !important;
	font-size: 16px;
	font-weight: 700;
	color: #1A0F31;
	margin-top: 3px;
}

.list-layout .single-product .part-text .buy-now-button {
	margin-top: 25px;
}

.distributor {
	padding: 120px 0;
	background: #1A0F31;
}

.distributor .jvectormap-container {
	background: transparent !important;
}

.distributor path.jvectormap-region.jvectormap-element {
	fill: #2642a0;
}

.distributor .jvectormap-zoomin,
.distributor .jvectormap-zoomout {
	display: none;
}

.distributor .maps {
	margin-bottom: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.distributor .maps #world-map-gdp {
	width: 970px;
	height: 500px;
}

.distributor .find-dist-btn {
	display: inline-block;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
	padding: 0 50px;
	height: 50px;
	line-height: 50px;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
	border-radius: 50px;
	color: #fff;
	margin-top: 50px;
}

.distributor .single-dist {
	text-align: center;
}

.distributor .single-dist .part-icon {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	margin: 10px 0;
	margin-bottom: 18px;
}

.distributor .single-dist .part-icon:after {
	position: absolute;
	content: '';
	left: -10px;
	top: -10px;
	right: -10px;
	bottom: -10px;
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 50%;
}

.distributor .single-dist .part-text span {
	display: block;
}

.distributor .single-dist .part-text span.number {
	font-family: 'Inter', sans-serif;
	font-size: 60px;
	font-weight: 700;
	color: #fff;
	margin-bottom: -9px;
}

.distributor .single-dist .part-text span.number span {
	display: inline-block;
}

.distributor .single-dist .part-text span.title {
	color: #bac9e6;
	font-size: 16px;
	text-transform: uppercase;
}


 .gallery-slider .single-img {
	border-radius: 10px;
	overflow: hidden;
	-webkit-box-shadow: -1px 7px 29px -8px rgba(0,0,0,0.38);
	-moz-box-shadow: -1px 7px 29px -8px rgba(0,0,0,0.38);
	box-shadow: -1px 7px 29px -8px rgba(0,0,0,0.38);
}

.gallery-slider .owl-nav {
	text-align: center;
	position: relative;
	top: 20px;
	margin-top: 20px;
}

 .gallery-slider .owl-nav div {
	position: absolute;
	display: inline-block;
	width: 50px;
	height: 50px;
	background-color: #ffffff;
    box-shadow: 0px 4px 55px 6px rgba(0, 0, 0, 0.10);
	font-size: 14px;
	color: #1a0f31;
	text-align: center;
	line-height: 50px;
	border-radius: 50%;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	cursor: pointer;
}

.gallery-slider .owl-nav div:hover {
	color: #000000;
    background: #ffffff;
    border-color: #000000;
}

.gallery-slider .owl-nav .owl-prev {
	left: -45px;
    top: -320px;
}
.gallery-slider .owl-nav .owl-next {
	right: -45px;
    top: -320px;
}

.gallery-slider .owl-dots {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 10px;
}

.gallery-slider .owl-dots .owl-dot {
	width: 5px;
	height: 5px;
	border: 1px solid #ffffff;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	border-radius: 50%;
	display: inline-block;
	margin-right: 20px;
}

.gallery-slider .owl-dots .owl-dot.active {
	background-image: -moz-linear-gradient(85deg, #ffffff 0%, #ffffff 100%);
	background-image: -webkit-linear-gradient(85deg, #ffffff 0%, #ffffff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
	border: none;
	width: 10px;
	height: 10px;
}

.testimonial {
	padding: 120px 0 120px;
	background-image: -moz-radial-gradient(50% 50%, circle closest-side, #05358a -50%, #000046 105%);
	background-image: -webkit-radial-gradient(50% 50%, circle closest-side, #28a446 -50%, #000046 105%);
	position: relative;
	z-index: 2;
	overflow: hidden;
}

.testimonial .shape {
	position: absolute;
	content: '';
	left: 50%;
	top: 50%;
	margin-left: -790px;
	margin-top: -790px;
	width: 1580px;
	height: 1580px;
	opacity: 0.3;
	-webkit-animation: circle 480s infinite;
	animation: circle 480s infinite;
}

.testimonial .testimonial-slider .owl-nav {
	text-align: center;
	position: relative;
	margin-top: 30px;
}

.testimonial .testimonial-slider .owl-nav div {
	display: inline-block;
	width: 30px;
	height: 30px;
	font-size: 14px;
	color: #fff;
	text-align: center;
	line-height: 28px;
	border: 1px solid #fff;
	border-radius: 50%;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	cursor: pointer;
}

.testimonial .testimonial-slider .owl-nav div:hover {
	color: #fff;
	background: #000000;
	border-color: #000000;
}

.testimonial .testimonial-slider .owl-nav div:first-child {
	margin-right: 20px;
}

.testimonial .testimonial-slider .single-testimonial {
	text-align: center;
}

.testimonial .testimonial-slider .single-testimonial .part-pic {
	width: 120px;
	height: 120px;
	position: relative;
	margin: 20px 0;
	margin-bottom: 51px;
	display: inline-block;
}

.testimonial .testimonial-slider .single-testimonial .part-pic:before {
	position: absolute;
	content: '';
	left: -20px;
	top: -20px;
	right: -20px;
	bottom: -20px;
	border: 1px dashed #fff;
	border-radius: 50%;
}

.testimonial .testimonial-slider .single-testimonial .part-pic img {
	width: 100%;
	border-radius: 50%;
}

.testimonial .testimonial-slider .single-testimonial .part-text span {
	display: block;
}

.testimonial .testimonial-slider .single-testimonial .part-text span.name {
	font-size: 26px;
	font-weight: 700;
	color: #fff;
	font-family: 'Inter', sans-serif;
	margin-bottom: 3px;
}

.testimonial .testimonial-slider .single-testimonial .part-text span.profession {
	color: #a0afce;
	opacity: .9;
	text-transform: uppercase;
	font-size: 15px;
	margin-bottom: 19px;
}

.testimonial .testimonial-slider .single-testimonial .part-text span.location {
	color: #a0afce;
	font-size: 15px;
}

.testimonial .testimonial-slider .single-testimonial .part-text p {
	color: rgba(255, 255, 255, 0.9);
	line-height: 26px;
}

.faq-w-form .faq-content{
	margin-right: 40px;
}
.faq {
	padding: 70px 0;
}
.faq-w-form .heading-section{
	padding: 20px 0px 60px;
}
 
.faq.faq-page .faq-content .accordion .card {
	border-radius: 50px;
}

.faq .faq-filtering-button {
	margin-bottom: 40px;
	text-align: center;
}

.faq .faq-filtering-button .nav-tabs {
	border: none;
	border: 1px solid #cfd1d4;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	padding: 5px;
	border-radius: 50px;
}

.faq .faq-filtering-button .nav-tabs .nav-item .nav-link {
	padding: 0;
	border: none;
	height: 50px;
	padding: 0 50px;
	border-radius: 50px;
	font-weight: 700;
	font-size: 16px;
	text-transform: uppercase;
	color: #414c7a;
	line-height: 50px;
	opacity: 0.8;
}

.faq .faq-filtering-button .nav-tabs .nav-item .nav-link.active {
	color: #fff;
	opacity: 1;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
}

.faq .faq-content .accordion .card {
	border-bottom: 2px dotted #4A5568 !important;
	margin-bottom: 0px;
	border: none;
	border-radius: 0;
	background-color:transparent;
}

.faq .faq-content .accordion .card:last-child {
	margin-bottom: 0;
	border-bottom: none !important;
}

.faq .faq-content .accordion .card .card-header {
	padding: 0;
	background: transparent;
	border: none;
}

.faq .faq-content .accordion .card .card-header h2 button {
	padding: 25px 0px;
	text-decoration: none;
	font-size: 18px;
	color: #1A0F31;
	font-weight: 600;
	/* -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1); */
	border-radius: 15px;
	width: 100%;
	text-align: left;
	position: relative;
	/* background-color: #ffffff; */
	font-family:'inter', sans-serif;
}


.faq .faq-content .accordion .card .card-header h2 button:after {
	position: absolute;
	right: 5px;
	top: 50%;
	-webkit-transform: translateY(-50%) rotate(0);
	-ms-transform: translateY(-50%) rotate(0);
	transform: translateY(-50%) rotate(0);
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	color: #4A5568;
	height: 20px;
    width: 20px;
    text-align: center;
    line-height: 19px;
    font-size: 30px;
	font-weight: 900;
}

.faq .faq-content .accordion .card .card-header h2 button[aria-expanded="true"] {
	/* background-color: #ffffff; */
	color: #1A0F31;
}

.faq .faq-content .accordion .card .card-header h2 button[aria-expanded="true"]:after {
	/* -webkit-transform: translateY(-50%) rotate(180deg);
	-ms-transform: translateY(-50%) rotate(180deg);
	transform: translateY(-50%) rotate(180deg); */
	content: '\2212';
	font-family: "Font Awesome 5 Free";
	color:#d7ab48;
}

.faq .faq-content .accordion .card .card-header h2 button[aria-expanded="false"]:after {
	/* -webkit-transform: translateY(-50%) rotate(180deg);
	-ms-transform: translateY(-50%) rotate(180deg);
	transform: translateY(-50%) rotate(180deg); */
	content: '\002B';
	font-family: "Font Awesome 5 Free";
}







.faq .faq-content .accordion .card .card-header button {
	padding: 25px 0px;
	text-decoration: none;
	font-size: 18px;
	color: #1A0F31;
	font-weight: 600;
	/* -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1); */
	border-radius: 15px;
	width: 100%;
	text-align: left;
	position: relative;
	/* background-color: #ffffff; */
	font-family:'inter', sans-serif;
}


.faq .faq-content .accordion .card .card-header button:after {
	position: absolute;
	right: 5px;
	top: 50%;
	-webkit-transform: translateY(-50%) rotate(0);
	-ms-transform: translateY(-50%) rotate(0);
	transform: translateY(-50%) rotate(0);
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	color: #4A5568;
	height: 20px;
    width: 20px;
    text-align: center;
    line-height: 19px;
    font-size: 30px;
	font-weight: 900;
}

.faq .faq-content .accordion .card .card-header button[aria-expanded="true"] {
	/* background-color: #ffffff; */
	color: #1A0F31;
}

.faq .faq-content .accordion .card .card-header button[aria-expanded="true"]:after {
	/* -webkit-transform: translateY(-50%) rotate(180deg);
	-ms-transform: translateY(-50%) rotate(180deg);
	transform: translateY(-50%) rotate(180deg); */
	content: '\2212';
	font-family: "Font Awesome 5 Free";
	color:#d7ab48;
}

.faq .faq-content .accordion .card .card-header button[aria-expanded="false"]:after {
	/* -webkit-transform: translateY(-50%) rotate(180deg);
	-ms-transform: translateY(-50%) rotate(180deg);
	transform: translateY(-50%) rotate(180deg); */
	content: '\002B';
	font-family: "Font Awesome 5 Free";
}





.faq .faq-content .accordion .card .card-body {
	padding: 0px 0px 30px;
	color: #4A5568;
	font-size: 15px;
	line-height: 27px;
	margin-top: -10px;
	/* box-shadow: 0px 5px 6px #0000001F; */}

.faq .faq-content .accordion .card .card-body ul{
	margin-left: 30px;
    list-style: disc;
    line-height: 40px;
}
.faq .faq-content .more-faqs{
	padding-top: 25px;
}
.faq .faq-content .more-faqs a{
	font-size: 18px;
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	color:#D8AC48;
}
.faq .faq-content .more-faqs a i{
	margin-right: 10px;
}
.brand {
	padding: 0 0 120px;
}

.brand .brand-slider .single-brand {
	padding: 15px;	
}

.brand .brand-slider .single-brand img{
	height: 250px;
	object-fit: contain;
}
.header .social{
	text-align:right;
}

.header .social a {
	display: inline-block;
	text-align: center;
	width: 40px;
    height: 40px;
    line-height: 40px;
	font-size: 18px;
	background-color:#ffffff;
	border-radius: 50%;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	margin: 0px 4px;
}

.fb-color{
	color:#3b5998 !important;
}
.fb-colorL:hover{
	background-color:#3b5998 !important;
	color:#ffffff !important;
}

.twitter-color{
	color:#00acee !important;
}
.twitter-color:hover{
	background-color:#00acee !important;
	color:#ffffff !important;
}


.linkdin-color{
	color:#0e76a8 !important;
}
.linkdin-color:hover{
	background-color:#0e76a8 !important;
	color:#ffffff !important;
}


.insta-color{
	color:#dc2743 !important; 
}
.insta-color:hover{
	background-color:#dc2743 !important;
	color:#ffffff !important;
}


.pinterest-color{
	color:#E60023 !important;
}
.pinterest-color:hover{
	background-color:#E60023 !important ;
	color:#ffffff !important;
}

.insta-color:hover{
	background: #f09433; 
	background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
	background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
	background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
	color:#ffffff;
}

.linkdin-color:hover{
	background-color:#0e76a8 !important;
	color:#ffffff;
}

.twitter-color:hover{
	background-color:#00acee !important;
	color:#ffffff;
}

.fb-color:hover{
	background-color:#3b5998 !important;
	color:#ffffff !important;
}


.copyright{
	align-items: center;
    justify-content: flex-end;
    display: flex;
}

.compny-info {
	color: #ffffff;
	text-align: justify;
}

.breadcrumb-contact{
	background: url("https://theboxlane.com/images/breadcrumbs/breadcrumb-contact.webp") center center no-repeat;
	background-size: cover;
}

.breadcrumb-qoute{
	background: url("https://theboxlane.com/images/breadcrumbs/breadcrumb-request.webp") center center no-repeat;
	background-size: cover;
}

.breadcrumb-blog{
	background: url("https://theboxlane.com/images/breadcrumbs/breadcrumb-blog.webp") center center no-repeat;
	background-size: cover;
}

.breadcrumb-general {
	background: url("https://theboxlane.com/images/breadcrumbs/breadcrumb-general.webp") center center no-repeat;
	background-size: cover;
}

.breadcrumb-terms{
	background: url("https://theboxlane.com/images/breadcrumbs/breadcrumb-t-c.webp") center center no-repeat;
	background-size: cover;
}

.breadcrumb-todas{
	height: 575px;
}


.breadcrumb-todas .breadcrumb-content {
	padding: 100px 0;
	margin-top: 139px;
}

.breadcrumb-todas .breadcrumb-content h2.title {
	font-size: 44px;
	color: #fff;
	font-weight: 800;
	line-height: 80px;
}

.breadcrumb-todas .breadcrumb-content h1.title {
	font-size: 44px;
	color: #fff;
	font-weight: 800;
	line-height: 80px;
}

.breadcrumb-todas .breadcrumb-content ul li {
	display: inline-block;
	color: #fff;
	position: relative;
	margin-right: 6px;
}

.breadcrumb-todas .breadcrumb-content ul li a {
	display: inline-block;
	color: #fff;
}

.breadcrumb-todas .breadcrumb-content ul li:last-child:after {
	display: none;
}

.breadcrumb-todas .breadcrumb-content ul li:after {
	content: '\f105';
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	margin-left: 10px;
}

.breadcrumb-todas .part-img img {
	width: auto;
	max-width: none;
}

.about {
	padding: 120px 0;
}

.about .part-img {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	margin-right: -30px;
}

.about .part-img img {
	width: auto;
	max-width: none;
	height: 420px;
}

.about .part-txt .title {
	margin-bottom: 6px;
}

.about .part-txt .title .subtitle {
	font-size: 18px;
	color: #000000;
	display: block;
	font-weight: 700;
	text-transform: uppercase;
	margin-top: -6px;
	margin-bottom: 2px;
}

.about .part-txt .title h1{
	font-size: 53px;
	font-family: 'Inter', sans-serif;
	line-height: 63px;
	color: #2C4836;
	font-weight: 700;
	text-transform: capitalize;
	margin-bottom: 11px;
}

.about .part-txt .title h2 {
	font-size: 24px;
	font-family: 'Inter', sans-serif;
	line-height: 63px;
	color: #2C4836;
	font-weight: 700;
	text-transform: capitalize;
}

.about .part-txt .descr h3{
	font-size: 18px;
	font-weight: 700;
    line-height: 30px;
    color: #2C4836;
}

.about .part-txt .descr p {
	font-size: 16px;
	line-height: 30px;
	color: #2C4836;
}

.about .part-txt .descr ul{
	margin-left: 50px;
    line-height: 40px;
	list-style: disc;
	margin-bottom: 10px;
}

.about .part-txt .descr a {
	display: inline-block;
	height: 45px;
	line-height: 45px;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 5px rgba(13, 21, 75, 0.1);
	box-shadow: 0px 0px 10px 5px rgba(13, 21, 75, 0.1);
	color: #fff;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 700;
	padding: 0 30px;
	border-radius: 25px;
	margin-top: 15px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	overflow: hidden;
	position: relative;
	z-index: 2;
}

.about .part-txt .descr a:after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	opacity: 0;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: -1;
}

.about .part-txt .descr a:hover {
	color: #000000;
}

.about .part-txt .descr a:hover:after {
	opacity: 1;
}

.core-value {
	padding: 120px 0 90px;
	background: #f7f9fe;
}

.core-value .single-box {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
	margin-bottom: 30px;
}

.core-value .single-box .part-img {
	height: 55px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 25px;
}

.core-value .single-box .part-img img {
	width: auto;
	height: 100%;
}

.core-value .single-box .part-txt h3 {
	font-size: 24px;
	line-height: 30px;
	font-weight: 700;
	color: #1A0F31;
	margin-bottom: 16px;
}

.core-value .single-box .part-txt p {
	font-size: 16px;
	line-height: 26px;
	color: #2C4836;
	margin-bottom: -7px;
}

.team {
	padding: 120px 0 90px;
}

.team .single-team {
	background: #f7f9fe;
	border: 1px solid transparent;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	margin-bottom: 30px;
}

.team .single-team .part-img img {
	width: 100%;
}

.team .single-team .part-txt {
	text-align: center;
	padding: 30px 0;
}

.team .single-team .part-txt h3 {
	font-size: 22px;
	line-height: 32px;
	font-weight: 700;
	color: #1A0F31;
	margin-top: -7px;
	margin-bottom: 9px;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.team .single-team .part-txt p {
	font-size: 16px;
	line-height: 26px;
	color: #0b1d97;
	text-transform: uppercase;
	margin-bottom: 23px;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.team .single-team .part-txt .social a {
	display: inline-block;
	text-align: center;
	font-size: 13px;
	width: 30px;
	height: 30px;
	line-height: 28px;
	border: 1px solid #7c7c90;
	border-radius: 50%;
	color: #7c7c90;
	margin: 0 5px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.team .single-team:hover {
	background: #000000;
}

.team .single-team:hover .part-txt h3,
.team .single-team:hover .part-txt p {
	color: #fff;
}

.team .single-team:hover .part-txt .social a {
	color: #fff;
	border-color: #fff;
}

.team .single-team:hover .part-txt .social a:hover {
	background: #fff;
	border-color: #fff;
	color: #1A0F31;
}

.product-sidebar {
	top: 0;
	-webkit-transition: 0.6s;
	-moz-transition: 0.6s;
	-o-transition: 0.6s;
	transition: 0.6s;
}

.product-sidebar.elemFixed {
	position: fixed;
	top: 94px;
	z-index: 55;
	width: 270px;
}

.product-sidebar .shopping-cart {
	padding: 20px;
	border-radius: 10px;
	margin-bottom: 10px;
	background: #fff;
	border: 1px solid #F0F0F0;
    box-shadow: 0px 6px 8px #0000000F;
}

.product-sidebar .shopping-cart h3 {
	font-size: 22px;
	font-weight: 700;
	line-height: 28px;
	color: #2C4836;
	text-transform: capitalize;
}

.product-sidebar .shopping-cart ul li .single-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border: 1px solid #EFEFEF;
    border-radius: 11px;
    opacity: 1;
}

.product-sidebar .shopping-cart ul li .single-item:hover{
	box-shadow: 0px 9px 9px #00000012;
}

.product-sidebar .shopping-cart ul li{
	padding-bottom:22px;
}

.product-sidebar .shopping-cart ul li:last-child{
	padding-bottom: 0px;
}

.product-sidebar .shopping-cart ul li .single-item .part-img {
	width: 65px;
	height: 65px;
	overflow: hidden;
	border-radius: 5px;
	background-color: #f6f6f6;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
}

.product-sidebar .shopping-cart ul li .single-item .part-img img {
	padding: 8px;
	object-fit: contain;
	height:65px;
}

.product-sidebar .shopping-cart ul li .single-item .part-text {
	margin-left: 20px;
	padding-top: 25px;
}

.product-sidebar .shopping-cart ul li .single-item .part-text span {
	display: block;
}

.product-sidebar .shopping-cart ul li .single-item .part-text span.title {
	font-size: 16px;
	font-family: 'Inter', sans-serif;
	color: #2C4836;
	font-weight: 600;
	margin-bottom: 2px;
}

.product-sidebar .shopping-cart ul li .single-item .part-text span.price {
	font-weight: 700;
	font-size: 15px;
	color: #0b1d97;
}

.product-sidebar .shopping-cart ul li .single-item .part-text .rated li {
	font-size: 12px;
	color: #000000;
	display: inline-block;
	margin-bottom: 5px;
}

.product-sidebar .shopping-cart ul li:last-child .single-item {
	border-bottom: none;
	padding-bottom: 0;
}

.product-sidebar .shopping-cart ul li:first-child .single-item {
	padding-top: 0;
}

.product-sidebar .shopping-cart .check-out-button {
	display: inline-block;
	height: 40px;
	line-height: 40px;
	padding: 0 35px;
	border-radius: 50px;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
	color: #fff;
	margin-top: 40px;
}

.product-sidebar .searchBar {
	padding: 30px;
	border-radius: 10px;
	-webkit-box-shadow: 0px 0px 4px 0px rgba(13, 21, 76, 0.3);
	box-shadow: 0px 0px 4px 0px rgba(13, 21, 76, 0.3);
	margin-bottom: 30px;
	background: #fff;
}

.product-sidebar .searchBar h3 {
	font-size: 22px;
	font-weight: 700;
	line-height: 28px;
	color: #1A0F31;
	text-transform: capitalize;
	padding-bottom: 22px;
	border-bottom: 2px solid #eeeeee;
	margin-bottom: 30px;
}

.product-sidebar .searchBar form {
	position: relative;
}

.product-sidebar .searchBar form input {
	font-weight: 600;
	font-size: 16px;
	color: rgba(13, 21, 75, 0.8);
	border: none;
	border-bottom: 1px solid #9ea1b7;
	width: 100%;
	padding-bottom: 10px;
}

.product-sidebar .searchBar form input::-webkit-input-placeholder {
	color: rgba(13, 21, 75, 0.5);
}

.product-sidebar .searchBar form input:-ms-input-placeholder {
	color: rgba(13, 21, 75, 0.5);
}

.product-sidebar .searchBar form input::-ms-input-placeholder {
	color: rgba(13, 21, 75, 0.5);
}

.product-sidebar .searchBar form input::placeholder {
	color: rgba(13, 21, 75, 0.5);
}

.product-sidebar .searchBar form button {
	background: transparent;
	border: 0;
	color: #1A0F31;
	position: absolute;
	right: 0;
	background: #fff;
}

.product-sidebar .filterPrice {
	padding: 30px;
	border-radius: 10px;
	-webkit-box-shadow: 0px 0px 4px 0px rgba(13, 21, 76, 0.3);
	box-shadow: 0px 0px 4px 0px rgba(13, 21, 76, 0.3);
	margin-bottom: 30px;
	background: #fff;
}

.product-sidebar .filterPrice h3 {
	font-size: 22px;
	font-weight: 700;
	line-height: 28px;
	color: #1A0F31;
	text-transform: capitalize;
	padding-bottom: 22px;
	border-bottom: 2px solid #eeeeee;
	margin-bottom: 30px;
}

.product-sidebar .filterPrice .filter-out-button {
	display: inline-block;
	height: 40px;
	line-height: 40px;
	padding: 0 35px;
	border-radius: 5px;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
	color: #fff;
	margin-top: 34px;
	-webkit-box-shadow: 7.5px 12.99px 20px 0px rgba(48, 125, 255, 0.2);
	box-shadow: 7.5px 12.99px 20px 0px rgba(48, 125, 255, 0.2);
}

.related-product{
	margin-top:60px;
}

.price-range-slider .slider {
	display: block;
	position: relative;
	height: 36px;
	width: 100%;
	margin-top: -5px;
}

.price-range-slider .slider .slider-touch-right,
.price-range-slider .slider .slider-touch-left {
	display: block;
	position: absolute;
	height: 14px;
	width: 14px;
	z-index: 2;
	top: 50%;
	margin-top: -8px;
}

.price-range-slider .slider .slider-touch-right:hover div.circle .tooltip-todas,
.price-range-slider .slider .slider-touch-left:hover div.circle .tooltip-todas {
	opacity: 1;
}

.price-range-slider .slider .slider-touch-right div.circle,
.price-range-slider .slider .slider-touch-left div.circle {
	display: block;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-image: -moz-linear-gradient(90deg, #2f7cff 0%, #4b8eff 100%);
	background-image: -webkit-linear-gradient(90deg, #2f7cff 0%, #4b8eff 100%);
	-webkit-box-shadow: 0.5px 0.86px 4px 1px rgba(82, 15, 15, 0.2);
	box-shadow: 0.5px 0.86px 4px 1px rgba(82, 15, 15, 0.2);
	border: 3px solid #fff;
	cursor: pointer;
}

.price-range-slider .slider .slider-touch-right div.circle .tooltip-todas,
.price-range-slider .slider .slider-touch-left div.circle .tooltip-todas {
	opacity: 1;
	position: absolute;
	background: #000000;
	color: #fff;
	font-weight: 600;
	bottom: 100%;
	padding: 3px 10px;
	font-size: 14px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	margin-bottom: 6px;
	opacity: 0;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.price-range-slider .slider .slider-touch-right div.circle .tooltip-todas:after,
.price-range-slider .slider .slider-touch-left div.circle .tooltip-todas:after {
	position: absolute;
	content: '';
	left: 50%;
	top: 100%;
	width: 8px;
	background: #000000;
	height: 8px;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	margin-left: -4px;
	margin-top: -4px;
}

.price-range-slider .slider .slider-line {
	position: absolute;
	left: 0;
	top: 16px;
	height: 3px;
	width: 100%;
	border-radius: 4px;
	background: #eeeeee;
	z-index: 0;
	overflow: hidden;
}

.price-range-slider .slider .slider-line span {
	display: block;
	height: 100%;
	width: 0%;
	background: #000000;
}

.price-range-slider .result {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	margin-top: 12px;
}

.price-range-slider .result span {
	display: block;
}

.price-range-slider .result span.text {
	font-size: 16px;
	font-weight: 700;
	color: rgba(18, 31, 89, 0.7);
}

.price-range-slider .result span.number {
	font-size: 16px;
	font-weight: 700;
	color: #0b1d97;
}

.affiliate {
	padding: 120px 0;
	background: #f7f9fe;
}

.affiliate .part-img {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	margin-right: -30px;
}

.affiliate .part-img img {
	width: auto;
	max-width: none;
	height: 420px;
}

.affiliate .part-txt .title {
	margin-bottom: 6px;
}

.affiliate .part-txt .title .subtitle {
	font-size: 18px;
	color: #0b1d97;
	display: block;
	font-weight: 700;
	text-transform: uppercase;
	margin-top: -6px;
	margin-bottom: 6px;
}

.affiliate .part-txt .title h2 {
	font-size: 50px;
	font-family: 'Inter', sans-serif;
	line-height: 60px;
	color: #1A0F31;
	font-weight: 700;
	text-transform: capitalize;
	margin-bottom: 15px;
}

.affiliate .part-txt .descr p {
	font-size: 16px;
	line-height: 28px;
	color: #2C4836;
}

.affiliate .part-txt .descr a {
	display: inline-block;
	height: 45px;
	line-height: 45px;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 5px rgba(13, 21, 75, 0.1);
	box-shadow: 0px 0px 10px 5px rgba(13, 21, 75, 0.1);
	color: #fff;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 700;
	padding: 0 30px;
	border-radius: 25px;
	margin-top: 17px;
	margin-bottom: -7px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	overflow: hidden;
	position: relative;
	z-index: 2;
}

.affiliate .part-txt .descr a:after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	opacity: 0;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: -1;
}

.affiliate .part-txt .descr a:hover {
	color: #000000;
}

.affiliate .part-txt .descr a:hover:after {
	opacity: 1;
}

.become-affiliate {
	padding-top: 120px;
	padding-bottom: 80px;
}

.become-affiliate .single-box {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
	margin-bottom: 16px;
}

.become-affiliate .single-box .part-img {
	height: 70px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	margin-bottom: 23px;
}

.become-affiliate .single-box .part-txt h3 {
	font-size: 24px;
	line-height: 34px;
	font-weight: 700;
	color: #1A0F31;
	margin-bottom: 15px;
	text-transform: capitalize;
}

.become-affiliate .single-box .part-txt p {
	font-size: 16px;
	line-height: 26px;
	color: #2C4836;
}

.how-works {
	padding: 120px 0;
	background: #f7f9fe;
}

.how-works .boxes {
	position: relative;
	z-index: 2;
}

.how-works .boxes .vector-how-it-work {
	position: absolute;
	top: -55px;
}

.how-works .boxes:after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url();
	z-index: -1;
}

.how-works .boxes .single-box {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
}

.how-works .boxes .single-box .part-img {
	width: 100px;
	height: 100px;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	border-radius: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-bottom: 54px;
	position: relative;
}

.how-works .boxes .single-box .part-img span {
	position: absolute;
	top: 50%;
	right: -25px;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 20px;
	font-weight: 600;
	background: #1A0F31;
	border-radius: 50%;
	color: #fff;
}

.how-works .boxes .single-box .part-txt h3 {
	font-size: 24px;
	line-height: 30px;
	font-weight: 700;
	color: #1A0F31;
	margin-bottom: 16px;
}

.how-works .boxes .single-box .part-txt p {
	font-size: 16px;
	line-height: 26px;
	color: #2C4836;
	margin-bottom: -7px;
}

.what-waiting {
	padding: 120px 0;
}

.what-waiting .single-box {
	background-image: -webkit-gradient(linear, left top, left bottom, from(#1b226b), to(#0d154b));
	background-image: -webkit-linear-gradient(#1b226b, #0d154b);
	background-image: -o-linear-gradient(#1b226b, #0d154b);
	background-image: linear-gradient(#1b226b, #0d154b);
	padding: 40px;
	text-align: center;
	border-radius: 10px;
}

.what-waiting .single-box .part-txt h3 {
	font-size: 24px;
	line-height: 30px;
	font-weight: 700;
	color: #fff;
	margin-top: -4px;
	margin-bottom: 16px;
}

.what-waiting .single-box .part-txt p {
	font-size: 16px;
	line-height: 26px;
	color: #d1dbef;
	margin-bottom: 33px;
}

.what-waiting .single-box .part-txt a {
	display: inline-block;
	height: 45px;
	line-height: 45px;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	color: #fff;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 700;
	padding: 0 30px;
	border-radius: 25px;
	margin-bottom: -7px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	overflow: hidden;
	position: relative;
	z-index: 2;
}

.what-waiting .single-box .part-txt a:after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	opacity: 0;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: -1;
}

.what-waiting .single-box .part-txt a:hover {
	color: #000000;
}

.what-waiting .single-box .part-txt a:hover:after {
	opacity: 1;
}


.testimonial-2:after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0d154b;
	opacity: 0.95;
	z-index: -1;
}

.testimonial-2 .testimonial-2-slider .single-testimonial p {
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	margin: 0;
	padding: 30px 40px;
	color: #fff;
	border-radius: 8px;
	line-height: 28px;
	text-align: center;
}

.testimonial-2 .owl-thumbs {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-top: 80px;
}

.testimonial-2 .owl-thumbs .owl-thumb-item {
	text-align: center;
}

.testimonial-2 .owl-thumbs .owl-thumb-item .user-pic {
	width: 70px;
	border: 5px solid #a5a4a5;
	border-radius: 50%;
	display: inline-block;
	margin-bottom: 17px;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	position: relative;
}

.testimonial-2 .owl-thumbs .owl-thumb-item .user-pic img {
	width: 100%;
	border-radius: 50%;
}

.testimonial-2 .owl-thumbs .owl-thumb-item .user-pic:after {
	position: absolute;
	content: '';
	left: 50%;
	width: 20px;
	margin-left: -10px;
	height: 20px;
	top: 0px;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	border-radius: 50px;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	opacity: 0;
}

.testimonial-2 .owl-thumbs .owl-thumb-item .user-pic:before {
	position: absolute;
	content: '';
	left: 50%;
	width: 12px;
	margin-left: -6px;
	height: 12px;
	top: 0px;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	border-radius: 50px;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	opacity: 0;
}

.testimonial-2 .owl-thumbs .owl-thumb-item .user-data {
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.testimonial-2 .owl-thumbs .owl-thumb-item .user-data span {
	display: block;
}

.testimonial-2 .owl-thumbs .owl-thumb-item .user-data span.name {
	font-size: 24px;
	font-family: 'Inter', sans-serif;
	color: #fff;
	font-weight: 600;
	margin-bottom: 4px;
}

.testimonial-2 .owl-thumbs .owl-thumb-item .user-data span.position {
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 700;
	color: rgba(255, 255, 255, 0.8);
}

.testimonial-2 .owl-thumbs .owl-thumb-item.active .user-pic {
	border-color: #000000;
	position: relative;
}

.testimonial-2 .owl-thumbs .owl-thumb-item.active .user-pic:after {
	top: -40px;
	opacity: 1;
}

.testimonial-2 .owl-thumbs .owl-thumb-item.active .user-pic:before {
	top: -60px;
	opacity: 1;
}

.testimonial-2 .owl-thumbs .owl-thumb-item.active .user-data {
	opacity: 1;
	visibility: visible;
}

.brand.affiliate-page {
	padding-top: 120px;
}

.shop-cart {
	padding: 120px 0;
}

.shop-cart .notification-cart {
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.3);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.3);
	border-radius: 5px;
	margin-bottom: 30px;
}

.shop-cart .notification-cart p {
	font-size: 18px;
	line-height: 28px;
	color: #fff;
	padding: 30px;
	text-align: center;
	margin: 0;
}

.shop-cart .successfully-notice {
	background: #1A0F31;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 15px 20px;
	border-radius: 5px;
	margin-bottom: 60px;
}

.shop-cart .successfully-notice p {
	margin: 0;
	color: #fff;
}

.shop-cart .successfully-notice p .check-icon {
	color: #000000;
	margin-right: 10px;
}

.shop-cart .successfully-notice .aply-button {
	height: 40px;
	width: 180px;
	background: #000000;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.4);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.4);
	display: inline-block;
	text-align: center;
	text-transform: uppercase;
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	color: #fff;
	border: none;
	font-size: 14px;
	cursor: pointer;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	border-radius: 5px;
}

.shop-cart .successfully-notice .aply-button:hover {
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.5);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.5);
}

.shop-cart .cart-list-table .table {
	margin-bottom: 0;
}

.shop-cart .cart-list-table .table thead {
	-webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
}

.shop-cart .cart-list-table .table thead tr th {
	font-size: 16px;
	font-weight: 700;
	color: #1A0F31;
	font-family: 'Inter', sans-serif;
	text-transform: uppercase;
	border: none;
	height: 80px;
	text-align: center;
	vertical-align: middle;
}

.shop-cart .cart-list-table .table thead tr th:first-child {
	padding-left: 30px;
	text-align: left;
}

.shop-cart .cart-list-table .table tbody th,
.shop-cart .cart-list-table .table tbody td {
	border: none;
	border-bottom: 1px solid #ecf0f1;
	height: 150px;
	vertical-align: middle;
}

.shop-cart .cart-list-table .table tbody tr.single-item tr {
	width: 480px;
}

.shop-cart .cart-list-table .table tbody tr.single-item th .carted-single-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.shop-cart .cart-list-table .table tbody tr.single-item th .carted-single-item .part-img {
	width: 95px;
}

.shop-cart .cart-list-table .table tbody tr.single-item th .carted-single-item .part-img img {
	width: 100%;
}

.shop-cart .cart-list-table .table tbody tr.single-item th .carted-single-item span.title {
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	color: #1A0F31;
	font-size: 18px;
	margin-left: 30px;
}

.shop-cart .cart-list-table .table tbody tr.single-item td {
	padding: 0;
	vertical-align: middle;
	text-align: center;
}

.shop-cart .cart-list-table .table tbody tr.single-item td .cart-button .cart-item-selected-count {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	border: 1px solid #e5e5e5;
	height: 40px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 145px;
	border-radius: 50px;
	margin-right: 16px;
}

.shop-cart .cart-list-table .table tbody tr.single-item td .cart-button .cart-item-selected-count button {
	background: transparent;
	border: 0;
	outline: none;
	cursor: pointer;
	color: #2C4836;
	padding: 0 25px;
}

.shop-cart .cart-list-table .table tbody tr.single-item td .cart-button .cart-item-selected-count input.number {
	font-weight: 600;
	color: #2C4836;
	border: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.shop-cart .cart-list-table .table tbody tr.single-item td .price,
.shop-cart .cart-list-table .table tbody tr.single-item td .total {
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	color: #1A0F31;
	font-size: 18px;
}

.shop-cart .cart-list-table .table tbody tr.single-item td .remove-item {
	width: 30px;
	height: 30px;
	border-radius: 50px;
	border: none;
	color: #1A0F31;
	color: rgba(18, 31, 89, 0.6);
	-webkit-box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
	box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
	outline: none;
	cursor: pointer;
}

.shop-cart .buttons-for-update-cart {
	margin-top: 30px;
}

.shop-cart .buttons-for-update-cart form {
	position: relative;
}

.shop-cart .buttons-for-update-cart form input {
	border: none;
	width: 100%;
	-webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
	font-size: 14px;
	color: #1A0F31;
	height: 50px;
	padding: 0 30px;
	padding-right: 195px;
}

.shop-cart .buttons-for-update-cart form input::-webkit-input-placeholder {
	color: rgba(18, 31, 89, 0.6);
}

.shop-cart .buttons-for-update-cart form input:-ms-input-placeholder {
	color: rgba(18, 31, 89, 0.6);
}

.shop-cart .buttons-for-update-cart form input::-ms-input-placeholder {
	color: rgba(18, 31, 89, 0.6);
}

.shop-cart .buttons-for-update-cart form input::placeholder {
	color: rgba(18, 31, 89, 0.6);
}

.shop-cart .buttons-for-update-cart form .aply-button {
	position: absolute;
	top: 0;
	right: 0;
}

.shop-cart .buttons-for-update-cart .aply-button {
	height: 50px;
	width: 165px;
	background: #000000;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.4);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.4);
	display: inline-block;
	text-align: center;
	line-height: 40px;
	text-transform: uppercase;
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	color: #fff;
	border: none;
	font-size: 14px;
	cursor: pointer;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.shop-cart .buttons-for-update-cart .aply-button:hover {
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.5);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.5);
}

.shop-cart .shop-cart-footer {
	margin-top: 60px;
}

.shop-cart .shop-cart-footer .calculating-shipping h4.title {
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	color: #1A0F31;
	margin-bottom: 23px;
	margin-top: -6px;
}

.shop-cart .shop-cart-footer .calculating-shipping form select,
.shop-cart .shop-cart-footer .calculating-shipping form input {
	width: 100%;
	border: 1px solid #dddddd;
	border-radius: 5px;
	height: 50px;
	padding: 0 30px;
	font-size: 15px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	color: #1A0F31;
	margin-bottom: 20px;
}

.shop-cart .shop-cart-footer .calculating-shipping form .update-total-button {
	height: 50px;
	width: 180px;
	background: #000000;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
	display: inline-block;
	text-align: center;
	line-height: 40px;
	text-transform: uppercase;
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	color: #fff;
	border: none;
	font-size: 14px;
	cursor: pointer;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	margin-top: 20px;
}

.shop-cart .shop-cart-footer .calculating-shipping form .update-total-button:hover {
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.5);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.5);
}

.shop-cart .shop-cart-footer .cart-total h4.title {
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	color: #1A0F31;
	margin-bottom: 23px;
	margin-top: -6px;
}

.shop-cart .shop-cart-footer .cart-total ul {
	border-top: 1px solid #eeeeee;
	border-bottom: 1px solid #eeeeee;
	padding: 15px 0;
}

.shop-cart .shop-cart-footer .cart-total ul li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 7px 0;
}

.shop-cart .shop-cart-footer .cart-total ul li span {
	display: inline-block;
}

.shop-cart .shop-cart-footer .cart-total ul li span.name {
	font-weight: 600;
	color: #2C4836;
}

.shop-cart .shop-cart-footer .cart-total ul li span.details {
	color: #2C4836;
}

.shop-cart .shop-cart-footer .cart-total .total-calculate {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 23px 0 33px;
}

.shop-cart .shop-cart-footer .cart-total .total-calculate span {
	display: inline-block;
	font-size: 18px;
	font-weight: 600;
	color: #1A0F31;
}

.shop-cart .shop-cart-footer .cart-total .check-out-button {
	height: 50px;
	width: 230px;
	background: #000000;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.4);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.4);
	display: inline-block;
	text-align: center;
	line-height: 40px;
	text-transform: uppercase;
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	color: #fff;
	border: none;
	font-size: 14px;
	cursor: pointer;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.shop-cart .shop-cart-footer .cart-total .check-out-button:hover {
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.5);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.5);
}

.cart-iz-empty .cart-empty-content {
	background-color: #fafafa;
	border: 1px solid #e1e8ed;
	padding: 50px;
	text-align: center;
	display: none;
}

.cart-iz-empty .cart-empty-content img {
	width: 200px;
	margin-bottom: 44px;
}

.cart-iz-empty .cart-empty-content h2 {
	font-size: 30px;
	font-weight: 700;
	color: #1A0F31;
	margin-bottom: 14px;
}

.cart-iz-empty .cart-empty-content p {
	font-size: 17px;
	color: #2C4836;
}

.cart-iz-empty .cart-empty-content p a {
	color: #000000;
	font-weight: 500;
	text-decoration: underline;
}

.repair {
	padding: 120px 0;
}

.repair .part-img {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.repair .part-img img {
	max-width: none;
	width: auto;
	height: 750px;
}

.repair .part-text {
	padding-left: 30px;
	margin-bottom: -25px;
}

.repair .part-text .title {
	margin-bottom: 65px;
}

.repair .part-text .title .subtitle {
	font-size: 18px;
	color: #0b1d97;
	display: block;
	font-weight: 700;
	text-transform: uppercase;
	margin-top: -6px;
	margin-bottom: 9px;
}

.repair .part-text .title h2 {
	font-size: 53px;
	font-family: 'Inter', sans-serif;
	line-height: 63px;
	color: #1A0F31;
	font-weight: 700;
	text-transform: capitalize;
	margin-bottom: 25px;
}

.repair .part-text .title p {
	font-size: 16px;
	line-height: 26px;
	color: #2C4836;
}

.repair .part-text .descr .single-box .part-icon {
	height: 60px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	margin-bottom: 14px;
}

.repair .part-text .descr .single-box .part-icon img {
	height: 100%;
}

.repair .part-text .descr .single-box .part-txt h3 {
	font-size: 24px;
	font-weight: 700;
	line-height: 34px;
	color: #1A0F31;
	margin-bottom: 10px;
}

.repair .part-text .descr .single-box .part-txt p {
	font-size: 16px;
	line-height: 26px;
	color: #2C4836;
}

.easy-step:after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0d154b;
	opacity: 0.95;
	z-index: -1;
}

.easy-step .single-step {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
	margin-bottom: 27px;
}

.easy-step .single-step .part-icon {
	height: 65px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	margin-bottom: 15px;
}

.easy-step .single-step .part-txt h3 {
	font-size: 24px;
	line-height: 34px;
	font-weight: 700;
	color: #fff;
	margin-bottom: 15px;
}

.easy-step .single-step .part-txt p {
	font-size: 16px;
	line-height: 26px;
	color: #fff;
}

.easy-step .section-btn {
	text-align: center;
}

.easy-step .section-btn a {
	display: inline-block;
	height: 45px;
	line-height: 45px;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	color: #fff;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 700;
	padding: 0 30px;
	border-radius: 25px;
	margin-bottom: -7px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	overflow: hidden;
	position: relative;
	z-index: 2;
}

.easy-step .section-btn a:after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	opacity: 0;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: -1;
}

.easy-step .section-btn a:hover {
	color: #000000;
}

.easy-step .section-btn a:hover:after {
	opacity: 1;
}

.brand.warranty-page {
	padding-top: 120px;
}

.support {
	padding: 120px 0 90px;
}

.support.drone-solution .single-box .part-txt {
	text-align: center;
}

.support.drone-solution .single-box .part-txt h3 {
	margin-bottom: 14px;
}

.support.drone-solution .single-box .part-txt p {
	font-size: 16px;
	line-height: 28px;
	color: #2C4836;
	margin-bottom: 0;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.support.drone-solution .single-box:hover .part-txt p {
	color: rgba(255, 255, 255, 0.8);
}

.support .single-box {
	-webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 30px;
	padding: 40px 30px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.support .single-box .part-icon {
	width: 65px;
	height: 65px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 22px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.support .single-box .part-txt h3 {
	font-size: 24px;
	line-height: 34px;
	font-weight: 700;
	color: #1A0F31;
	margin-bottom: -9px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.support .single-box:hover {
	background: #1A0F31;
}

.support .single-box:hover .part-icon {
	border-color: #fff;
}

.support .single-box:hover .part-txt h3 {
	color: #fff;
}

.support-contact {
	padding: 120px 0;
	background-image: -moz-linear-gradient(24deg, #0e154b 0%, #3d49cd 100%);
	background-image: -webkit-linear-gradient(24deg, #0e154b 0%, #3d49cd 100%);
	position: relative;
	z-index: 2;
}

.support-contact:after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/*background: url("../img/support-bg.png") center center no-repeat;*/
	background-size: cover;
	z-index: -1;
}

.support-contact .part-txt {
	text-align: center;
}

.support-contact .part-txt h2 {
	font-size: 30px;
	line-height: 50px;
	font-weight: 700;
	color: #fff;
	margin-top: -10px;
	margin-bottom: 25px;
}

.support-contact .part-txt a {
	display: inline-block;
	height: 45px;
	line-height: 45px;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	color: #fff;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 700;
	padding: 0 30px;
	border-radius: 25px;
	margin-bottom: -7px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	overflow: hidden;
	position: relative;
	z-index: 2;
}

.support-contact .part-txt a:after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	opacity: 0;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: -1;
}

.support-contact .part-txt a:hover {
	color: #000000;
}

.support-contact .part-txt a:hover:after {
	opacity: 1;
}

.brand.support-page {
	padding-top: 120px;
}

.contact {
	box-shadow: 0px 5px 14px #0000001A;
    border: 1px solid #F6F6F6;
}

.contact .part-txt .title {
	margin-bottom: 57px;
}

.contact .part-txt .title .subtitle {
	font-size: 18px;
	color: #0b1d97;
	display: block;
	font-weight: 700;
	text-transform: uppercase;
	margin-top: -6px;
	margin-bottom: 0px;
}

.contact .part-txt .title h2 {
	font-size: 53px;
	font-family: 'Inter', sans-serif;
	line-height: 63px;
	color: #1A0F31;
	font-weight: 700;
	text-transform: capitalize;
	margin-bottom: 15px;
}

.contact .part-txt .title p {
	font-size: 16px;
	line-height: 26px;
	color: #2C4836;
}

.contact .part-txt .single-box {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.contact .part-txt .single-box .part-icon {
	margin-right: 20px;
}

.contact .part-txt .single-box .part-icon {
	width: 65px;
	height: 65px;
	text-align: center;
	line-height: 65px;
	color: #000000;
	border-radius: 5px;
}

.contact .part-txt .single-box .part-icon img{
	width:50px;
}


.contact .part-txt .single-box .txt {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.contact .part-txt .single-box .txt p {
	font-size: 16px;
	line-height: 26px;
	margin-bottom: 0;
	color: #2C4836;
	font-weight: 600;
}

.contact .part-txt .social {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-top: 20px;
}

.contact .part-txt .social h3 {
	font-family: 'Inter', sans-serif;
	font-size: 18px;
	line-height: 28px;
	font-weight: 700;
	color: #1A0F31;
	margin-bottom: 0;
	margin-right: 10px;
}

.contact .part-txt .social a {
	display: inline-block;
	text-align: center;
	width: 30px;
	height: 30px;
	line-height: 30px;
	font-size: 13px;
	color: #1A0F31;
	border: 1px solid #1A0F31;
	border-radius: 50%;
	margin-left: 10px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.contact .part-txt .social a:hover {
	background: #000000;
	border-color: #000000;
	color: #fff;
}

.contact .contact-form {
	padding: 30px;
	position: relative;
	z-index: 2;
}


.contact .contact-form h3 {
	font-size: 24px;
	line-height: 24px;
	font-weight: 700;
	color: #2C4836;
	margin-top: -3px;
	margin-bottom: 35px;
}

.contact .contact-form form input {
	width: 100%;
	height: 50px;
	margin-bottom: 20px;
	border-radius: 10px;
	padding: 0 30px;
	border: #f6f6f6;
	background-color:#F6F6F6;
}

.contact .contact-form form select{
	width: 100%;
	height: 50px;
	margin-bottom: 20px;
	border-radius: 10px;
	padding: 0 30px;
	border: #f6f6f6;
	background-color:#F6F6F6;
}

.contact .contact-form form select:focus{
	outline: #000000;
    border-color: #000000;
    box-shadow: 0 0 7px 0rem rgb(49 138 74);
}


.contact .contact-form form input:focus{
	outline: #000000;
    border-color: #000000;
    box-shadow: 0 0 7px 0rem rgb(49 138 74);
}


.contact .contact-form form textarea {
	width: 100%;
	height: 140px;
	margin-bottom: 20px;
	border: #f6f6f6;
	border-radius: 10px;
	padding: 15px 30px;
	border: #f6f6f6;
	background-color:#F6F6F6;
}

.contact .contact-form form button:focus{
	outline: none;
}

.contact .contact-form form button {
	display: inline-block;
	height: 45px;
	line-height: 45px;
	background-image: -moz-linear-gradient(270deg, #1DD27D 0%, #37AE64 100%);
	background-image: -webkit-linear-gradient(270deg, #1DD27D 0%, #37AE64 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	color: #fff;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 700;
	padding: 0 30px;
	border: 0px;
	border-radius: 25px;
	cursor: pointer;
	margin-bottom: -7px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	overflow: hidden;
	position: relative;
	z-index: 2;
}

.contact .contact-form form button:after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	opacity: 0;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: -1;
}

.contact .contact-form form button:hover {
	color: #000000;
}

.contact .contact-form form button:hover:after {
	opacity: 1;
}

.qoute-bg{
	background-color:#F6F6F6;
}

.map iframe {
	width: 100%;
	height: 600px;
	display: block;
}

.brand.contact-page {
	padding-top: 120px;
}

.error {
	padding: 30px 0;
}

.error .error-content {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.error .error-content .part-txt {
	text-align: center;
}

.error .error-content .part-txt h3 {
	font-size: 36px;
	line-height: 34px;
	font-weight: 700;
	color: #d7ab48;
	margin-bottom: 14px;
}

.error .error-content .part-txt p {
	font-size: 16px;
	line-height: 26px;
	color: #2C4836;
	margin-bottom: 37px;
}

.error .error-content .part-txt .thanku{
	font-size: 18px;
    line-height: 26px;
    color: #2C4836;
    margin-bottom: 37px;
    font-weight: bold;
    margin-top: 30px;
}

.error .error-content .part-txt a {
	display: inline-block;
	height: 45px;
	line-height: 45px;
	background-image: -moz-linear-gradient(270deg, #1DD27D 0%, #37AE64 100%);
	background-image: -webkit-linear-gradient(270deg, #1DD27D 0%, #37AE64 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	color: #fff;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 700;
	padding: 0 30px;
	border-radius: 25px;
	margin-bottom: -7px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	overflow: hidden;
	position: relative;
	z-index: 2;
}

.error .error-content .part-txt a:after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	opacity: 0;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: -1;
}

.thankyou{
	background: url(https://theboxlane.com/images/general-banners/thanks-banner.webp) center center no-repeat;
    background-size: cover;
}

.error .error-content .part-txt a:hover {
	color: #000000;
}

.error .error-content .part-txt a:hover:after {
	opacity: 1;
}

.membership {
	padding: 120px 0 80px;
}

.membership .single-membership {
	margin-bottom: 32px;
	text-align: center;
}

.membership .single-membership .part-icon {
	width: 65px;
	height: 65px;
	background-image: -moz-linear-gradient(24deg, #000000 0%, #3d49cd 100%);
	background-image: -webkit-linear-gradient(24deg, #000000 0%, #3d49cd 100%);
	border-radius: 50px;
	text-align: center;
	line-height: 65px;
	color: #fff;
	font-size: 20px;
	position: relative;
	margin: 6px;
	margin-bottom: 30px;
	display: inline-block;
}

.membership .single-membership .part-icon:after {
	position: absolute;
	content: '';
	left: -6px;
	top: -6px;
	right: -6px;
	bottom: -6px;
	border: 1px solid #1A0F31;
	border-radius: 50%;
}

.membership .single-membership .part-text span {
	display: block;
}

.membership .single-membership .part-text span.title {
	font-family: 'Inter', sans-serif;
	font-size: 22px;
	line-height: 32px;
	color: #1A0F31;
	font-weight: 700;
	margin-bottom: 14px;
}

.membership .single-membership .part-text p {
	color: #2C4836;
	line-height: 26px;
	font-size: 16px;
}

.join-todas {
	background-image: -moz-linear-gradient(24deg, #0e154b 0%, #3d49cd 100%);
	background-image: -webkit-linear-gradient(24deg, #0e154b 0%, #3d49cd 100%);
	padding: 50px 0;
}

.join-todas h2 {
	font-size: 24px;
	font-weight: 700;
	color: #fff;
	margin-bottom: 18px;
	margin-top: -5px;
}

.join-todas p {
	color: #fff;
	margin: 0;
}

.join-todas a.join-todas-button {
	display: inline-block;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	border-radius: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	width: 180px;
	font-size: 14px;
	color: #fff;
	text-transform: uppercase;
	font-weight: 700;
}

.brand.membership-page {
	padding-top: 120px;
}

.gallery-page {
	padding: 120px 0;
}

.gallery-page .all-images .grid-item,
.gallery-page .all-images .grid-sizer {
	width: calc(100% / 3);
}

.gallery-page .all-images .grid-item {
	float: left;
	margin-bottom: 30px;
}

.gallery-page .all-images .grid-item:hover .overlay {
	opacity: 1;
	visibility: visible;
}

.gallery-page .all-images .grid-item .overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background: rgba(18, 31, 89, 0.4);
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

.gallery-page .all-images .grid-item .overlay a.view-btn {
	display: inline-block;
	width: 40px;
	height: 40px;
	border-radius: 50px;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
	text-align: center;
	line-height: 40px;
	color: #fff;
}

.gallery-page .all-images .grid-item img {
	width: 100%;
}

.gallery-page .all-images .grid-item.grid-item-4 {
	margin: 0 15px;
	width: calc((100% / 3) - 30px);
}

.gallery-page .all-images .grid-item--width2 {
	width: calc(100% / 1.5);
}

.gallery-page .all-images .grid-item--width2.mr-30 {
	width: calc((100% / 1.5) - 15px);
	margin-right: 15px;
}

.gallery-page .all-images .grid-item--width2.ml-30 {
	width: calc((100% / 1.5) - 15px);
	margin-left: 15px;
}

.gallery-page .all-images .grid-item--height2.mr-30 {
	width: calc((100% / 3) - 15px);
	margin-right: 15px;
}

.gallery-page .all-images .grid-item--height2.ml-30 {
	width: calc((100% / 3) - 15px);
	margin-left: 15px;
}

.gallery-page .our-gallery-slider .owl-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	top: 22px;
}

.gallery-page .our-gallery-slider .owl-nav div {
	width: 35px;
	height: 35px;
	font-size: 5px;
	text-align: center;
	line-height: 35px;
	border-radius: 50%;
	color: #fff;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
	position: relative;
	z-index: 3;
	cursor: pointer;
}

.gallery-page .our-gallery-slider .owl-nav div:first-child {
	margin-right: 100px;
}

.gallery-page .our-gallery-slider .owl-dots {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 11px;
	position: relative;
	z-index: 2;
	margin-bottom: 11px;
}

.gallery-page .our-gallery-slider .owl-dots .owl-dot {
	width: 5px;
	height: 5px;
	border: 1px solid #000000;
	border-radius: 50%;
	margin: 0 10px;
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	cursor: pointer;
}

.gallery-page .our-gallery-slider .owl-dots .owl-dot.active {
	width: 11px;
	height: 11px;
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.2);
}

.divide-box {
	text-align: center;
}

.divide-box-menu {
	background: #f3f4f5;
	display: inline-block;
	border-radius: 50px;
	margin-bottom: 50px;
}

.divide-box-menu li {
	display: inline-block;
}

.divide-box-menu li button {
	border: none;
	height: 50px;
	width: 160px;
	text-transform: uppercase;
	font-weight: 700;
	color: #1A0F31;
	background: transparent;
	outline: none;
	cursor: pointer;
}

.divide-box-menu li button.active {
	background-image: -moz-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	background-image: -webkit-linear-gradient(85deg, #2d7bff 0%, #4c8eff 100%);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	box-shadow: 0px 0px 10px 0px rgba(13, 21, 75, 0.1);
	border: none;
	height: 50px;
	width: 160px;
	border-radius: 50px;
	text-transform: uppercase;
	font-weight: 700;
	color: #fff;
}

.single-divide-box {
	-webkit-transition: 1s;
	-moz-transition: 1s;
	-o-transition: 1s;
	transition: 1s;
}

.el-hidden {
	height: 0;
	overflow: hidden;
	-webkit-transform: scale(0.95);
	-ms-transform: scale(0.95);
	transform: scale(0.95);
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}

/* About Us Page begin */
.about-page .product-content-banner-wrapper .product-content-banner-wrap p{
	color: #4A5568;
	text-align: justify;
	font-family: 'Inter', sans-serif;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 30px; /* 166.667% */
}
.our-vision-wrapper{
	background: #F9F9F9;
	padding: 40px 0px 70px;
}
.vision-content img{
	padding-bottom: 20px;
}
.vision-content h3{
	color: #1A0F31;
	font-family: 'gilroy', sans-serif;
	font-size: 22px;
	font-style: normal;
	font-weight: 700;
	line-height: 32px; /* 145.455% */
}
.vision-content p{
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 26px; /* 173.333% */
	text-align: justify;
	color:#4A5568;
}
.about-page .page-banner{
	padding: 70px 0px 90px;
    background-repeat: no-repeat;
    background-size: cover;
}
.about-page .page-banner .baner-sub{
	color: #1A0F31;
	font-family: 'gilroy', sans-serif;
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: 180%; /* 39.6px */
	text-transform: uppercase;
	display: inline-block;
    padding-bottom: 15px;
}
.about-page .page-banner .sign-line::before {
    top: 25px;
}
.about-page .product-content-wrapper .product-content-wrap h2{
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: 46px; /* 153.333% */
	color:#1A0F31;
	font-family: 'gilroy', sans-serif;
}
.about-page .product-content-wrapper .product-content-wrap p{
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 26px; /* 144.444% */
	color:#4A5568;
	font-family: 'Inter', sans-serif;
	padding-bottom: 25px;
	margin-bottom: 0;
}
.about-page .product-content-wrapper .product-content-wrap ul{
	padding: 10px 0 20px;
}
.about-page .product-content-wrapper .product-content-wrap ul li{
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 26px; /* 144.444% */
	color:#4A5568;
	font-family: 'Inter', sans-serif;
	padding-bottom: 15px;
}
.about-page .product-content-wrapper .product-content-wrap ul li svg{
	margin-right:10px;
}
.about-page .product-content-wrapper .product-content-wrap{
	padding-left: 0px;
	padding-right: 70px;
}
.about-page .product-content-banner-wrapper{
	margin: 70px 0px 70px;
}
.about-page .product-content-wrapper{
	padding: 80px 0px;
}
.why-choose-about-wrapper{
	background: #EFF7FE;
	padding: 80px 0px;
}
.why-choose-about-wrapper .why-choose-about-content h2{
	color: #1A0F31;
	font-family: 'Inter', sans-serif;
	font-size: 40px;
	font-style: normal;
	font-weight: 700;
	line-height: 110%;
}
.why-choose-about-wrapper .why-choose-about-content p{
	color: var(--second-txt, #4A5568);
	font-family: 'inter', sans-serif;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 30px; /* 200% */
	margin-bottom: 0;
	padding-bottom: 20px;
}
/* about-usp-wrapper begin */
.about-usp-wrapper{
    padding: 0px 0px 60px;
    border-bottom: 1px solid #CBD5E1;
}
.about-usp-wrapper .container{
    border-top: 1px solid #CBD5E1;
    padding-top: 40px;
}
.about-usp-wrapper .about-usp-item{
    text-align: center;
}
.about-usp-wrapper .about-usp-item img{
    width: 124px;
    height: 124px;
}
.about-usp-wrapper .about-usp-item h3{
    font-family: 'inter', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 145.455% */
    padding: 15px 0px;
    margin-bottom: 0;
}
.about-usp-wrapper .about-usp-item p{
    color:#4A5568;
    text-align: center;
    font-family: 'gilroy', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 173.333% */
    padding: 0px 20px 20px;
}
/* about-usp-wrapper end */

/* About Us Page end */


/* Custom Css begin */
/* Globel css begin */
body{
  color:#111705;
}
p{
  color:#828282;
}
a:hover{
  color: #D8AC48;
}
.heading-section{
  padding:20px 0px 55px;
  text-align: center;
  position: relative;
}
.heading-section h2{
  color:  #1A0F31;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 48px */
  padding-bottom:15px;
}
.heading-section p{
  width: 90%;
  margin: auto;
  color: var(--second-txt, var(--Sub-text, #4A5568));
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
}
.heading-dark h2{
  color:  #1A0F31 !important;
}
.heading-dark p{
  color:  #1A0F31 !important;
}
.heading-lite h2{
  color:  #ffffff !important;
}
.heading-lite p{
  color:  #ffffff !important;
}
button:focus:not(:focus-visible){
  box-shadow: none;
}
/* .heading-section span::before{
  content: '';
  position: absolute;
  height: 18px;
  width: 2px;
  background-color: #d8ac48;
  margin: 3px -2px 0px -8px;
} */
.left-borde-before{
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 10px;
  margin-left: 10px;
}
.left-borde-before::before{
  content: '';
  position: absolute;
  height: 18px;
  width: 2px;
  background-color: #d8ac48;
  margin: 3px -2px 0px -8px;
}
.yellow-bg{
  background-color:#FFFAF0;
}
.gray-bg{
  background-color:#FFFAF0;
}
.mobile-version{
  display:none !important;
}
.blue-bg{
  background-color:#F3FBFD;
}
.yellow-bg-color{
  background-color:#F5ECD8;
}
.yellow-text{
  color:#D8AC48;
}
.mobile-version-wrapper{
  display: none;
}
.owl-nav div {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  box-shadow: 0px 4px 55px 6px rgba(0, 0, 0, 0.10);
  font-size: 14px;
  color: #1a0f31;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  cursor: pointer;
}
.owl-nav .owl-prev {
  left: -20px;
  top: 120px;
}
.owl-nav .owl-next {
  right: -20px;
  top: 120px;
}


/* Globel css end */

.btn-yellow{
  background-color:#D8AC48;
  color:#ffffff;
  border:1px solid #D8AC48;
  box-shadow: 4px 5px 15px rgba(216, 172, 72, 0.40);
  padding: 0.575rem 2.5rem;
}
.btn{
  font-weight: 500;
}
.btn-black{
  background-color:#1A0F31;
  color:#ffffff;
  font-weight: 500;
  border:1px solid #1A0F31;
  /* box-shadow: 4px 5px 15px rgba(216, 172, 72, 0.40); */
  padding: 0.775rem 1.2rem;
}
.w-icon-right i{
  margin-left:5px;
}
.btn-black:hover{
  color:#1A0F31;
  border:1px solid #1A0F31;
  background-color: transparent;
}
.btn-transparent{
  background-color: transparent;
  color:#1A0F31;
  font-weight: 500;
  border:1px solid #1A0F31;
  /* box-shadow: 4px 5px 15px rgba(216, 172, 72, 0.40); */
  padding: 0.775rem 1.2rem;
}
.btn-transparent:hover{
  background-color: #1A0F31;
  color:#ffffff;
  font-weight: 500;
  border:1px solid #1A0F31;
}

.btn-yellow:hover{
  color:#D8AC48;
  border:1px solid #D8AC48;
  background-color: transparent;
}
.yellow-text{
  color:#D8AC48;
}
.yellow-dot{
  color:#D8AC48;
  font-size:40px;
}

/* Header sticky begin  */
.breadcrump-wrapper{
  background-color:#ffffff;
  padding:14px 0px;
}
.breadcrump-wrapper .breadcrump-menu ul{
  display:inline-flex;
}
.breadcrump-wrapper .breadcrump-menu ul li{
  padding:0px 10px;
  color:#4A5568;
  font-size:12px;
  font-family: 'Gilroy', sans-serif;
}
.breadcrump-wrapper .breadcrump-menu ul li:first-child{
  padding-left:0px;
}
.breadcrump-wrapper .breadcrump-menu ul li a{
  font-weight:bold;
  color: #d8ac48;
}
.breadcrump-wrapper .breadcrump-menu ul li a:hover{
  color:#d8ac48;
}
.breadcrump-wrapper .breadcrump-menu ul li i{
  padding-right:8px;
  color:#111705;
}
.breadcrump-wrapper .breadcrump-menu ul li svg{
  padding-right: 3px;
  color: #111705;
  margin-bottom: 2px;
}
.breadcrump-wrapper .breadcrump-menu ul li .home-crump{
  color:#d7ab48;
}
.breadcrump-wrapper .breadcrump-links{
  text-align:right;
}
.breadcrump-wrapper .breadcrump-links li a{
  color: #828282;
  font-size:12px;
}
.breadcrump-wrapper .breadcrump-links li{
  color: #828282;
  font-size:12px;
}
.breadcrump-wrapper .breadcrump-links span{
  height: 14px;
  width: 1px;
  background: #828282;
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 10px;
}
.breadcrump-wrapper .breadcrump-links ul{
  display:inline-flex;
}
.breadcrump-banner-wrapper{
  background-image: url('https://theboxlane.com/assets/images/support-page-banner.webp');
  background-color: #F5ECD8;
  padding: 80px 0px;
  margin-bottom: 60px;
  background-size: contain;
  background-repeat: no-repeat;
}
.breadcrump-banner-wrapper .page-name h1{
  font-size: 40px;
  font-weight: 600;
}
.upper-header{
  background-color: #1A0F31;
  padding:10px 0px;
}
.upper-header .upper-header-content p{
  margin-bottom:0;
  text-align:center;
  font-size: 14px;
  color: #d8ac48;
  font-weight: 600;
}
.upper-header .upper-header-content p a{
  color: #d8ac48;
  border-bottom: 1px solid;
}
.search-form{
  box-shadow: 0px 1px 10px 0px #0000001A;
}

.search-form input{
  height:56px;
  border: none;
  border-radius: 0px 10px 10px 0px !important;
}
.search-form .input-group-text{
  height: 56px;
  border: none;
  background-color: #ffffff;
  border-radius: 10px 0px 0px 10px;
}
.search-form .input-group-text i{
  color:#4a5568;
}
.search-form .input-group-append{
  position: absolute;
  right: 10px;
  top: 9px;
}
.search-form .input-group-append .btn{
  background-color:#D8AC48;
  color:#ffffff;
}
.input-group>.form-control:focus{
  z-index:1;
  border-left:none;
  border:none;
  background-color: #f3f4f6;
}
.white-header .header{
  background-color:#ffffff;
  position:relative;
  border-bottom:none;
}
/* .white-header .header.navbar-fixed{
  position:fixed !important;
} */
.white-header .header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link{
  color: #1A0F31 !important;
}
.white-header .header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link:hover{
  color: white !important;
}
@media (max-width:992px){
  .white-header .header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link:hover{
      color: black !important;
      background-color: #d8ac48;
  }  
}
.white-header .header .header-contact-info .call-header span{
  color:#ffffff !important;
  display: block;
  margin-bottom: -30px;
  margin-left: 34px;
}
.white-header .header .header-contact-info .email-header{
  border: 2px solid #1A0F31;
}
.white-header .header-contact-info .email-header a {
  color: #1A0F31;
}


.navbar-fixed .main-menu .navbar .navbar-nav .nav-item .nav-link{
  color: #1A0F31 !important;
}
.navbar-fixed .header-contact-info .call-header span{
  color: #1A0F31 !important;
}  
.navbar-fixed .header-contact-info .email-header{
  border: 2px solid #1A0F31;
}
.navbar-fixed .header-contact-info .email-header a{
  color:#1A0F31;
}
.sticky-logo{
  display:none;
}
.navbar-fixed .sticky-logo{
  display:block;
}

.navbar-fixed .fixed-nav-logo{
  display:none;
}

/* Header sticky end */

/* Hero banner begin */
.hero-banner{
  background-color: #160d23;
  background-image: url('https://theboxlane.com/images/hero-banner-final.webp');
  background-size: cover;
  background-position: bottom center;
  height: 800px;
  display: flex;
  align-items: center;
}
.hero-banner-content{
  margin-top:120px;
}
.hero-banner-content ul{
  margin-bottom: 30px;
  column-count:2;
}
.hero-banner-content ul li{
  color:#ffffff;
  padding:0px 3px;
  font-size:15px;
}
.hero-banner-content ul li i{
  color:#D8AC48;
  padding:8px;
}
.hero-banner p{
  color: #BDBDBD;
  font-size: 18px;
  line-height: 32px;
}
.hero-banner-content img{
  display:none;
}
.hero-banner h1{
  font-size: 48px;
  color: #ffffff;
  font-weight: 700;
}
.hero-banner h1 span{
  font-weight: 300;
}
.quote-form-hero{
  width:415px;
  height: 600px !important;
  background-color:#ffffff;
  border-radius:20px;
  padding:20px;
  position:relative;
  margin-top:25px;
  box-shadow: 3px 7px 97px -5px rgba(231.63, 222.94, 222.94, 0.31); 
}
.quote-form-hero .form-control{
  height:46px;
  background-color:#F3F4F6;
  border:none;
}
.quote-form-hero label{
  padding-bottom: 5px;
}
.quote-form-hero textarea{
  height:initial !important;
}
.quote-form-hero .form-heading{
  text-align:center;
  border-bottom: 1px solid #E8E8E8;
  margin-bottom:15px;
}
.quote-form-hero .form-heading h2{
  font-size:18px;
  font-weight: 700;
}
.quote-form-hero .form-heading p{
  font-size: 14px;
  margin-bottom:5px;
  color: var(--Gray-3, #828282);
}

.quote-form-hero .form-check{
  padding-left:0;
}

.quote-form-hero .form-check-input:checked {
  border-color: #D8AC48;
  background-color: #D8AC48;
  color:#D8AC48;
  
}

.quote-form-hero .form-check-input:checked + .form-check-label::before {
  background-color: #d8ac48;
  border-color: #d8ac48;
  color: #d8ac48;
}

.quote-form-hero .form-top-element{
  position: absolute;
  top: -30px;
  left: -40px;
  display: none;
}
.quote-form-hero .form-bottom-element{
  position: absolute;
  bottom: -35px;
  right: -40px;
  display: none;
}
/* Hero banner end */

/* USP begin */
.usps-wrapper .usp-item{
  text-align:center;
  margin-top:20px;
}
.usps-wrapper .usp-item img{
  background: white;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.10);
  border-radius: 10px;
  border: 0.50px #F2F3F3 solid;
  padding: 15px;
  min-height: 140px;
  object-fit: contain;
}
.usps-wrapper .usp-item p{
  color: #1A0F31;
  font-size: 12px;
  font-weight: 600;
  padding-top: 10px;
}
/* USP end */
/* Product begin */
.product-wrapper .product-item h2{
  font-size: 30px;
  padding-top: 15px;
  font-weight: 600;
}
.product-wrapper .product-item:hover h2{
  color: #111705;;
}
.product-wrapper .product-item span{
  height: 2px;
  width: 50px;
  display: flex;
  background-color: #D8AC48;
  margin: 8px 0px 12px;
}
.bg-lines{
  position:relative;
  z-index:1;
}
.bg-lines::before{
  content:'';
  background-image:url('https://theboxlane.com/assets/images/grid-lines.webp');
 position: absolute;
  height: 558px;
  width: 482px;
  z-index: -1;
  top: -156px;
  background-size:contain;
  background-position: left;
  left:13px;
  
}
/* Product end */
/* product-content-wrapper begin */
.web-product-page .product-content-wrapper{
  z-index: 1;
  position: relative;
}
.web-product-page .product-content-wrapper::before{
  content: '';
  background-image: url(https://theboxlane.com/images/product-page-grid.png);
  position: absolute;
  height: 960px;
  width: 614px;
  z-index: -1;
  top: -80px;
  background-size: cover;
  background-position: left;
  left: 10px;
  background-repeat: no-repeat;
}
.web-product-page .carousel-inner{
  padding: 25px;
}
.web-product-page .product-title{
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #d8ac48;
}
.web-product-page .product-content p{
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  color:#ffffff;
}
.product-gallery .carousel-thumb {
  border: 1px solid #ccc; /* Change to your desired default border color */
  margin-bottom:10px;
  border-radius: 10px;
  width:100px;
}
.web-product-page .quote-form-hero .form-top-element{
  display: block;
}
.web-product-page .quote-form-hero .form-bottom-element{
  display: block;
}
/* Style the right arrow icon */
.product-gallery .arrow-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.product-gallery .left-ar{
  left:0;
}
.product-gallery .right-ar{
  right:0;
}
.product-gallery .arrow-icon i {
  font-size: 2rem;
  color: #000000;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255);
  line-height: 40px;
  text-align: center;
  border-radius: 25px;
}
.product-gallery .arrow-icon i:hover{
  background-color: rgba(255, 255, 255, 0.9);
}
.product-gallery .product-description-wrap h2{
  font-size: 40px;
  font-weight: 600;
}
.product-gallery .product-description-wrap h2 span{
  color:#D8AC48;
  font-size: 40px;
}
.product-gallery .product-description-wrap p{
  font-size: 15px;
  line-height: 24px;
}
#tuck-id .product-gallery .product-description-wrap{
  padding-left:50px ;
}
.product-gallery .product-description-wrap ul {
  /*display:inline-flex;*/
  margin-bottom: 1rem;
}
.product-gallery .product-description-wrap ul li{
  font-size: 15px;
  line-height: 24px;
}
.product-gallery .product-description-wrap ul li i{
  color: #D8AC48;
  padding: 0px 8px;
}
#gift-id .product-gallery .product-description-wrap{
  padding-left:50px ;
}
.product-gallery .carousel-control-prev{
  background-color: #d8ac48;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  top: 40%;
  display:none;
}
.product-gallery .carousel-control-next{
  background-color: #d8ac48;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  top: 40%;
  display:none;
}

.material-card-wrapper .material-card{
  border-radius: 15px;
  border: 0.5px #E1E2E4 solid;
  padding: 15px;
}
.material-card-wrapper .material-content{
  padding:20px 0px 10px;
  min-height: 170px;
}
.material-card-wrapper .material-content h3{
  font-size:18px;
  margin-bottom: 0;
  font-weight: 600;
}
.material-card-wrapper .material-content p{
  font-size:15px;
  line-height:25px;
}
.material-card-wrapper{
  padding-top: 1rem;
}
.material-card-wrapper h2{
  font-size:30px !important;
}
.sizes-tab{
  margin-top:30px;
}
.sizes-tab .sizes-conntent h3{
  font-size:24px;
  font-weight:600;
}
.sizes-tab .sizes-conntent p{
  font-size: 15px;
  line-height: 24px;
}
.sizes-tab .sizes-conntent ul{
  list-style: disc;
  margin-bottom: 16px;
  margin-left: 16px;
}
.sizes-tab .sizes-conntent ul li{
  font-size: 15px;
  line-height: 24px;
  color: #828282;
}
/* product-content-wrapper end */

/* CTA BANNER BEGIN */
.cta-wrapper{
  text-align: center;
  padding: 70px 0px;
  margin: 20px 0px 50px;
}
.cta-wrapper .cta-content-wrap h3{
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;
}
.cta-wrapper .cta-content-wrap p{
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 35px;
}

.web-product-page .product-thumbnail-wapper{
  display: flex;
  justify-content: center;
  gap: 20px
}
.web-product-page .product-thumbnail-wapper .img-thumbnail{
  opacity: 0.7;
}
.web-product-page .product-thumbnail-wapper .img-thumbnail.active-thumbnail{
  opacity: 1;
  border: 2px solid #d7ab48;
  border-radius: 9px;
}
.web-product-page .product-gallery{
  background-image: url('https://theboxlane.com/images/product-hero-bg.webp');
  background-color: #1a0f31;
  padding: 70px 0px 140px;
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
}
.web-product-page .product-gallery .product-content{
  padding-left: 15px;
}
/* CTA BANNER END */
/* product-usps-wrapper begin */
.product-usps-wrapper{
  margin-top: 30px;
}
.product-usps-wrapper .product-usps-list ul{
  column-count: 2;
}
.product-usps-wrapper .product-usps-list ul li{
  line-height: 30px;
  font-size: 12px;
}
.product-usps-wrapper .product-usps-list ul li svg{
	margin-right:5px;
}
.product-usps-wrapper .container{
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 4px 55px 0px rgba(0, 0, 0, 0.15);
  padding: 30px;
}
.reviews-brand-wrapper{
  position: relative;
  padding-left: 30px;
}
.reviews-brand-wrapper::before{
  content: '';
  position: absolute;
  width: 1px;
  height: 80px;
  background: #E0E0E0;
  left: -20px;
  top: -15px;
}
.reviews-brand-wrapper ul{
  gap: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reviews-brand-wrapper ul li a{
  color:#d7ab48;
  text-decoration: underline !important;
}
.printing-finishing-wrapper{
  padding: 40px 0px;
}
.printing-finishing-wrapper .heading-section h2{
  margin-bottom: 25px;
}
.printing-finishing-wrapper .printing-finishing-item{
  text-align: center;
  margin-top: 20px 0px 20px;
}
.printing-finishing-wrapper .printing-finishing-item img{
  border-radius: 10px;
  border: 1px solid #F2F3F3;
  background: var(--White-Color, #FFF);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.10);
  padding: 10px;
  margin-bottom: 20px;
}
.printing-finishing-wrapper .printing-finishing-item p{
  font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
color:#1A0F31;
}
/* product-usps-wrapper end */
/* product-tab-content-area-wrapper begin */
.product-tab-content-area-wrapper{
  padding: 40px 10px 10px;
}
.product-tab-content-area-wrapper h2{
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.product-tab-content-area-wrapper p{
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 160% */
}
.additional-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
}

.additional-content.visible {
  opacity: 1;
  max-height: 1000px; /* Set a high value to ensure full expansion */
}
.read-more-btn{
  border: none;
  background-color: transparent;
  color: #d7ab48;
}
/* product-tab-content-area-wrapper end */
/* who-its-work begin */
.who-its-work{
  position: relative;
  background: #FAF9FF;
  padding: 40px 0px 50px;
  margin-top:50px;
}

.bg-icon-element{
  background-image: url('https://theboxlane.com/images/usps/offer-line.webp');
  background-position: center center; /* Adjust as needed */
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: -25px;
}
.who-its-work .works-item{
  text-align: center;
}
.who-its-work .works-item img{
  width: 90px;
  height: 90px;
}
.who-its-work .works-item p{
  padding-top: 10px;
  font-weight: 600;
  color:#1A0F31;
  font-size: 16px;
}
.mobile-who-works{
  display:none;
}
/* who-its-work end */
/* process-wrapper begin */
.process-wrapper .heading-section{
  padding: 20px 0px 10px;
}
.process-wrapper{
  /* margin-bottom: 60px; */
  padding:30px 0px;
}
.process-wrapper .process-items .process-item-number{
  padding-bottom: 15px;
  position: relative;
}
.process-wrapper .process-items .process-item-number .yellow-point{
  height:10px;
  width:10px;
  background-color: #D8AC48;
  border-radius: 50%;
  position: absolute;
  bottom: 10px;
  z-index: 2;
  box-shadow: 0px 0px 3px 4px rgba(216,172,72,0.75);
  -webkit-box-shadow: 0px 0px 3px 4px rgba(216,172,72,0.75);
  -moz-box-shadow: 0px 0px 3px 4px rgba(216,172,72,0.75);
  left: 5px;
}
.process-wrapper .process-items .process-item-number p{
  color: #4A5568;
  font-size: 60px;
  font-weight: 600;
}
.process-wrapper .process-items .process-item-number p span{
  font-size: 30px;
}
.process-wrapper .process-items .process-item-content h4{
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
}
.process-wrapper .process-items .process-item-content p{
  font-size:15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color:#4A5568;
}
.process-bg-before{
  position: relative;
}
.process-bg-before::before {
  content: ""; /* This is required to make the pseudo-element appear */
  position: absolute; /* Position the pseudo-element */
  width: 15800px;
  height: 2px; /* Fill the parent element */
  z-index: 2; /* Place it behind the content */
  background-color: #dbdce0;
  margin-top:111px;
  margin-left: 15px;
}
.process-wrapper .heading-section p{
  color: var(--Sub-Text, rgba(26, 15, 49, 0.60));
  font-size: 18px;
}
.process-wrapper .process-item-content{
  padding-top:15px;
}
/* process-wrapper end */
/* product-content-wrapper begin */
.product-content-wrapper h2{
  font-size: 40px;
  font-weight: 600;
  margin-bottom:20px;
}
.product-content-wrapper h3{
  font-size: 18px;
  padding-bottom: 10px;
}
.product-content-wrapper p{
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
}
.product-content-wrapper .product-content-wrap{
  padding-left:30px;
}
/* product-content-wrapper end */
/* quote-banner-wrapper begin */
.quote-banner-wrapper .qoute-bg{
  background-image: url('https://theboxlane.com/images/get-in-touch.webp');
  background-size: cover;
  padding: 60px 40px;
  margin-bottom: -180px;
  z-index: 1;
  position: relative;
  border-radius:20px 20px;
}
.quote-banner-wrapper .quote-info{
  padding-bottom:20px;
}
.quote-banner-wrapper .quote-info span{
  color:#ffffff;
  font-size: 18px;
  padding-bottom: 10px;
  display: inline-block;
}
.quote-banner-wrapper .quote-info a{
  color:#D8AC48;
  font-size: 30px;
  font-weight: 300;
}
.quote-banner-wrapper .quote-info a i{
  transform: scaleX(-1);
}
.quote-banner-wrapper .quote-content h3{
  color:#ffffff;
  font-size: 60px;
}
.quote-banner-wrapper .quote-content p{
  color:#ffffff;
  font-size: 18px;
  padding-bottom: 10px
}
.quote-banner-wrapper .quote-img{
  margin-bottom: -75px;
}
.quote-banner-wrapper .quote-img img{
  width:450px;
}
.quote-banner-wrapper{
  background-color: #FFFAF0;
}
/* quote-banner-wrapper end */
/* artwork-content-wrapper begin */
.artwork-content-wrapper .artwork-content{
  display: flex;
  gap:20px;
  margin-bottom: 20px;
}
.artwork-content-wrapper .artwork-content img{
  width: 92px;
  height: 92px;
}
.artwork-content-wrapper .artwork-content h3{
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 160% */
  color:#1A0F31;
}
.artwork-content-wrapper .artwork-content p{
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color:#4A5568;
}
/* artwork-content-wrapper end */
/* product-content-banner-wrapper begin */
.product-content-banner-wrapper{
  margin: 40px 0px 70px;
}
.product-content-banner-wrapper .container{
  padding: 50px;
  border-radius: 15px;
  background: #F3F4F6;
}
.product-content-banner-wrapper .product-content-banner-wrap h2{
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px; /* 153.333% */
  color:#1A0F31;
}
.product-content-banner-wrap{
  padding-right:30px;
}
.product-content-banner-wrapper .product-content-banner-wrap p{
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 160% */
  color:#4A5568;

}
.product-content-banner-wrapper .product-content-banner-img{
  text-align: right;
}
/* product-content-banner-wrapper end */
/* product-related begin */
.style-related-categories .related-item-content p::before{
  content: '';
  position: absolute;
  width: 45px;
  height: 3px;
  background-color: #1A0F31;
  top: 28px;
  border-radius: 20px;
}

/* product-related end */
/* review-wrapper begin */
.review-wrapper{
  padding: 15px 0px;
  background: #F7F7F7;
}
.brand-line{
  width: 1px;
  height: 20px;
  background-color: #E0E0E0;
  display: inline-block;
  margin-bottom: -10px;
  margin-left: -4px;
  margin-right: 5px;
  margin-left: 5px;
}
.review-wrapper .review-text p{
  color: rgba(26, 15, 49, 0.60);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 2px;
  word-wrap: break-word;
  margin-bottom: 0;
  margin-top:10px;
}
.review-brand-wrapper{
  display:flex;
}
.review-wrapper .review-brand{
  display: flex;
  align-items: center;
  margin: 0px 25px;
}
.review-wrapper .review-brand span{
  color: #828282;
  font-weight:600;
}
.review-wrapper .review-brand img{
  height:40px;
}
.review-wrapper .review-brand .stars{
  height:15px;
}
.review-brand-wrapper .review-divider{
  width: 1px;
  height: 35px;
  opacity: 0.5;
  background: rgba(22, 3, 49, 0.60);
  margin-top: 5px;
}

/* review-wrapper end */


/* Sticky Social begin */
.sticky-social{
  position: fixed;
  right: 0;
  z-index: 2;
  top: 35%;   
}
.sticky-social ul li a{
  height: 40px;
  width: 40px;
  display: inline-block;
  line-height: 40px;
  text-align: center;
  margin-bottom: 3px;
  background: var(--dark-view-2, #3A3B3C);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.10);
}
.sticky-social ul li a:hover{
  background-color: #D8AC48;
}
.sticky-social ul li a i{
  color:#ffffff;
}
.sticky-whatsapp{
  position: fixed;
  left: 2%;
  z-index: 2;
  bottom: 12%;
}
.sticky-whatsapp img{
 width: 50px;
}

.banner-list-mobile{
  display:none;
}
.content-wrapper-two{
  padding: 80px 0px 50px;
}
/* Sticky Social end */
/* Form Popup begin */
#formPop .hero-banner-content{
      margin-top: 130px;
}
#formPop .hero-banner-content h3{
  color: var(--Main-Color, #1A0F31);
  font-size: 32px;
  font-weight: 600;
}
#formPop .quote-form-hero{
  box-shadow: 0px 4px 60px 3px rgba(0, 0, 0, 0.07);
}
#formPop .modal-header{
  border-bottom:none;
  position: relative;
  z-index: 2;
}
#formPop .modal-body{
  margin-top: -45px;
  position: relative;
  z-index: 1;
}
/* Form Popup end */

@media (min-width: 992px){
  .modal-lg {
      max-width: 1000px;
  }
}


/* supporting-page-content */

.supporting-page-content .content-wrapper h2{
  font-size: 18px;
  font-weight: 600;
  color:#1A0F31;
}
.supporting-page-content .content-wrapper p{
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color:#4A5568;
}
.supporting-page-content .content-wrapper ul{
  list-style:disc;
  margin-bottom: 16px;
  margin-left: 25px;
  color:#4A5568;
}
.supporting-page-content .content-wrapper ul li{
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color:#4A5568;
}
.supporting-page-content .content-wrapper{
  padding-right:50px;
}
.content-table .table-content-heading{
  display:flex;
}
.content-table .table-content-heading img{
  height: 20px;
  margin-left:10px;
}
.content-table .table-content-heading h3{
  font-size: 15px;
  font-weight: 600;
}
.content-table ul{
  padding: 0px 0px 20px 0px;  
  border:1px solid #E8ECF1;
}
.content-table ul li a{
  padding:10px 20px;
  display: block;
  
}

.content-table ul li a:hover{
  background-color:#d8ac48;
}
.content-table ul li:hover a{
  color:#ffffff;
}
.content-table ul li a{
  font-size:15px;
  color:#4A5568;
  font-weight:600;
}

/* content-wrapper-two begin */
.content-wrapper-two .content-wrap-content h2{
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 30px;
}
.content-wrapper-two .content-wrap-content p{
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 30px;
}

/* content-wrapper-two end */

/* unique-sp-wrapper begin */
.unique-sp-wrapper{
  padding:70px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.unique-sp-wrapper .heading-section p{
  width: 90%;
  color: #4A5568;
}
.unique-sp-wrapper .heading-section .sign-line{
  z-index: 4;
}
.unique-sp-wrapper .unique-sp-item{
  text-align: center;
}
.unique-sp-wrapper .unique-sp-item p{
  font-size: 15px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color:#1A0F31;
  padding-top: 15px;
}

/* unique-sp-wrapper end */
/* single-content-wrapper begin */
.single-content-wrapper{
  padding:30px 0px;
}
.single-content-wrap{
  text-align: center;
}
.single-content-wrap h2{
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
}
.single-content-wrap p{
  font-size: 18px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
}
.single-content-wrap .big-p{
  font-size: 30px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color:#1A0F31;
}
/* single-content-wrapper end */
/* box-style-wrapper begin */
.box-style-wrapper{
  background-color: #F5F5F5;
  padding: 60px 0px 100px;
  margin: 40px 0px;
}
.box-style-wrapper .explore-more-style{
  background-color: #D8AC48;
  height: 336px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 20px;
  color:#ffffff;
  border-radius: 10px;
  box-shadow: 10px 10px 50px 0px #FAF6EC;
}
.box-style-wrapper .explore-more-style .explore-more-heading{
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 170% */
  font-family: 'gilroy', sans-serif;
  margin:0;
}
.box-style-wrapper .explore-more-style p{
  color:#ffffff;
  padding-bottom: 10px;
}
.box-style-wrapper .style-item img{
  padding: 22px;
  background-color: #f5ecd8;
  height: 220px;
  object-fit: contain;
}
.box-style-wrapper .style-content-wrap{
  display: flex;
  justify-content: space-between;
  height: 100px;
  align-items: center;
  border-radius: 0px 0px 10px 10px;
  background: #faf5ec;
  backdrop-filter: blur(25px);
  padding: 0px 20px;
  position: relative;
}
.box-style-wrapper .style-content-wrap i{
  height: 42px;
  width: 42px;
  background-color: #1A0F31;
  line-height: 42px;
  text-align: center;
  color:#ffffff;
  border-radius: 25px;
  transform: rotate(315deg);
}
.box-style-wrapper .style-slider{
  background-color: #ffffff;
  box-shadow: 0px 4px 85px 0px #00000014;
  padding: 10px;
  border-radius: 10px;
}
.box-style-wrapper .style-slider .style-item p{
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
  color: #1A0F31;
  position: relative;
  line-height: normal;
  margin-top: 20px;
  font-family: 'gilroy', sans-serif;
} 
.box-style-wrapper .style-slider .style-item p::before{
  content:'';
  position: absolute;
  width: 45px;
  height: 3px;
  background-color: #1A0F31;
  top: -17px;
}
.box-style-wrapper .style-slider .owl-dots{
  position: absolute;
  align-items: center;
  justify-content: center;
  display: flex;
  left: 50%;
}
.box-style-wrapper .style-slider{
  position: relative;
}
.box-style-wrapper .owl-nav div {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  box-shadow: 0px 4px 55px 6px rgba(0, 0, 0, 0.10);
  font-size: 14px;
  color: #1a0f31;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  cursor: pointer;
}
.box-style-wrapper .owl-nav .owl-prev {
  left: -20px;
  top: 120px;
}
.box-style-wrapper .owl-nav .owl-next {
  right: -20px;
  top: 120px;
}

/* box-style-wrapper end */




/* Waqar Ahmad */

/* find packaging solution start */
.find-packaging-solution-wrapper{
  padding:60px 0px 40px;
}
.find-packaging-solution .pa-1{
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;   
  color:#1A0F31 !important ; 
  margin-bottom: 0px;
  font-family: 'gilroy', sans-serif;

}
.find-packaging-solution .pa-main{
  padding: 22px 0px;
}

.find-packaging-solution .img-div{
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;    
}

.homepage .find-packaging-solution img{
  background-color: transparent !important;
  height: auto;
}
.homepage .box-style-wrapper{
  margin:0 !important;
}
.find-packaging-solution img{
  border-radius: 15px !important;
  /* height: 300px; */
  object-fit: contain;
  background-color: #F2F2F2 !important;
}

.find-packaging-solution{
  box-shadow: 10px 10px 50px 0px #DDD;
  border-radius: 15px;
  margin-bottom: 20px;
  display: block;
  padding: 20px 20px 0px 20px;
}
/* find packaging solution start end */
/* category-content-wrapper begin */
.category-content-wrapper{
  padding:60px 0px;
}
.category-content-wrapper .category-content-left{
  border-radius: 5px;
border: 1px solid var(--Gray-5, #E0E0E0);
background: #FDFDFD;
padding: 30px;
}
.category-content-wrapper .category-content-left h2{
  font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 48px; /* 120% */
color:#1A0F31;
}
.category-content-wrapper .category-content-left p{
  color: var(--second-txt, var(--Sub-Text, #4A5568));
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
}
.category-content-wrapper .category-content-left ul{
  margin-bottom:30px;
}
.category-content-wrapper .category-content-left ul li{
  color: var(--second-txt, var(--Sub-Text, #4A5568));
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 144.444% */
  display: flex;
  padding-bottom: 10px;
}
.category-content-wrapper .category-content-left ul li svg{
  margin-right: 5px;
  margin-top: 5px;
}
.category-content-wrapper .category-content-right span{
  display: block;
  padding: 20px 0px 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.category-content-wrapper .category-content-right h2{
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 120% */
}
.category-content-wrapper .category-content-right p{
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  color:#4A5568;
}
.category-content-right img{
  width:100%;
  height: 400px;
}
/* category-content-wrapper end */
/* Eco Friendly start */
.eco-friendly-wrapper{
  background-color: #F7F7F7;
}

.eco-friendly-wrapper .sign-line{
  z-index: 2;
}

.eco-friendly-wrapper .heading-section{
  padding:20px 0px;
  text-align: center;
  position: relative;
}

.eco-friendly .pa-1{
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;   
  color:#1A0F31 !important ; 

}

.eco-friendly .img-div{
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
}

.eco-friendly .img-fluid{
  width: 90% !important;
  padding-top: 15px;
  border-radius: 15px !important;
}

.eco-friendly .pa-2{
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;    
  color: #4A5568 !important;
}

.eco-friendly{
  box-shadow: 0px 4px 6px 0px #0000001A;
  border-radius: 15px;
  margin-bottom: 30px;
}

.eco-friendly .pa-main{
  padding: 18px 18px 30px 18px;
}

/* Eco Friendly end */

/* Center Item start */

.center-item-wrapper{
  background-color: #F5ECD8;
  padding: 50px 0px;
}

.center-item .img-div{
  display: flex;
  justify-content: center;
}

.center-item .pa-1{
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;   
  color:#1A0F31 !important ; 
  padding-top: 15px;
}

.center-item .pa-2{
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;   
  color:#1A0F31 !important ; 
  padding-bottom: 50px;
}


/* Center Item end */

/* interested-in-categories start */

.interested-in-categories .heading-section{
  padding:20px 0px;
  text-align: center;
  position: relative;
}

.interested-in-categories .heading-section .sign-line{
  z-index: 2 !important;
}
.related-item-logo .related-item-content{
background-color: rgba(255, 255, 255, 0.8) !important;
/* border-bottom-left-radius: 15px !important; */
/* border-bottom-right-radius: 15px !important; */
width: 100% !important;
position: absolute;
bottom: -1%;
padding-top: 10px;
text-align: center;
}
.related-item-logo{
  position: relative;
}
/* interested-in-categories end */
/* box-reviews-wrapper begin */
.box-reviews-wrapper{
  padding:40px 0px;
}
.box-reviews-wrapper .related-item-logo .related-item-content{
  width: 90% !important;
}
.box-reviews-wrapper .related-item-logo .related-item-content h3{
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color:#1A0F31;
  margin-bottom:5px;
}
.box-reviews-wrapper .related-item-logo .related-item-content h3::after{
  content: '';
  position: absolute;
  width: 40px;
  height: 2px;
  background-color: #1A0F31;
  top: 30px;
  left: 10px;
}
.box-reviews-wrapper .related-item-logo .related-item-content p{
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color:#1A0F31;
}
.box-reviews-wrapper .related-item-logo{
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-reviews-wrapper .related-item-logo .related-item-content{
  text-align: left;
  bottom:5%;
  border-radius: 10px;
  padding: 10px;
}
.box-reviews-wrapper .owl-nav{
  display:none;
}
.box-reviews-wrapper .owl-dots{
  display:none;
}
/* box-reviews-wrapper end */
/* The Importance start */

.the-importance-div .head-1{
font-size: 30px;
font-weight: 700;
line-height: 32px;
letter-spacing: 0em;
text-align: left;
color: #1A0F31 !important;
padding-bottom: 10px;
}
.the-importance {
  background-color: #F8F8F8;
}
.the-importance .par-1{
font-size: 18px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
color: #4A5568;
padding-top: 15px;
}
.the-importance img{
  height: 100% !important;
}
@media (max-width:1340px){
  .the-importance img{
  height: 650px !important;
}
}
@media (max-width:1200px){
  .the-importance img{
  height: 710px !important;
}
}
@media (max-width:1070px){
  .the-importance img{
  height: 780px !important;
}
}
@media (max-width:968px){
  .the-importance img{
  height: 100% !important;
}
}

.owl-dots {
  text-align: center;
  margin-top: 20px;
}

.owl-dot {
  display: inline-block;
  width: 30px;
  height: 3px;
  margin: 0 5px;
  background: #E0E0E0;
  cursor: pointer;
}

.owl-dot.active {
  background: #D8AC48; /* Active dot color */
}
/* The Importance end */

/* Client Testimonial start */

.client .heading-section{
  padding:20px 0px;
  text-align: center;
  position: relative;
}
.client-item{
  padding: 40px 10px;
  border-radius: 25px;
  box-shadow: 0px 4px 6px 0px #0000001A;
  margin-bottom: 20px;
  min-height: 375px;
  border:1px solid #000000;
  margin-top: 30px;
  background-color: #ffffff;
}

.client-item-logo{
  display: flex;
  padding-left: 20px;
}
.client-item-logo .img-fluid{
  width: 32px !important;
  height: 32px !important;
}
.client-item-logo .pa-1{
font-family: Roboto;
font-size: 24px;
font-weight: 700;
line-height: 26px;
letter-spacing: 0em;
text-align: left;
color: #475569 !important;
padding-left: 10px;
}
.client-item-par .pa-2{
font-size: 16px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
color: #4A5568 !important;
padding-left: 20px;
}
.client-item-footer{
  display: flex;
  padding-left: 20px;
  padding-top:16px;
}
.client-item-footer .img-fluid{
  width: 64px !important;
  height: 64px !important;
  margin-right: 8px;
}
.client-item-footer .pa-3{
font-size: 16px;
font-weight: 600;
line-height: 29px;
letter-spacing: 0em;
text-align: left;
color: black !important;
padding-left: 10px;
margin-bottom: 0px;
}
.client-item-footer .pa-4{
font-size: 16px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
color:#475569 !important ;
padding-left: 10px;
}
.client-item-icon{
  display: flex;
  padding: 10px 20px 16px 20px;
}
.client-item-icon .img-fluid{
  width: 15.83px !important;
  height: 15.13px !important;
}
.client-wrapper .owl-stage-outer { 
overflow: visible !important;
}

.client-wrapper .owl-item {
 opacity: 0;
 transition: opacity 500ms;
}
.client-wrapper .owl-item.active {
opacity: 1;
}
/* Client Testimonial end */

/* Find Our Works start */
.find-par{
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
}
.find-our-work{
  background-color: #F5F5F5;
  border-bottom:3px solid #D8AC48;
  border-top: 1px solid #E1E2E4;
}
.find-our-work .heading-section a{
  text-decoration: underline !important;
}
.find-our-work .owl-dots{
  display: none;
}
.find-our-work .owl-nav{
  display: none;
}
/* Find Our Works end */


/* FAQ start */

.faq{
  background: #F5F5F5;
}
.card{
  box-shadow: none !important;
}
.faq .quote-form-hero{
  margin-top: 0;
  box-shadow: 0px 10px 15px -3px #0000001A;
}
.faq-content{
  padding: 10px 36px 30px 36px;
  border: 2px dotted rgba(26, 15, 49, 0.60) !important;
  border-radius: 25px;
}
.quote-form-hero select {
  height: 46px;
  background-color: #F3F4F6;
  border: none;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
/* FAQ end */
/* Testimonail Begin */
.client-wrapper .center .client-item{
  min-height:418px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: -25px;
  box-shadow: 10px 10px 50px 0px #DDD;
}
.client-wrapper{
  background-color: #Fdfbf7;
}
.client-wrapper .owl-nav{
  display: none;
}
.client-wrapper

.client-wrapper .owl-stage-outer { 
overflow: visible !important;
}

.client-wrapper .owl-item {
 opacity: 0;
 transition: opacity 500ms;
}
.client-wrapper .owl-item.active {
opacity: 1;
}
/* Testimonail end */

/* Homepage tabs begin */
.material-wrapper .nav{
  justify-content: space-around;
  border: 1px solid #CBD5E1;
  border-radius: 10px;
}
.material-wrapper .nav li{
  flex: 1;
  text-align: center;
  height:48px;
  line-height:48px;
}
.material-wrapper .nav-tabs .nav-link{
  color: var(--second-txt, #4A5568);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height:48px;
  line-height:33px;
}
.material-wrapper .nav-tabs .nav-link.active{
  color:#ffffff;
  background-color: #d7ab48;
  border-radius: 10px;
  margin-bottom: 0px;
}

/* homepage tabs end */

/* service-tab-wrapper begin */
.service-tab-wrapper{
  padding:50px 0px;
  background-color: #f9f9f9;
}
.service-tab-wrapper .nav::before{
  content: '';
  position: absolute;
  height: 360px;
  width: 1px;
  background-color: #cccccc;
  left: 35px;
  top: 15px;
}
.service-tab-wrapper .nav{
  display: block;
  border-bottom: none;
  position: relative;
}
.service-tab-wrapper .nav-tabs .nav-link.active{
  background-color: transparent;
  border:none;
  color: #d8ad4a;
}
.service-tab-wrapper .nav-tabs .active span{
  background-color: #D8AC48 !important;
  color:#ffffff !important;
}
.service-tab-wrapper .nav-tabs .nav-link{
  background-color: transparent;
  border:none;
  color: var(--Main-Color, #1A0F31);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}
.service-tab-wrapper .nav-tabs .nav-link span{
  margin-right: 20px;
  width: 42px;
  height:42px;
  line-height: 42px;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  color:#D8AC48;
  text-align: center;
  filter: drop-shadow(5px 5px 50px rgba(221, 221, 221, 0.80));
}
.service-tab-wrapper .nav-tabs .nav-item{
  padding-bottom: 30px;   
}
.service-tab-wrapper .service-content-area{
  background-color: #ffffff;
  padding: 60px 60px 20px;
  border: 20px solid #eeeeee;
  border-radius: 20px;
  box-shadow: -9px 2px 20px 0px #0000003B;
  margin-left: 20px;
}
.service-tab-wrapper .service-content-area ul{
  /*column-count: 2;*/
  /*display:flex;*/
}
.service-tab-wrapper .service-content-area ul li{
  padding: 0px 40px 30px;
}
.service-tab-wrapper .service-content-area ul li .service-content-heading{
  color: var(--secondry-Color, #D8AC48);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: 'gilroy', sans-serif;
  margin:0 0 8px 0;
}
.service-tab-wrapper .service-content-area ul li p{
  color: var(--second-txt, #4A5568);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */
}
/* service-tab-wrapper end */
/* Eco-Friendly Page begin */
.fsc-certified-wrapper{
  background: rgb(245,236,216);
  background: linear-gradient(90deg, rgba(245,236,216,1) 50%, rgba(255,255,255,1) 50%);
  padding: 50px 0px;
}
.fsc-certified-wrapper .row{
  display: flex;
  align-items: center;
  justify-content: center;
}
.fsc-certified-wrapper .fsc-certified-content h3{
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 33px */
  color:#1A0F31;
  padding-bottom: 15px;
}
.fsc-certified-wrapper .fsc-certified-content p{
  color: #4A5568;
  font-family: 'inter', sans-serif;
  text-align: justify;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 200% */
}
.fsc-certified-wrapper .fsc-certified-content{
  padding: 50px 140px 50px 0px;
  position: relative;
}
.fsc-certified-wrapper .fsc-certified-content::after{
  display: block;
  content: "";
  position: absolute;
  top: 40%;
  width: 50px;
  height: 50px;
  right: -60px;
  background: #f5ecd8;
  border-width: 0 0 1px 1px;
  -webkit-transform: translateX(-50%) rotate(-45deg);
  -moz-transform: translateX(-50%) rotate(-45deg);
  -ms-transform: translateX(-50%) rotate(-45deg);
  -o-transform: translateX(-50%) rotate(-45deg);
  transform: translateX(-50%) rotate(-45deg);
  -webkit-transition-delay: 0.07s;
  -moz-transition-delay: 0.07s;
  -o-transition-delay: 0.07s;
  transition-delay: 0.07s;
}
.fsc-certified-img{
  text-align: center;
}
.fsc-certified-img img{
  width: 400px;
}
/* Eco-Friendly Page end */
/* Faqs page begin */
.faqs-page-wrapper{
  padding: 70px 0px 40px;
}
.faqs-page .faq {
  padding: 40px 0 40px;
}
.faqs-page .heading-faqs h2{
  color:  #D8AC48;
  font-family: 'Inter', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px; /* 159.091% */
}
.faqs-page  #myBtnContainer{
  display: flex;
  justify-content: space-around;
  border: 1px solid #CBD5E1;
  border-radius: 10px;
}
.faqs-page #myBtnContainer .btn{
  flex: 1;
  text-align: center;
  border-right: 1px solid #CBD5E1;
  border-left: 1px solid #CBD5E1;
  height:48px;
}
.faqs-page #myBtnContainer .btn.active{
  background-color: #D8AC48;
  color: #ffffff;
}
/* Faqs page end */
.view-all-btn{
  padding: 40px 0px 30px;
}
.faqs-banner{
  background-image: url('https://theboxlane.com/images/faqs-banner.webp');
  background-position: center;
  background-size: cover;
  padding: 75px 0px 20px;
}
.faqs-banner .faqs-banner-content{
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.faqs-banner .faqs-banner-content span{
  display: inline-block;
  color:#1A0F31;
  font-family: 'gilroy', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 39.6px */
  text-transform: uppercase;
  margin-bottom: 10px;
}
.faqs-banner .faqs-banner-content .sign-line::before{
  top: 27px;
}
.faqs-banner .faqs-banner-content h1{
  color:#1A0F31;
  font-family: 'gilroy', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.6%; /* 49.44px */
  letter-spacing: 2px;
  text-transform: uppercase;
}
.faqs-banner .faqs-banner-content h1 span{
  color:#D8AC48;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}
.faqs-banner .faqs-banner-content p{
  color: #4A5568;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.faqs-banner .faqs-banner-content img{
  padding-top: 20px;
}
.banner-input{
  max-width: 730px;
  height:63px;
  position: relative;
  margin-top: 30px;
}
.banner-input input{
  border-radius: 10px !important;
  border: 1px solid #E1E2E4;
  background: #F7F7F7;
  padding-left: 50px;
}
.banner-input .input-group-text{
  background: transparent;
  border: none;   
  position: absolute;
  z-index: 2;
  top: 8px;
}
.banner-input .input-group-text i{
  color: #CBD5E1;
}
.banner-input .input-group-append{
  position: absolute;
  right: 6px;
  top: 7px;
  background-color: #D8AC48;
  color: #ffffff;
  font-weight: 700;
  font-size: 18px;
  border-radius: 5px;
  padding: 6px 30px;
}
.banner-input .input-group-append .btn{
  color:#ffffff;  
}
/* Responsive begin */
.mobile-show{
  display:none;
}
/* Custom Css end */
/* Main Css begin */
/* carve-div begin */
.carve-div{
  clip-path: polygon(0 100%, 100% 100%, 100% 84%);
}

.sign-line{
  position: relative;
}

.sign-line::before{
  content:'';
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('https://theboxlane.com/assets/images/text-sign.svg');
  bottom: 0;
  left: 0;
  right: 0;
  top: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}
.z-index-2{
  z-index: 4;
}

.carve-div-white{
  background-color:#ffffff;
}

/* carve-div end */

/* Page Banner begin */
.general-banner{
  background-image: url(https://theboxlane.com/assets/images/general-banner.png) !important;
  background-size: cover;
  background-repeat: no-repeat;
}

.page-banner{
  background-color: #F1F5F9;
  padding: 30px 0px;
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.page-banner .page-banner-content h1{
  font-size: 40px;
  font-weight: 800;
  font-style: normal;
  line-height: 52px;
}
.page-banner .page-banner-content p{
  color: #4A5568;
  padding: 10px 0px;
  font-family: 'gilroy', sans-serif;
}
.page-banner .sign-line::before{
  top: 42px;
}
.page-banner .page-banner-img{
  text-align:center;
}
/* Page Banner end */

/* Social banner */
.social-banner{
  background-color: #F1F5F9;
  padding: 40px 0px 70px;
}
.social-banner .heading-titles{
  text-align: center;
  padding-bottom: 40px;
}
.social-banner .heading-titles h2{
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
}
.social-banner .heading-titles p{
  color: #4A5568;
  font-size: 18px;
}
.social-banner .social-item{
  font-size: 18px;
  color: #1A0F31;
  font-weight: 500;
}
.social-banner .social-item i{
  padding-right: 10px;
}
/* Social banner */

/* overlay banner begin */
.overlay-text-wrapper .overlay-text-card{
  padding: 48px;
  width: 610px;
  border:1px solid #eeeeee;
  border-radius: 10px;
  background-color: #ffffff;
  position: relative;
  z-index: 2;
  margin-left: -90px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.50);
-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.50);
-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.50);
}
.overlay-text-wrapper .overlay-text-card h2{
  color: #0F172A;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
}
/* overlay banner end */

/* how-works-wrapper begin */
.how-works-wrapper .work-cards{
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10);
  padding: 20px;
  margin-bottom:30px;
}
.how-works-wrapper .work-cards .work-cards-upper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.how-works-wrapper .work-cards .work-cards-upper img{
  width: 115px;
  height: 115px;
}
.how-works-wrapper .work-cards .work-cards-upper span{
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  color: #EFEFEF;
}
.how-works-wrapper .work-cards .work-cards-lower h3{
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 37.5px;
  padding-bottom: 20px;
}



/* how-works-wrapper end */

/* brand-logos-wrapper */
.brand-logos-wrapper{
  background-color: #1A0F31;
  padding: 40px 0px;
  border-radius: 0px 0px 0px 100px;
}
.brand-logos-wrapper .brand-item-logo{
  display: flex;
  align-items: center;
  justify-content: center;
}
.brand-logos-wrapper .owl-dots{
  display: none;
}
/*.brand-logos-wrapper .heading-section h2{*/
/*    color: #ffffff;*/
/*}*/
.brand-logos-wrapper .heading-section .brand-logos-heading{
  color: #ffffff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding-bottom: 15px;
  font-family: 'gilroy', sans-serif;
  margin:0;
}
.brand-logos-wrapper .heading-section p{
  color: #ffffff;
}
/* brand-logos-wrapper */
/* quote-form-wrapper begin */
.quote-form-wrapper{
  background-image: url('https://theboxlane.com/assets/web-images/getaquote/get-free-quote-banner.webp');
  background-repeat: no-repeat;
  padding: 40px 0px;
  background-size: cover;
}
.quote-form-wrapper .quote-form-content h1{
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color:#1A0F31;
}
.contact-page .quote-form-wrapper .quote-form-content p{
  font-size: 18px;
  font-weight: 400;
  line-height: 32px; /* 190.909% */
}
.contact-page .quote-form-wrapper .quote-form-content .big-text{
  font-weight: 700;
}
.quote-form-wrapper .quote-form-content p{
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
  color:#4A5568;
}
.quote-form-wrapper .quote-form-content .big-text{
  font-family: 'Inter',sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px; /* 190.909% */
}
.quote-form-wrapper .quote-form-content .big-text a{
  color:#D8AC48;
}
.quote-form-wrapper .quote-form-content span{
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 39.6px */
  text-transform: uppercase;
  color:#1A0F31;
}
.quote-form-wrapper .quote-form-content span::before{
  top: 15px;
}
.quote-form-wrapper .product-usps-list ul {
  column-count: 2;
  border-top: 1px solid #CBD5E1;
  padding-top: 25px;
}
.quote-form-wrapper .product-usps-list ul li {
  line-height: 30px;
}
.quote-page-content-heading{
  text-align: left;
  padding-bottom: 50px;
}
.quote-page-content-heading h2{
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 48px */
  color:#1A0F31;
  padding-bottom: 10px;
}
.quote-page-content-heading p{
  color:#4A5568;
}
.quote-page-content-item{
  padding-bottom: 20px;
}
.quote-page-content-img{
  position: absolute;
  right: 0;
  top: 0;
}
.quote-page-content-img img{
  height: 950px;
}
.quote-page-content-item h4{
  color: #1A0F31;
  font-family: 'Inter' ,sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 24.2px */
}
.quote-page-content-item p{
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
  color: #4A5568;
}
.quote-page-content-item span{
  width: 60px;
  height: 4px;
  background-color: #D8AC48;
  border-radius: 10px;
  margin: 10px 0px;
  display: inline-block;
}
.quote-page .process-bg-before{
  margin-top: 20px;
}
/* quote-form-wrapper end */
/* Contact us page begin */
.contact-page-info-wrapper{
  background-image: url('https://theboxlane.com/assets/images/contact-us/contact-content-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 70px 0px;
  position: relative; 
  background-attachment: fixed;
}
.contact-page-info-content{
  position: relative;
  padding: 0px 50px 0px 0px;
}
.contact-page-info-wrapper::before{
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: rgba(255,255,255, 0.8);
  top: 0;
  bottom: 0;
  left: 0;
}
.contact-page-info-content span{
  color: #1A0F31;
  font-family: 'Inter', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2px;
}
.contact-page-info-content h2{
  color: #1A0F31;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.contact-page-info-content p{
  color:#1A0F31;
font-family: 'Inter', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 26px; /* 144.444% */
margin-bottom: 40px;
}
.contact-page-info-content ul li{
  display: flex;
  padding-bottom: 30px;
}
.contact-page-info-content ul li svg{
  margin-right:15px;
}
.contact-page-info-content ul li p{
  margin-bottom: 0;
  color: var(--Main-Text, #1A0F31);
  font-family: 'Inter', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 118.182% */
}
.contact-page-info-content ul li p span{
  color: #4A5568;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-top: 10px;
  display: inline-block;
}
.contact-page-info-content ul li p a{
  color: #4A5568;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-top: 10px;
  display: inline-block;
}
.contact-banner-img{
  margin-top: -100px;
}
/* Contact us page end */
/* instagram-post-widget */
.instagram-post-widget .eapps-instagram-feed-header{
  display: none;
}
.instagram-post-widget .eapps-instagram-feed a:last-child{
  display: none;
}


/* instagram-post-widget */

/* Blog widget begin */
.blog-wrapper{
  padding:40px 0px;
}
.blog-wrapper .sign-line::before{
  top: 40px !important;
}
.blog-wrapper .owl-nav .owl-next{
  top: 235px;
}
.blog-wrapper .owl-nav .owl-prev{
  top: 235px;
}
.blog-wrapper .blog-item{
  position: relative;
  margin-top: 30px;
  border-radius: 10px;
  background: var(--Whit, #FFF);
  box-shadow: 0px 10px 20px 0px #DDD;
  margin-bottom: 15px;
}
.blog-wrapper .blog-item .blog-content{
  bottom: 0px;
  padding: 45px 15px 30px;
  color: #1A0F31;
  display: block;
  background-color: #ffffff;
  position: relative;
  border-radius: 0px 0px 10px 10px;
}
.blog-wrapper .blog-item .blog-content .blog-date{
  position: absolute;
  height: 75px;
  width: 75px;
  border-radius: 50%;
  text-align: center;
  line-height: 15px;
  background-color: #1a0f31;
  margin-bottom: 0;
  color: #ffffff;
  padding: 10px;
  top: -40px;
}
.blog-wrapper .blog-item .blog-content .blog-date p{
  margin-bottom: 0px;
  color: var(--White, #FFF);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;   
  line-height: 32px;
}
.blog-wrapper .blog-item .blog-content .blog-date span{
  color: #FFF;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}
.blog-wrapper .blog-item .blog-content h3{
  color: var(--Main-Color, #1A0F31);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 136.364% */
}
.blog-wrapper .blog-item .blog-content a{
  color: #D8AC48;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}
.blog-wrapper .owl-stage-outer { 
  overflow: visible !important;
}
.blog-wrapper .owl-item {
 opacity: 0;
}
.blog-wrapper .owl-item.active {
opacity: 1;
}
/* Blog widget end */


/* box-by-style-wrapper begin */
.box-by-style-wrapper{
  padding:80px 0px;
}
.box-by-style-wrapper .box-by-style{
  margin-bottom:30px;
}
.box-by-style-wrapper .box-by-style p{
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin:10px 0px;
}

.style-related-categories{
  position: relative;
  padding: 50px 0px 50px;
}
.style-related-categories .owl-nav{
  display: none;
}
.style-related-categories .related-item-logo{
  /* height: 290px; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.style-related-categories .related-item-logo img{
  height: 100%;
  object-fit: cover;
}
.style-related-categories .related-item-content{
  height: 130px;
  width: 90% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.50);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  margin: 20px 0px;
}
.style-related-categories .related-item-content p{
  color: #1A0F31;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
  text-align: left;
  flex: 3;
  margin-top: 20px;
  font-family: 'gilroy', sans-serif ;
}
.style-related-categories .related-item-content i{
  height: 38px;
  width: 38px;
  background-color: #1A0F31;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
  border-radius: 25px;
  transform: rotate(315deg);
}

.gallery-wrapper{
  padding:60px 0px 80px;
}
.gallery-wrapper .gallery-item{
  padding: 12px 0px;
}
/* box-by-style-wrapper end */
/* product-page begin */
.product-page .product-content-wrapper .tab-navigation .nav-tabs{
  width: 497px;
}

/* product-page end */
/* Footer begin */
.cta-wrapper-footer .cta-banner-content .sub-heading{
  color:#D8AC48;
  font-size: 40px;
  font-weight: 800;
  line-height: 33px;
  margin-bottom: 10px;
}
.cta-wrapper-footer h3{
  font-size: 40px;
  font-weight: 800;
  line-height: 50px;
  margin-bottom: 20px;
  color:#1A0F31;
}
.cta-wrapper-footer .cta-banner-content p{
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #4A5568;
  margin-bottom:40px;
  font-family: 'gilroy', sans-serif;
}
.cta-wrapper-footer .cta-banner-content a{
  padding: 1rem 2.5rem !important;
}
.cta-wrapper-footer{
  padding: 60px;
  text-align: center;
  background-color: #F5ECD8;
  color: #ffffff;
}


footer{
  background-color: #1A0F31;
  padding: 50px 0px;
}
.footer-col-1 img{
  width: 100%;
  margin-bottom: 20px;
}
.footer-col-1 p{
  font-size: 15px;
  line-height: 28px;
  color:#ffffff;
  text-align: justify;
}
.footer-col-1 span{
  color:#ffffff;
  font-size: 16px;
}
.footer-col-1 ul{
  display:inline-flex;
  padding: 10px 0px;
}
.footer-col-1 ul li{
  padding: 0px 10px;
}
.footer-col-1 ul li:first-child{
  padding-left: 0px;
}
.footer-col-2, .footer-col-3, .footer-col-4{
  margin-top: 15px;
  padding-left: 40px;
}
.footer-title{
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 25px;
  position: relative;
  display:inline-block;
}

.footer-title::before{
  content: '';
  position: absolute;
  width: 70px;
  height: 10px;
  background-image: url(https://theboxlane.com/assets/images/footer/border-line.svg);
  bottom: 0;
  left: 0;
  right: 0;
  top: 22px;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
}
.footer-col-4 img{
  margin:10px 0px 0px 0px;
}
.footer-col-4 p{
  margin-top: 20px;
  margin-bottom: 0;
  color:#ffffff;
  font-size:15px;
}
.footer-col-4 i{
  color:#d8ac48;
  margin-right: 10px;
}
.footer-list li{
  color: #ffffff;
  font-size: 15px;
  padding: 5px 0px;
  line-height: 28px;
}
.footer-list li a{
  color: #B9B3C1;
}
.footer-list li a svg{
  margin-left:10px;
}
.copyright-footer{
  display: flex;
  justify-content: space-between;
}
.copyright-footer p{
  margin-bottom: 0px;
  color:#ffffff;
  font-size:15px;
}
.copyright-footer .footer-social{
  display: inline-flex;
}
.copyright-footer .footer-social li{
  padding: 0px 10px;
}
.copyright-footer .footer-social li i{
  color: #ffffff;
  font-size: 18px;
}
.copyright-footer .footer-social li a:hover i{
  color:#d8ac48;
}
.copyright-footer-wrapper{
  background-color: #1A0F31;
  padding: 15px 0px;
  border-top: 1px solid #3d2536;
}
/* Footer end */
/* Homepage begin */
.homepage .brand-logos-wrapper{
  padding: 40px 0px 40px;
}
.brand-logos-wrapper img{
  /* height: 40px; */
  width: 100px !important;
  object-fit: contain;
}
.brand-logos-wrapper .owl-nav{
  display: none;
}
.hero-banner-wrapper{
  padding: 70px 0px 190px;
  background-image: url(https://theboxlane.com/assets/images/homepage/hero-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
}
.hero-banner-wrapper .hero-content span{
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.brand-logo-canve{
  margin-bottom: -160px;
}
.hero-banner-wrapper .hero-content h1{
  color: #FFF;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 55px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.hero-banner-wrapper .hero-content h2{
  color: #1A0F31;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 2px;
}
.hero-banner-wrapper .hero-content .sub-heading{
  color: #1A0F31;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 2px;
  font-family: 'gilroy', sans-serif;
  margin: 0;
}
.hero-banner-wrapper .hero-content ul{
  margin: 0px 0px 30px;
}
.hero-banner-wrapper .hero-content ul li{
  line-height: 24px;
  font-family:'gilroy' , sans-serif;
}
.hero-banner-wrapper .hero-content p{
  color:#1A0F31;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin: 18px 0px 8px 0px;
}
.hero-img{
  position: relative;
}
.hero-img img{
  position: absolute;
  top: 33px;
}
.canve-div-one{
  margin-top: -153px;
  text-align: center;
  position: relative;
}
.canve-div-one ul{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -65px;
}
.canve-div-one ul li{
  padding: 0px 15px;
}
.material-wrapper{
  padding: 40px 0px 50px;
  background-color: #ffffff;
}
.material-wrapper .home-material-card{
  border-radius: 15px;
  border: 1px solid #E1E2E4;
  background: var(--White-Color, #FFF);
   box-shadow: 10px 10px 50px 0px #DDD; 
}
.material-wrapper .home-material-card img{
  height: 250px;
  object-fit: cover;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}
.material-wrapper .home-material-card h4{
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #1A0F31;
}
.material-wrapper .home-material-card span{
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #1A0F31;
  font-family: 'gilroy', sans-serif;
  margin-bottom:8px;
  display:inline-block;
}
.material-wrapper .home-material-card p{
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #1A0F3199;
  font-family: 'Inter', sans-serif;
}
.material-wrapper .home-material-content{
  padding: 25px 15px 0px 15px;
  min-height: 190px;
}
.material-wrapper .material-heading h3{
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #4A5568;
  margin: 15px 0px;
}
.material-wrapper .owl-nav .owl-prev{
  left: -40px;
  top: 200px;
}
.material-wrapper .owl-nav .owl-next{
  right: -40px;
  top: 200px;
}


.mailerbox-home-wrapper{
  background-image: url(https://theboxlane.com/assets/images/homepage/product-slider/product-bg.jpg);
  background-size: cover;
  /* padding: 60px 0px; */
  position: relative;
  z-index: 1;
  /* height: 500px; */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}
.mailerbox-home-wrapper .product-ecommec{
  height: 100vh;
  overflow: scroll;
  overflow-x: hidden;
  z-index: 1;
  position: relative;
  /* margin-top: 44px; */
}
.mailerbox-home-wrapper .product-ecommec::-webkit-scrollbar {
  width: 0px;
}
.mailerbox-home-wrapper .product-ecommec::-webkit-scrollbar-track {
  background: #f7eef1; 
}
.mailerbox-home-wrapper .product-ecommec::-webkit-scrollbar-thumb {
  background: #f7eef1; 
  border-radius:10px;
}
.mailerbox-home-wrapper .product-ecommec::-webkit-scrollbar-thumb:hover {    
  background: #f7eef1; 
} 
.canve-png-img{
  background: rgb(254,238,225);
  background: linear-gradient(90deg, rgba(254,238,225,1) 43%, rgba(246,237,242,1) 57%);
  height: 150px;
  margin-top: -150px;
  position: relative;
  z-index: -1;
}

.mailerbox-home-wrapper .product-slider-img{
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}
/* .mailerbox-home-wrapper .product-slider-img .grid-bg-wrap{
  width: 100% !important;
  position: absolute;
  height: 500px;
  object-fit: cover;
  z-index: -1;
} */

.mailerbox-home-wrapper .product-slider-img img{
  /* width: 100% !important; */
  object-fit: contain;
  margin-bottom: -50px;
  max-height: 470px;
}
.mailerbox-home-wrapper .product-slider-img h5{
  color: #1A0F31;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.mailerbox-home-wrapper .product-slider-img p{
  color: #4A5568;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 156.25% */
}
.mailerbox-home-wrapper .product-slider-img::before{
  content: '';
  background-image: url(https://theboxlane.com/assets/images/homepage/product-slider/grid.webp);
  position: absolute;
  height: 100vh;
  width: 560px;
  z-index: -1;
  top: -0px;
  background-size: cover;
  background-position: left;
  /* left: 89px; */
  background-repeat: no-repeat;
}
/* .mailerbox-home-wrapper .row{
  height:100vh;
} */
.mailerbox-home-wrapper .mailerbox-home-content p{
  margin-right: 40px;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color:#1A0F31;
  padding-bottom:32px;
}
.mailerbox-home-wrapper .mailerbox-home-content h2{
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  color:#0F172A;
  margin-bottom:0;
  padding-bottom:32px;
}
.product-sticker{
  position: absolute;
  height: 130px;
  width: 130px !important;
  right: 55px !important;
  top: 8%;
  background-color: #f0e9f0;
  border-radius: 50%;
  z-index: 9;
}
.product-slider-content{
  position: absolute;
  bottom: 7%;
}
/* Homepage end */
/* Product Page begin */
.material-wrapper .owl-stage-outer { 
overflow: visible !important;
}

.material-wrapper .owl-item {
 opacity: 0;
 transition: opacity 500ms;
}
.material-wrapper .owl-item.active {
opacity: 1;
}


/* Product Page end */




/* Main Css end */
