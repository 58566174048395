.col.shadow.rounded {
    border-radius: 20px !important;
}
.homeicon{
    border-radius: 50% !important;
    padding: 1px 6px;
    height: 25px;
    width: 25px;
    display: inline-block;
    text-align: center !important;
    line-height: 21px;
    margin-right:5px;
    padding: 0;
    background-color: #635675;
}
.form-select{
    height: 46px !important;
    background-color: #F3F4F6;
    border: none;
    color: #6c757d;
}
.form-control{
    height: 46px !important;
    background-color: #F3F4F6;
    border: none;
}

/* Product Css begin */
.product-gallery .model-container canvas{
    width:100% !important;
    height:490px !important;
}
.open-close-toggle {
    position: relative;
    left: 14px !important;
    top: 30px;
    width: 210px;
    z-index: 99999;
    background-color: #f5ecd8;
    border: 1px solid #d8ac48;
}
.product-form-gallery{
    padding: 40px 0px 40px;
    background-color: #fbfaf5;
}
.product-form-gallery .form-container .form-control{
    height: 46px !important;
    background-color: #F3F4F6;
    border: none;
    color: #4A5568;
    font-weight: 600;
    font-family:  'Inter', sans-serif;
}
.form-control:focus{
  background-color: #F3F4F6;
}
.form-control{
    height: 46px !important;
    background-color: #F3F4F6;
    border: none;
    color: #4A5568;
    font-weight: 600;
    font-family:  'Inter', sans-serif;
}
.form-select{
    height: 46px !important;
    background-color: #F3F4F6;
    border: none;
    color: #4A5568;
    font-weight: 600;
    font-family:  'Inter', sans-serif;
}
.product-form-gallery .form-container .form-select{
    height: 46px !important;
    background-color: #F3F4F6;
    border: none;
    color: #4A5568;
    font-weight: 600;
    font-family:  'Inter', sans-serif;
}
.product-form-gallery .form-container textarea{
    height: 46px !important;
    background-color: #F3F4F6;
    border: none;
}

.product-form-gallery .form-container .form-check-input:checked{
    background-color: #d8ac48;
    border-color: #d8ac48;
}
.product-details{
    margin:0px 15px;
    position: relative;
}
.product-details .open-close-toggle{
    left: 33% !important;
}
.product-details .threed-toggle-btn{
    position: absolute;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    bottom: 0;
    left:0;
    background-color: #f5ecd8;
    padding: 10px;
    border: 1px solid #d8ac48;
    border-radius: 25px;
}
.product-details .threed-toggle-btn input:checked + .slider{
    background-color: #6f6073;
}
.product-details .threed-toggle-btn .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 22px;
  }
.product-details .product-content{
    padding-bottom:15px;
  }
  .product-details .product-content p{
    margin-bottom: 0;
  }
  .product-details .product-gallery{
    height:600px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.product-details .product-gallery img{
    padding:20px;
    object-fit: contain;
    width: 500px;
}
.product-details .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    right: 3px;
    bottom: 2px;
    background-color: #1A0F31;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
.product-details .preview-btn{
    font-weight: bold;
    margin-left:10px;
}
.product-content h1{
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-family: 'gilroy', sans-serif;
}
.open-close-toggle .active{
    background: transparent linear-gradient(180deg, #d8ac48 0%, #f3af14 100%) 0% 0% no-repeat padding-box;
}
.product-details .product-content .homeicon{
    display: inline-block;
    line-height: 20px;
    width: 22px;
    height:22px;
    padding: 0;
}
.price-panel{
    margin-top: 15px;
    padding: 15px 15px 0px 15px;
}
.price-panel .note{
   margin-bottom:20px;
}
.price-panel .note p{
    background-color: #DDDCDC;
    font-size: 12px;
}
.price-panel .note p span{
    background-color: #CD3535;
    color: #ffffff;
    padding: 2px 4px;
    border-radius: 5px;
    font-size: 12px;
    margin-right: 5px;
}
.turnaround-panel {
    padding: 20px 10px;
    border-radius: 10px;
    border: 2px solid #d8ac48;
    background-color: #F5ECD84D;
}
.turnaround-panel .turnaround{
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 10px;
    color: #1A0F31;
}
.turnaround-panel .designsupport{
    font-size: 12px;
    color: #1A0F31;
}
.turnaround-panel hr{
    margin: .5rem;
}
.turnaround-panel label{
    font-size:12px;
    color: #828282;
}
.price-panel .matchtheprice button{
    background-color: #ffffff;
    margin-top: 10px;
    border: 1px solid #D8AC48;
    color: #D8AC48;
}
.price-panel .unitprice p{
    font-size: 24px;
    color: #D8AC48;
    font-weight: 700;
    margin-bottom: 0;
}
.price-panel .subtotal p{
    font-size: 20px;
    color: #4A5568;
    font-weight: 700;
    margin-bottom: 0;
}
.price-panel .subtotal span{
    font-size: 20px;
    color: #4A5568;
    font-weight: 700;
}
.paypal-modal .col {
    padding-right: 15px;
    padding-left: 15px;
}
.paypal-modal .row{
    padding:15px;
}
.product-spec-wrapper li {
  display: flex;
  /* grid-template-columns: 1fr 1fr; */
  margin-bottom: 8px;
  gap: 20px;
  justify-content: space-between;
  border-bottom: 1px solid #d8ac48;
  padding: 10px 0px;
  align-items: center;
}
.product-spec-wrapper li .spec-name{
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  color: #1A0F31; 
}
.product-spec-wrapper li .spec-value{
  line-height: 18px;
  font-size: 12px;
  font-weight: 700;
  color: #1A0F31; 
}

/* Product Css end */

@media (max-width: 992px){
    .price-panel{
        margin-left:0px;
        margin-top:15px;
    }
    .process-icons{
        margin-top:20px;
    }
    .product-form-gallery {
        padding: 30px 0px 0px;
    }
}
@media (max-width: 767px){
    .product-details .product-gallery{
        height: initial;
    }
    .product-details .open-close-toggle {
        left: 0% !important;
    }
    .open-close-toggle{
        top: -70px;
        width:100% !important;
    }
    .product-details .threed-toggle-btn{
        bottom: 0%;
    }
    .image-holder-wrapper{
      display: none;
    }
}
@media (max-width: 567px){
    .product-gallery .model-container canvas {
        width: 360px !important;
        height: 350px !important;
    }
}




.app {
    display: flex;
  }
  
  .sidebar {
    width: 50px;
    padding: 20px;
    margin-bottom: 165px;
  }
  
  .color-circle {
    width: 20px;
    height: 20px;
    padding: 2px;
    border-radius: 50%;
    margin-bottom: 10px;
    cursor: pointer;
    border: 2px solid transparent; /* Initial border color */
  }

  .color-circle.active {
    border: 2px solid #000000; /* Initial border color */
    width: 23px;
    height: 23px;
    
  }
  
  .main-content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form-header{
    background-color: #635675;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 20px 20px 0px 0px;
  }

  .form-header span{
    font-size: 22px;
    font-weight: 700;
    color: #ffffff;
    font-family: 'Inter', sans-serif;
  }

  .form-wrapper{
    /* box-shadow: 0px 4px 60px 3px #00000012; */
    border-radius: 20px;
    background-color: #ffffff;
  }
  .form-wrapper .form-fields label{
    color:#828282;
    font-size: 12px;
  }
  label{
    color:#828282;
    font-size: 12px;
  }

  .shipping-check{
    display: flex;
    justify-content: space-between;
  }
  .design-s{
    color:#1A0F31;
    font-size: 12px;
    font-weight: 500;
    padding-top: 10px;
  }
  .form-fields{
    padding-bottom: 30px;
  }

  .submit-btn-wrapper button{
    width: 100%;
    padding: 15px;
    border: none;
    background-color: #1A0F31;
    color: #ffffff;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    border-radius: 50px;
  }

  .login{
    border: 1px solid #4A5568;
    padding: 0px 50px;
    height: 58px;
    display: flex;
    align-items: center;
    border-radius: 39px;
    cursor: pointer;
  } 
  .login:hover{
    border: 1px solid #D8AC48;
  }

  .login:hover a{
    color:#D8AC48;
  }

  .login a{
    font-size: 16px;
    font-weight: 700;
    color:#4A5568;
  }

  .user-login button{
    display: flex;
    background-color: transparent;
    border: none;
    color: #000000;
    align-items: center;
    text-align: left;
  }
  .user-login button:hover{
    background-color: transparent !important;
  }
  .user-login button:focus{
    background-color: transparent;
  }
  .user-login button:active{
    background-color: transparent;
  }
  .show>.btn-primary.dropdown-toggle{
    background-color: transparent;
  }
  .user-login button img{
    width: 36px;
    margin-right: 10px;
  }
  .user-login button .welcome-text{
    color: #4A5568;
    font-size: 12px;
    display: block;
  }
  .user-login button .username{
    color:#1A0F31;
    font-size: 15px ;
  }
  .singup-wrapper .input-group-text{
    padding: 14px 10px;
    background-color: #f3f4f6;
    border: none;
    border-radius: 0;
  }
  .singup-wrapper .login-btn button{
    font-weight: 600;
    width: 100%;
    background-color: #1A0F31;
    color: #ffffff;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 50px;
    margin-top: 15px;
  }
  .signup-modal .modal-header{
    background-color: #F3F3F3;
  }
  .signup-modal .modal-header .modal-title{
    color: #1A0F31;
    font-size: 40px;
    font-weight: 700;
  }
  .signup-footer{
    padding-top: 20px;
    text-align: center;
  }
  .signup-footer .f-pass{
    color: #1A0F31;
    font-size: 14px;
    border-bottom: 1px solid #1A0F31;
    font-family:  'Inter', sans-serif;
    cursor: pointer;
  }
  .signup-footer .cre-acc{
    color: #D8AC48;
    font-size: 14px;
    border-bottom: 1px solid #D8AC48;
    font-family: 'Inter', sans-serif;
    cursor: pointer;
  }
  .signup-footer p{
    padding-top: 12px;
    font-size: 14px;
    margin-bottom: 12px;
  }
  .signup-modal .modal-body{
    padding: 0;
  }
  .singup-wrapper{
    padding: 50px 20px;
  }

  .image-holder-wrapper{
    height: 100%;
    background-color: #f3f3f3;
  }

  .payment-form{
    box-shadow: 0px 4px 60px 3px #00000012;
    padding-bottom: 30px;
    margin-bottom: 60px;
  }

  .form-fields span{
    font-size: 12px;
    display: inline-block;
  }

  .custom-quantity-container{
    width: 93%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0.5px solid lightgray;
    margin: 1rem 1rem 0.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
  .custom-quantity-btn{
    /* width: 100%; */
    background-color: #D8AC48;
    border: 1px solid #D8AC48;
    color: #fff;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
  .custom-quantity-btn:hover{
    color: #D8AC48;
    background-color: transparent;
    border: 1px solid #D8AC48;
  }