@media only screen and (min-width: 1400px) and (max-width: 2400px) {
  .hero-img img{
      top: -40px; 
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .mailerbox-home-wrapper .product-slider-img::before{
      width: 699px;
  }
  .box-style-wrapper .style-slider .style-item p{
      font-size: 14px;
  }
  .box-style-wrapper .style-content-wrap i{
      height: 35px;
      width: 35px;
      line-height: 35px;
  }
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link{
      font-size:12px;
  }
  .hero-banner-wrapper .hero-content ul li{
      font-size:14px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1400px) {
  .mailerbox-home-wrapper .product-slider-img img{
      max-height: 400px;
      margin-bottom: 0px;
  }
  .product-sticker {
      position: absolute;
      height: 100px;
      width: 100px !important;
      right: 40px !important;
      top: 10px;
      background-color: #f0e9f0;
      border-radius: 50%;
  }
  .price-panel .subtotal p{
    font-size: 16px !important;
  }
  .price-panel .subtotal span{
    font-size: 16px !important;
  }
  .shopping {
    font-size: 8px;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1779px) {}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item {padding: 0 15px;}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* homepage 1 */
  .header .header-top {display: none;}
  .navbar-toggler {width: 42px;height: 42px;padding: 0;color: #d8ac48;font-size: 20px;float: right;margin: 10px 0;outline: none;}
  .navbar-toggler:focus {outline: none;}
  .header .header-bottom {border-radius: 0;box-shadow: none;padding-left: 0;padding-right: 0;}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item {padding: 0;}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link {background: transparent;padding: 14px 15px;margin-bottom: 1px;color: rgba(26, 15, 49, 0.60);}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item:last-child .nav-link {margin-bottom: 15px;}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link:before {display: none;}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item .dropdown-menu {display: none;}
  .header .header-bottom .main-menu .navbar .navbar-nav .dropdown:hover .dropdown-menu {display: block;}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link.buy-now {background: #061143;padding: 14px 15px;border-radius: 0;}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  
  /* homepage 1 */
  .header .header-top {display: none;}
  .navbar-toggler {width: 42px;height: 42px;padding: 0;color: #d8ac48;font-size: 20px;float: right;margin: 10px 0;outline: none;}
  .navbar-toggler:focus {outline: none;}
  .header .header-bottom {border-radius: 0;box-shadow: none;padding-left: 0;padding-right: 0;}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item {padding: 0;}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link {background: #ffffff;padding: 14px 15px;margin-bottom: 1px;color: rgba(26, 15, 49, 0.60);}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item:last-child .nav-link {margin-bottom: 15px;}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link:before {display: none;}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item .dropdown-menu {display: none;}
  .header .header-bottom .main-menu .navbar .navbar-nav .dropdown:hover .dropdown-menu {display: block;}
  /* faq */
  .faq {padding: 60px 0;}
  .faq .faq-filtering-button .nav-tabs .nav-item .nav-link {padding: 0 60px;font-size: 14px;height: 45px;line-height: 45px;}
  .faq .faq-filtering-button .nav-tabs {display: flex;justify-content: space-around;}
  .faq .faq-content .accordion .card .card-header h2 button {font-size: 15px;white-space: normal;}
  .faq .faq-content .accordion .card .card-header button {font-size: 15px;white-space: normal;}
}
@media only screen and (min-width: 480px) and (max-width: 575px) {
  .header .header-bottom {border-radius: 0;box-shadow: none;padding-left: 0;padding-right: 0;}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item {padding: 0;}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link {background: #ffffff;padding: 14px 15px;margin-bottom: 1px;color: rgba(26, 15, 49, 0.60);}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item:last-child .nav-link {margin-bottom: 15px;}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link:before {display: none;}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item .dropdown-menu {display: none;}
  .header .header-bottom .main-menu .navbar .navbar-nav .dropdown:hover .dropdown-menu {display: block;}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link.buy-now {background: #ffffff;padding: 14px 15px;border-radius: 0;border-bottom: 1px solid;}
  /* faq */
  .faq {padding: 60px 0;}
  .faq .faq-filtering-button .nav-tabs .nav-item .nav-link {padding: 0 18px;font-size: 14px;height: 45px;line-height: 45px;}
  .faq .faq-filtering-button .nav-tabs {display: flex;justify-content: space-around;}
  .faq .faq-content .accordion .card .card-header h2 button {font-size: 15px;white-space: normal;}
  .faq .faq-content .accordion .card .card-header button {font-size: 15px;white-space: normal;}
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  /* homepage 1 */
  .navbar-toggler {width: 42px;height: 42px;padding: 0;color: #D8AC48;border: 1px solid #D8AC48;font-size: 20px;float: right;margin: 10px 0;border-radius: 50%;outline: none; }
  .navbar-toggler:focus {outline: none;}
  .header .header-bottom {border-radius: 0;box-shadow: none;padding-left: 0;padding-right: 0;}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item {padding: 0;}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link {background: #ffffff;padding: 14px 15px;margin-bottom: 1px;color: rgba(26, 15, 49, 0.60);}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item:last-child .nav-link {margin-bottom: 15px;}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link:before {display: none;}
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item .dropdown-menu {display: none;}
  .header .header-bottom .main-menu .navbar .navbar-nav .dropdown:hover .dropdown-menu {display: block;}
  .banner .banner-content {padding: 100px 0 !important;margin-top: 63px !important;} 
  .banner .banner-content .banner-buttons a.banner-button {padding: 14px 60px;}
  
 
 
  /* faq */
  .faq {padding: 30px 0;}
  .faq .faq-filtering-button .nav-tabs .nav-item .nav-link {padding: 0 18px;font-size: 14px;height: 45px;line-height: 45px;}
  .faq .faq-filtering-button .nav-tabs {display: flex;justify-content: space-around;}
  .faq .faq-content .accordion .card .card-header h2 button {font-size: 10px;white-space: normal;}
  .faq .faq-content .accordion .card .card-header button {font-size: 10px;white-space: normal;}
 
 
}
@media only screen and (min-width: 320px) and (max-width: 575px) {
  
}
@media only screen and (min-width: 320px) and (max-width: 991px) {
 
 
}
@media only screen and (min-width: 992px) and (max-width: 1821px) {
  
  
}
@media only screen and (min-width: 1400px) and (max-width: 2400px){
 
}
@media (max-width:1400px){
 
}
@media (max-width:1200px){

}
@media (max-width:992px){    
  .faq .faq-content .accordion .card .card-body{padding: 15px 30px 10px;font-size: 12px;
      line-height: 24px;}
  .main-menu{
      width:100%;
      background-color: #ffffff;
  }
  .header .header-contact-info .call-header span{
      color:rgba(26, 15, 49, 0.60);
  }
  .header .header-contact-info .email-header span{
      font-size: 11px;
      color:rgba(26, 15, 49, 0.60);
      font-weight: 500;
  }
  .header .header-contact-info .email-header{
      border:none;
      border-radius: 0;
      padding: 0;
  }
  .header .header-contact-info .email-header a{
      color: #D8AC48;
      font-weight: 500;
      line-height: 0;
  }
  .header-social ul{
      display:inline-flex;
      margin-top: -18px;
  }
  .header-social ul li{
      padding:0px 12px;
  }
  .header-social ul li a i{
      color:#766f83;
  }
  .header-social ul li:first-child{
      padding-left: 0px;
  }
  .header-contact-info{
      margin: 0px 10px;
      border-bottom: none;
      padding-bottom: 15px;
      position: absolute;
    top: 22px;
    right: 20%;
  }
  .header-social{
      flex-direction: column;
      padding: 20px 15px 20px;
  }
  .header-social span{
      color: var(--Sub-Text, rgba(26, 15, 49, 0.60));
      font-size: 15px;
  }
  .header .header-bottom .main-menu .navbar .navbar-nav .nav-item .nav-link:hover{
      background-color: transparent;
  }r
  .review-wrapper{
      display: none;
  }
  .heading-section p{
      Width:100%;
      padding: 0px 15px;
      font-size: 14px;
      line-height: 24px;
  }
  .box-style-wrapper .style-slider .owl-dots{
      left: 35%;
  }
  .copyright-footer .footer-social{
      display: none;
  }
  .cta-wrapper-footer{
      padding: 30px 0px;
  }
  .cta-wrapper-footer h3{
      font-size: 24px;
      line-height: 28px;
      margin-bottom:10px;
  }
  .cta-wrapper-footer .cta-banner-content .sub-heading{
      font-size: 24px;
      line-height: 28px;
  }
  .cta-wrapper-footer .cta-banner-content p{
      font-size: 14px;
      margin-bottom:20px;
  }
  .find-packaging-solution img{
      height: initial !important;
      padding: 0px;
  }
  .find-packaging-solution{
      padding: 8px 8px 0px 8px;
  }
  .find-packaging-solution .pa-main {
      padding: 10px 0px;
  }
  .find-packaging-solution .pa-1{
      font-size: 12px;
  }
  .page-banner .page-banner-content h1{
      font-size: 28px;
      line-height: 30px;
  }
  .page-banner .page-banner-content p{
      margin-bottom: 10px;
      font-size: 14px;
  }
  .find-packaging-solution{
      border-radius: 5px;
  }
  .style-related-categories .related-item-logo{
      height: initial;
      padding: 10px 8px 0px 8px;
  }
  .style-related-categories .related-item-content{
      padding: 5px 10px;
      width: 80% !important;
  }
  .style-related-categories .related-item-content p{
      font-size: 16px;
  }
  .box-style-wrapper .style-slider .style-item p{
      font-size: 14px;
      line-height: 16px;
  }
  .heading-section{
      padding: 15px 15px;
  }
  .heading-section h2{
      font-size: 24px;
      margin-bottom: 0px;
      line-height: 26px;
  }
  .gallery-wrapper{
      display: none;
  }
  .box-style-wrapper{
      padding: 40px 0px 50px;
  }
  .client-item-par .pa-2{
      font-size: 16px;
      line-height: 25px;
  }
  .mailerbox-home-wrapper{
      background-color: #f9e8e2;
      background-image: none;
  }
  .mailerbox-home-wrapper .row{
      flex-direction: column-reverse;
  }
  .mailerbox-home-content{
      padding: 60px 0px;
  }
  .mailerbox-home-wrapper .product-slider-img::before{
      width: 680px;
  }
  .service-tab-wrapper .service-content-area ul{
      column-count: 1;    
  }
  .service-tab-wrapper .nav{
      column-count: 2;
  }
  .service-tab-wrapper .nav::before{
      background-color: transparent;
  }
  .faq .justify-content-end {
      justify-content: center!important;
  }
  .faq-w-form .faq-content{
      margin-right: 0px;
  }
  .canve-div-one ul li{
      border-right:none !important;
      font-size: 12px;
  }
  .canve-div-one ul li img{
      height:20px !important;
  }
  .canve-div-one ul li svg{
      height: 20px;
      width: 45px;
  }
  .canve-div-one ul{
      justify-content: space-around;
      margin-top: -35px;
  }
  .brand-logos-wrapper{
      border-radius: 0;
  }
  .hero-banner-wrapper .hero-content ul{
      display: none;
  }
  .hero-banner-wrapper .hero-content p{
      margin-bottom:30px;
  }
  .canve-div-one{
      margin-top: -80px;
  }
  .mailerbox-home-wrapper .mailerbox-home-content p{
      margin-right: 0;
      font-size: 14px;
      line-height: 24px;
      padding-bottom:0px;
  }
  .quote-form-hero{
      margin: 50px 0px 0px !important;
  }
  .footer-col-2, .footer-col-3, .footer-col-4{
      margin:20px 0px;
      padding-left: 0px;
  }
  .footer-col-1{
      margin-bottom: 20px;
  }
  .product-gallery .justify-content-end{
      justify-content: center !important;
  }
  .reviews-brand-wrapper{
      width: 100%;
      border-top: 1px solid #E0E0E0;
  }
  .reviews-brand-wrapper::before{
      background:transparent;
  }
  .reviews-brand-wrapper ul{
      padding-top: 20px;
  }
  .process-wrapper .heading-section h2{
      text-align: left;
  }
  .about-page .product-content-wrapper .product-content-wrap{
      padding-right: 0px;
      padding-bottom: 40px;
  }
  .why-choose-about-img{
      padding-bottom: 30px;
  }
  .fsc-certified-wrapper{
      background: rgb(245,236,216);
      /* background: linear-gradient(90deg, rgba(245,236,216,1) 50%, rgba(245,236,216,1) 50%); */
  }
  .sidebar-wrapper{
      display: none;
  }
  .box-style-wrapper .explore-more-style{
      margin-bottom: 25px;
  }
  .blog-search-wrap{
      padding: 0 !important;
  }
  #myBtnContainer{
      display: inline-block !important;
      border: none;
      margin-bottom: 20px;
      text-align: center;
  }
  .featured-blog{
      display: none !important;
  }
  .single-blog-banner .blog-banner-img{
      margin-bottom: 0 !important;
      padding: 20px 0px 40px;
  }
  .blog-content-wrapper {
      padding: 60px 0px 80px !important;
  }
  .terms-condition-page .faq{
      display: none;
  }
  .privacy-page .faq{
      display: none;
  }
  .terms-condition-page .find-our-work{
      display: none;
  }
  .contact-page-info-content{
      padding: 50px 15px;
      background-color: rgba(255,255,255, 0.8);
      border-radius: 10px;
  }
  .contact-page-info-wrapper::before{
      background-color: transparent;
  }
  .contact-banner-img{
      margin-bottom: -40px;
      display: flex;
      justify-content: end;
      width: 100%;
  }
  .contact-banner-img img{
      width: 300px;
  }
  .quote-page-content-img{
      display: none;
  }
  .hero-img img{
      position: relative;
  }
  .hero-banner-wrapper{
      padding: 40px 0px 0px;
  }
  .breadcrump-menu{
      display: none;
  }
  .mailerbox-home-wrapper .mailerbox-home-content h2{
      font-size: 24px;
      line-height: 26px;
      padding-bottom:10px;
  }
  .find-packaging-solution-wrapper {
      padding: 30px 0px 25px;
  }
  .view-all-btn {
      padding: 20px 0px 20px;
  }
  .sign-line::before{
      top: 23px !important;
  }
  .unique-sp-wrapper .unique-sp-item p{
      font-size: 12px;
      padding-top: 7px;
  }
  .material-wrapper .home-material-card h4{
      font-size: 14px;
  }
  .material-wrapper .home-material-card p{
      font-size: 10px;
      line-height: 18px;
  }
  .material-wrapper .home-material-content{
      min-height:160px;
  }
  .client-wrapper .container{
      padding-top: 10px !important;
  }
  .faq-w-form .heading-section {
      padding: 20px 0px 30px;
  }
  .faq-content{
      padding: 5px 15px 30px 15px;
  }
  .faq .faq-content .accordion .card .card-body{
      padding: 0px 0px 10px;    
  }
  .faq .faq-content .accordion .card .card-header h2 button{
      padding: 25px 0px 15px;
  }
  .faq .faq-content .accordion .card .card-header button{
      padding: 25px 0px 15px;
  }
  .quote-form-hero .form-top-element{
      display: none;
  }
  .quote-form-hero .form-bottom-element{
      display: none;
  }
  .blog-wrapper .sign-line::before {
      top: 24px !important;
  }
  .find-packaging-solution{
      box-shadow: -3px 20px 50px 0px #DDD;
  }
  .box-style-wrapper .style-content-wrap i{
      height: 30px;
      width: 30px;
      line-height: 30px;
      font-size: 12px;
  }
  .hero-banner-wrapper .hero-content span{
      font-size: 14px;
  }
  .canve-div-one svg{
      width: 992px;
      height: 95px;
  }
  .process-wrapper .heading-section h2{
      padding-left:15px;
  }
  .desktop-show{
      display:none;
  }
  .mobile-show{
      display:block;
  }
  .quote-form-wrapper .quote-form-content h1{
      font-size:30px;
      margin-top:20px;
  }
  .quote-form-wrapper .quote-form-content span{
      font-size:18px;
  }
  .quote-form-wrapper .quote-form-content .big-text{
      font-size:16px;
      line-height:24px;
  }
  .quote-page .product-usps-list{
      display:none;
  }
  .quote-page .explore-more-style{
      display:none;
  }
  .box-style-wrapper{
      margin: 20px 0px;
  }
  .brand-logos-wrapper{
      padding: 20px 0px;
  }
  .page-banner{
      padding: 30px 0px 0px;
  }
  .faqs-page #myBtnContainer{
      border:none;
  }
  .faqs-page #myBtnContainer .btn{
      border: 1px solid #CBD5E1;
      margin-bottom:10px;
  }
  .faqs-page .faq{
      padding: 20px 0 20px;
  }
  .content-table{
      display:none;
  }
  .supporting-page-content .content-wrapper{
      padding-right: 0px;
  }
  .supporting-page-content .content-wrapper p{
      text-align:justify;
  }
  .privacy-page .page-banner-content{
      padding-bottom: 0;
  }
  .terms-condition-page .page-banner-content{
      padding-bottom: 0;
  }
  .about-page .product-content-wrapper .product-content-wrap h2{
      font-size:24px;
      line-height: 30px;
  }
  .about-page .product-content-wrapper {
      padding: 40px 0px;
  }
  .about-page .product-content-wrapper .product-content-wrap p{
      font-size: 14px;
      line-height: 22px;
  }
  .about-page .product-content-wrapper .product-content-wrap ul li{
      font-size: 12px;
  }
  .content-wrapper-two .content-wrap-content h2{
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 20px;
  }
  .content-wrapper-two .content-wrap-content p{
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 20px;
  }
  .why-choose-about-wrapper .why-choose-about-content h2{
      font-size: 30px;
      line-height: 30px;
  }
  .why-choose-about-wrapper .why-choose-about-content p{
      font-size: 14px;
      line-height: 24px;
  }
  .why-choose-about-wrapper .row{
      display: flex;
      flex-direction: column-reverse;
  }
  .why-choose-about-wrapper .why-choose-about-content{
      margin-bottom:20px;
  }
  .upper-header{
      display:none;
  }
  .product-content-banner-wrapper .product-content-banner-wrap p{
      font-size: 14px;
      line-height: 24px;
  }
  .product-content-banner-wrapper{
      margin: 40px 0px 40px;
  }
  .category-content-wrapper{
      padding:40px 0px;
  }
  .category-content-wrapper .category-content-left h2{
      font-size:30px;
      line-height:35px;
  }
  .category-content-wrapper .category-content-left p{
      font-size: 14px;
      line-height: 24px;
  }
  .category-content-wrapper .category-content-left ul li{
      font-size: 14px;
      line-height: 24px;
  }
  .category-content-wrapper .category-content-right h2{
      font-size:30px;
      line-height:35px;
  }
  .category-content-wrapper .category-content-right p{
      font-size: 14px;
      line-height: 24px;
  }
  .product-content-banner-wrapper .product-content-banner-wrap h2{
      line-height:35px;
  }
  .web-product-page .product-title{
      font-size:30px;
      line-height:35px;
  }
  .web-product-page .product-content p{
      font-size: 14px;
      line-height: 24px;
  }
  .fsc-certified-wrapper .fsc-certified-content p{
      font-size: 14px;
      line-height: 24px;
  }
  .contact-page .quote-form-wrapper .quote-form-content p{
      font-size: 14px;
      line-height: 24px;
  }
  .contact-page-info-content span{
       font-size: 14px;
      line-height: 24px;
  }
  .contact-page-info-content h2{
      font-size:30px;
      line-height:35px;
  }
  .contact-page-info-content p{
      font-size: 14px;
      line-height: 24px;
  }
  .contact-page-info-content ul li p{
      font-size: 14px;
      line-height: 14px;
  }
  .contact-banner-buttons ul{
      display:block !important;
  }
  .contact-banner-buttons ul li{
      margin-bottom:15px;
  }
  .category-content-right img{
      height: initial;
  }
  .faqs-banner .faqs-banner-content h1{
      font-size:30px;
      line-height:35px;
  }
  .faqs-banner .faqs-banner-content p{
      font-size: 14px;
      line-height: 14px;
  }
  .faqs-page #myBtnContainer .btn{
      height:30px;
      font-size:8px;
  }
  .form-wrapper{
    margin-top: 40px;
  }
}
@media (max-width:768px){
  .product-usps-wrapper .container{
      margin-top: 20px;
  }
  .product-usps-wrapper .product-usps-list ul li{
      font-size: 12px;
  }
  .product-usps-wrapper .product-usps-list ul{
      margin-bottom:20px;
  }
  .reviews-brand-wrapper ul li a{
      font-size: 12px;
  }
  .mobile-version-wrapper{
      display:block;
  }
  .desktop-version-wrapper{
      display:none;
  }
  .material-wrapper .nav-tabs .nav-link{
      font-size: 10px;
  }
  .service-tab-wrapper .service-content-area{
      padding: 10px 0px;
      margin: 0;
      border: 9px solid #eeeeee;
  }
  .service-tab-wrapper .nav-tabs .nav-link{
      font-size: 16px;
  }
  .service-tab-wrapper .nav-tabs .nav-item{
      padding-bottom: 20px;
  }
  .material-wrapper .home-material-card img{
      height: 300px !important;
  }
  .material-wrapper .owl-nav .owl-next{
      right: -10px;
  }
  .material-wrapper .owl-nav .owl-prev{
      left: -10px;
  }
  .fsc-certified-wrapper .fsc-certified-content{
      padding: 0;
  }
  .page-banner-content{
      padding-bottom: 40px;
  }
  .product-content-banner-wrapper .container{
      padding: 50px 0px;
  }
  .product-content-banner-wrapper .product-content-banner-img {
      text-align: center;
      margin-bottom: 20px;
      padding: 0px 15px;
  }
  .product-content-banner-wrap{
      padding: 0px 15px;
  }
  .product-content-banner-wrap{
      padding: 0px 15px 30px;
  }
  .category-content-wrapper .category-content-left{
      padding: 15px 15px 30px;
  }
  .box-style-wrapper .style-item img{
      height: initial;
      padding: 5px;
  }
  .blog-main-content .popular-product{
      padding: 35px 15px !important;
  }
  .box-style-wrapper .style-content-wrap{
      padding: 0px 10px;
  }
  .product-sticker{
      height: 80px;
      width: 80px !important;
      right: 24px !important;
      top: 4%;
  }
  .mailerbox-home-wrapper .product-slider-img img{
      max-height: 320px;
      margin-bottom: 0px;
  }
  .product-slider-content{
      bottom: 0%;
  }
  .mailerbox-home-wrapper .product-slider-img h5{
      font-size: 24px;
  }
  .mailerbox-home-wrapper .product-slider-img p{
      font-size: 12px;
  }
  .style-related-categories .related-item-content i{
      height: 30px;
      width: 30px;
      line-height: 30px;
      font-size: 12px;
  }
  .hero-img img{
      top: 10px;
  }
  .reviews-brand-wrapper{
      padding-left: 0px;
  }
  .service-tab-wrapper .service-content-area ul li{
      padding: 0px 10px 20px;
  }
  .faq .faq-content .more-faqs a{
      font-size:12px;
  }
  .footer-col-1 img{
      width: 200px;
  }
  .contact-banner-buttons{
      position: relative;
      z-index: 3;
  }
}
@media (max-width:567px){
  .hero-banner-wrapper .hero-content h1{
      font-size: 30px;
      line-height: 35px;
  }
  .hero-banner-wrapper .hero-content p{
      line-height: 24px;
      margin: 10px 0px;
      font-size: 14px;
  }
  .hero-banner-wrapper .hero-content h2{
      font-size: 22px;
      line-height: 22px;
  }
  .box-reviews-wrapper .owl-nav{
      display: none;
  }
  .owl-nav div{
      display: none;
  }
  .mailerbox-home-wrapper .product-ecommec{
      height: 70vh;
  }
  .mailerbox-home-wrapper .product-slider-img{
      height: 70vh;
  }
  .mailerbox-home-wrapper .product-slider-img::before{
      height: 70vh;
  }
  .service-tab-wrapper .nav-tabs .nav-link span{
      margin-right: 10px;
      width: 30px;
      height: 30px;
      line-height: 30px;
  }
  .service-tab-wrapper .nav-tabs .nav-link{
      font-size: 12px;
      padding:0;
  }
  .mailerbox-home-wrapper .product-slider-img::before{
      width:100%;
  }
  .hero-img img{
      top: 10px;
  }
  .product-usps-wrapper .product-usps-list ul li{
      font-size: 10px;
  }
  .product-usps-wrapper .container{
      padding: 30px 15px;
  }
  .reviews-brand-wrapper ul{
      gap: 10px;
  }
  .reviews-brand-wrapper ul li img{
      object-fit: contain;
  }
  .box-style-wrapper .owl-nav{
      display:none;
  }
  .quote-form-hero{
      width:100%;
  }
  .review-wrapper .review-brand img{
      height: 40px;
      width: 50px;
      object-fit: contain;
  }
  .banner-input .input-group-append{
      padding: 0px 10px;
      top: 15px;
  }
  .banner-input .input-group-append .btn{
      font-size: 12px;
  }
  .find-packaging-solution .pa-main{
      min-height: 50px;
      display: flex;
      align-items: center;
  }
}
@media (max-width:567px){
  .faq .faq-content .accordion .card .card-header h2 button:after{
      right: 10px;
      height: 15px;
      width: 15px;
      line-height: 13px;
      font-size: 13px;
  }
  .faq .faq-content .accordion .card .card-header button:after{
      right: 10px;
      height: 15px;
      width: 15px;
      line-height: 13px;
      font-size: 13px;
  }
  .find-packaging-solution .pa-1{
      font-size:10px;
      line-height:14px;
  }
  .heading-section {
      padding: 15px 10px;
  }
  .unique-sp-wrapper .heading-section p{
      width:100%;
  }
  .unique-sp-wrapper .unique-sp-item svg{
      height: 70px;
      width: 70px;
  }
  .unique-sp-wrapper .unique-sp-item p{
      font-size: 10px;
      line-height: 14px;
  }
  .quote-form-hero{
      padding-left: 15px;
      padding-right: 15px;
  }
}
@media (max-width:480px){
  .style-related-categories .related-item-content p::before{top: 14px;}
  .style-related-categories .related-item-content{height:65px;}
  .style-related-categories .related-item-content p{
      font-size: 12px;
  }
  .product-content-wrapper .tab-navigation .nav-tabs .nav-item .nav-link{
      font-size:9px;
  }
  .faq .faq-content .accordion .card .card-header h2 button {font-size: 12px;}
  .faq .faq-content .accordion .card .card-header button {font-size: 12px;}
  .hero-img img{
      top: 5px;
  }
  .material-wrapper .nav-tabs .nav-link{
      font-size: 8px;
      padding: 0.5rem 0.5rem;
  }
  .homepage .brand-logos-wrapper{
      margin-top: -5px;
  }
  .contact-banner-img{
      margin-top: -155px;
      z-index: 1;
      margin-right: -70px;
      display:none;
  }
  .product-usps-wrapper .product-usps-list ul li {
      font-size: 8px;
  }
}
@media (max-width:380px){
  .product-content-wrapper .tab-navigation .nav-tabs .nav-item .nav-link{
      font-size:7px;
  }
}