h3{
    color: #1A0F31;
}
.order-tabs .nav-tabs{
    overflow: hidden;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    border: none;
}
.order-tabs .nav-link{
    border: none !important;
    border-radius: 0px !important;
    font-size: 18px;
    color: #4A5568 !important;
    font-weight: 500;
    height: 100%;
    line-height: 30px;
}
.order-tabs .nav-link.active{
    color: white !important;
    background-color: #D8AC48 !important;
}
.order-tabs .nav-item{
    border: 1px solid #CBD5E1;
    padding: 0;
    overflow: hidden;
    margin-bottom: 3px;
    width:250px;
    flex: 1;
    text-align: center;
    height: 45px;
}
.order-tabs .item1{
    border-radius: 5px 0px 0px 5px;
}
.order-tabs .item2{
    border-radius: 0px 0px 0px 0px;
}
.order-tabs .item3{
    border-radius: 0px 0px 0px 0px;
}
.order-tabs .item4{
    border-radius: 0px 5px 5px 0px;
}
.order-tabs #design .item-box{
    background-color: #FDFAF7;
    border: 1px solid #CBD5E1;
    border-radius: 5px;
}
.order-tabs #design .item-img{
    min-height: 220px;
}
.order-tabs #design .desc-time{
    font-size: 12px;
    font-weight: 500;
    color: #4A5568;
}
.order-tabs #design .desc-item{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 1px;
    line-height: 20px;
    color: #1A0F31;
}
.order-tabs #order , .order-tabs #details{
    font-size: 15px;
}
.order-tabs #order .shopnow a{
    font-size: 15px;
    font-weight: 500;
    color: #1A0F31;
}
.useer-table{
    padding: 40px 50px;
    margin: 15px 0px;
    border: 1px solid #CBD5E1;
    border-radius: 10px;
}
.order-item img{
    height: 55px;
    object-fit: contain;
}
.order-item{
    text-align: center;
}
.order-tabs .tab-content table tbody{
    vertical-align: middle;
}

.btn-yellow-table{
    background-color: #D8AC48;
    color: #ffffff;
    border: 1px solid #D8AC48;
    box-shadow: 4px 5px 15px rgba(216, 172, 72, 0.40);
}
.modal-body .card{
    padding: 10px 16px;
}
.modal-body .card-header h3{
    margin-bottom: 0;
}


@media (max-width:992px){
    .order-tabs .nav-tabs{
    grid-template-columns: 50% 50%;
}
.order-tabs #order , .order-tabs #details{
    font-size: 14px;
}
.order-tabs .item2{
    border-radius: 0px 5px 5px 0px;
}
.order-tabs .item3{
    border-radius: 5px 0px 0px 5px;
}
#design .table button{
    margin-bottom:10px;
}
}
@media (max-width:576px){
    .order-tabs .nav-tabs{
    grid-template-columns: 100%;
}
.order-tabs #order , .order-tabs #details{
    font-size: 12px;
}
.order-tabs #design .desc-time{
    font-size: 10px;
}
.order-tabs #design .desc-item{
    font-size: 13px;
}
.order-tabs .item1{
    border-radius: 5px 5px 5px 5px;
}
.order-tabs .item2{
    border-radius: 5px 5px 5px 5px;
}
.order-tabs .item3{
    border-radius: 5px 5px 5px 5px;
}
.order-tabs .item4{
    border-radius: 5px 5px 5px 5px;
}
}