.account-setting-wrapper{
    padding: 40px 50px;
    margin: 50px 0px;
    border: 1px solid #CBD5E1;
    border-radius: 10px;
}
.password-input-container{
    display: flex;
    align-items: center;
    background-color: #F3F4F6;
    padding: 6px;
    border-radius: 12px;
}