/* * navbar start */ 

/* navbar start */
.menu-increase .drop-toggle::after {
    content: "";
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    color: #d8ac48 !important;
  }
.menu-increase .nav-link:hover .drop-toggle::after {
    color: white !important;
}
  .menu-increase .fa-bars{
    color: #d8ac48 !important;
    border: 2px solid #d8ac48;
    border-radius: 30px;
    padding: 7px  9px;
}
.menu-increase .mega-menu a{
    background-color: white !important;
    color: #4A5568 !important;
font-size: 12px !important;
font-weight: 500;
line-height: 18px;
letter-spacing: 0em;
text-align: left !important;
}
.menu-increase .mega-menu li{
    display: flex;
    background-color: white !important;
}
.menu-increase .mega-menu .img-fluid{
    width: 33px;
    height: 33px;
    background-color: #F5ECD8 !important;
    border-radius: 5px;
    padding: 5px;
    margin-top: 5px;
    margin-right: 10px;
    object-fit: cover;
}
.menu-increase .mega-menu .btn-yellow{
width: 25% !important;
height: 38px;
box-shadow: none;
}
.menu-increase .mega-menu .img-tools{
    padding-top: 80px;
}
.menu-increase .mega-menu h4{
font-size: 18px;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;
}
.menu-increase .mega-menu p{
font-size: 18px;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;
}
.menu-increase .menu-position{
    /* position: absolute !important; */
    width:950%;
    left: -380px !important;
    border-radius: 15px !important;
box-shadow: 0px 5px 23px 12px #0000000A !important;
}
.menu-increase .menu-position a{
    background-color: white !important;
    color: #4A5568 !important;
font-size: 10px !important;
font-weight: 500;
line-height: 18px;
letter-spacing: 0em;
text-align: left !important;
}
.menu-increase .menu-position-style{
    /* position: absolute !important; */
    width:970%;
    left: -180px !important;
    top: 50px;
    border-radius: 15px !important;
box-shadow: 0px 5px 23px 12px #0000000A !important;
}
.menu-increase .menu-position-style-1{
    /* position: absolute !important; */
    width:970%;
    left: -306px !important;
    top: 50px;
    border-radius: 15px !important;
box-shadow: 0px 5px 23px 12px #0000000A !important;
}
.menu-increase .menu-position-style-3{
    /* position: absolute !important; */
    width:970%;
    left: -455px !important;
    top: 50px;
    border-radius: 15px !important;
box-shadow: 0px 5px 23px 12px #0000000A !important;
}
.menu-increase .li-btn button{
    font-size: 12px !important;
    width: 25% !important;
}
@media (max-width:1200px){
    .menu-increase .menu-position-style-3{
left: -560px !important;
}
.menu-increase .menu-position-style-1{
    left: -352px !important;
    }
    .drop-up-1 .dropdown-menu::before{
        left: 25% !important;
    }
    .drop-up-2 .dropdown-menu::before{
        left: 42% !important;
    }
    .drop-up-3 .dropdown-menu::before{
        left: 64% !important;
    }
}

    @media (max-width:1100px){
        .menu-increase .menu-position-style-3{
    left: -560px !important;
        }
        .menu-increase .menu-position-style-1{
            left: -350px !important;
                }
            }

    .drop-up-1 .dropdown-menu::before {
      content: "";
      position: absolute;
      top: -15px;
      left: 23%;
      margin-left: -10px; /* Adjust as needed */
      border-width: 0 10px 20px 10px;
      border-style: solid;
      border-color: transparent transparent white; /* Adjust background color as needed */
      z-index: 1000; /* Make sure it's above other elements */
    }
    
    .drop-up-2 .dropdown-menu::before {
        content: "";
        position: absolute;
        top: -15px;
        left: 35%;
        margin-left: -10px; /* Adjust as needed */
        border-width: 0 10px 20px 10px;
        border-style: solid;
        border-color: transparent transparent white; /* Adjust background color as needed */
        z-index: 1000; /* Make sure it's above other elements */
      }
      
    .drop-up-3 .dropdown-menu::before {
        content: "";
        position: absolute;
        top: -15px;
        left: 53%;
        margin-left: -10px; /* Adjust as needed */
        border-width: 0 10px 20px 10px;
        border-style: solid;
        border-color: transparent transparent white; /* Adjust background color as needed */
        z-index: 1000; /* Make sure it's above other elements */
      }
.menu-increase .mini-menu a{
    background-color: white !important;
    color: #4A5568 !important;
font-size: 12px !important;
font-weight: 500;
line-height: 25px;
letter-spacing: 0em;
text-align: center !important;
}
.menu-increase .mini-menu li{
    display: flex;
    background-color: white !important;
}

/* .mini-menu .btn-yellow{
width: 25% !important;
height: 38px;
} */
.menu-increase .mini-menu h4{
font-size: 18px;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;
}
.menu-increase .mini-menu p{
font-size: 18px;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;
}
.menu-increase .mini-menu .fa-arrow-right{
    width: 12px;
    font-size: 10px !important;
    padding-top: 5px;
    color: #d8ac48;
}
.menu-increase .div-btn button{
    height: 38px;
    font-size: 12px !important;
    text-align: center !important;
}

/* navbar end *
/* Responsive Menu begin */
@media (max-width:767px){
    .menu-increase .mini-menu a{
        text-align: left !important;
    }
    .menu-increase .mini-menu .fa-arrow-right{
        padding-top: 7px;
    }
}
@media (max-width:567px){
    .mini-menu .container{
        padding:0;
    }
    .menu-increase .mini-menu a{
        font-size: 10px !important;
    }
}




/* Responsive Menu end */