.inputwidth{
  width: 157px;
  padding: 5px;
  background-color: white;
  border: 1px solid black
}
.font{
  font-family: Arial, Helvetica, sans-serif;
}
.notshow{

  display: flex;

  justify-content: center;

  align-items: center;

  background-color: lightgrey;

  border-radius: 2rem;

  color: gray;

  padding: 0.5rem;

  border: none;

  outline: none;

  cursor: no-drop;

}
.accordion-button:not(.collapsed) {
  background-color: #4c2454 !important;
  color: #ffffff !important;
  font-weight: 700  !important;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.125) !important;
}

.product-spec-wrap .accordion-button{
  background-color: #ffffff !important;
  color: #212529 !important;
  font-weight: 700;
}
.product-spec-wrap .accordion-button.collapsed::after {
  color: #ffffff;
}