.templates-loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    /* transform: translate(-50%,-50%); */
    /* width: 100vw;
    height: 100vh; */
    /* width: 100vh;
    height: 100vh; */
    /* position: relative; */
    width: 100%;
    height: 100%;
    z-index: 11111111111;
    background-color: rgba(255, 255, 255, 0.7);
    /* You can adjust the background color and opacity */
  }

  .templates-loader {
    position: absolute;
    bottom: 30%;
    left: 50%;
    transform: translate(-30%, -50%);
    /* display: flex;
    justify-content: center;
    align-items: flex-start; */
  }
  